import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./footer.scss";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Environment from "../../utils/environment";
const Footer = () => {
  const api_url = Environment.base_url;

  const [email, setEmail] = useState("");

  const newsLetter = async () => {
    const config = {
      method: "post",
      url: api_url + "/newsletter",
      data: {
        email: email,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
      });
  };

  return (
    <>
      <section className="main-footer ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-11 col-12 p-0 m-auto">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                      <div className="card">
                        <img
                          className="handbox"
                          src="\footerassets\handbox.svg"
                        />
                        <div className="text">
                          <h6>Free Delivery</h6>
                          <p>For certain order limits.</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                      <div className="card">
                        <img
                          className="handbox"
                          src="\footerassets\round.svg"
                        ></img>
                        <div className="text">
                          <h6>Affordable picks</h6>
                          <p>Relatively cheaper. </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                      <div className="card">
                        <img
                          className="handbox"
                          src="\footerassets\lock.svg"
                        ></img>
                        <div className="text">
                          <h6>Wide Assortment</h6>
                          <p>
                            6k+ products
                             {/* across different categories. */}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                      <div className="card">
                        <img
                          className="handbox"
                          src="\footerassets\call.svg"
                        ></img>
                        <div className="text">
                          <h6>Quick Service </h6>
                          <p>Swift services everytime! </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="line-handle"></hr>

              <div className="row">
                <div className="col-xl-11 col-12 p-0 m-auto">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3  col-sm-12 padd-sm p-0">
                      <div className="info">
                        <Link to="/">
                          <img
                            className="logo"
                            src="\footerassets\Logo.svg"
                          ></img>
                        </Link>
                        {/* <p>
                          Fresh and convenient: Come for Groceries,
                          <br /> Stay for Experience!
                        </p> */}
                      </div>
                      <div className="num">
                        <img
                          className="phone"
                          src="\footerassets\phone.svg"
                        ></img>
                        <a href="tel:03005668000" target="_blank">
                        <p>+92 300 566 8000</p></a>
                      </div>

                      <div className="num">
                        <img
                          className="phone"
                          src="\footerassets\home.svg"
                        ></img>
                        <p>Faqir Aipee Road, I-11/3, Islamabad</p>
                      </div>

                      <div className="num">
                        <img
                          className="phone"
                          src="\footerassets\sms.svg"
                        ></img>
                        <a href='mailto:Info@quick.shop'>
                        <p>Info@quick.shop</p></a>
                      </div>
                      <div className="link">
                            <p>Stay Connected:</p>
                            <div className="social-icons-footer">
                            <a href="https://www.facebook.com/quickdotshop" target="_blank">
                              <img
                                className=" ms-2"
                                src="\footerassets\facebook.svg"
                              ></img></a>
                                <a href="https://twitter.com/quickdotshop" target="_blank"> 
                               <img
                                className=" ms-2"
                                src="\footerassets\twitter.svg"
                              ></img></a>
                              <a href="https://www.instagram.com/quickdotshop" target="_blank">
                              <img
                                className=" ms-2"
                                src="\footerassets\instagram.svg"
                              ></img></a>
                              <a href="https://www.linkedin.com/company/quickdotshop" target="_blank">
                              <img
                                className=" ms-2 pb-1"
                                src="\footerassets\linkedin.svg"
                              ></img></a>
                            </div>
                          </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 p-0 ">
                      <div className="line ">
                        <h4>Useful Links</h4>
                        <div className="list">
                          <Link to="/about-us">
                            <p>About Us</p>
                          </Link>
                          <Link to="/contact-us">
                            <p>Contact</p>
                          </Link>
                          {/* <p>Help Center</p> */}
                          <Link to="/careers">
                            <p>Career</p>
                          </Link>
                          <Link to="/privacy">
                          <p>Policy</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12 p-0">
                      <div className="line">
                        <h4>Help Center</h4>
                        <div className="list">
                          <Link to = "/payment">
                          <p>Payments</p>
                          </Link>
                          <Link to = "/shipping"> 
                          <p>Shipping</p>
                          </Link>
                          <Link to = "/product-return">
                          <p>Product Returns</p>
                          </Link>
                          <Link to="/faqs1">
                            {" "}
                            <p>FAQ</p>
                          </Link>
                          {/* <p>Other Issues</p> */}
                        </div>
                      </div>
                    </div>                      
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 p-0">
                      <div className="line">
                        <h4>Documents</h4>
                        <div className="list">
                          <Link to="/privacy">
                            {" "}
                            <p>Privacy Policy</p>
                          </Link>
                          <Link to="/return">
                          <p>Return Policy</p>
                          </Link>
                          <Link to="/terms">
                            <p>Terms of use</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12 p-0">
                      <div className="line">
                        <h4>Download App</h4>
                        <div className="list">
                       <p>

                   
                            < a href="https://play.google.com/store/apps/details?id=com.quick.groceryapp" target="blank">  <img
                                className=" my-2"
                                src="\footerassets\googleplay.png"
                              /></a>
                           </p>
                           <p>
                       < a className="mt-3" href="https://apps.apple.com/pk/app/quick-grocery-shopping/id1643695889" target="blank">  <img
                                className="  "
                                src="\footerassets\appstore.png"
                              /></a>
                                  </p>
                        </div>
                      </div>
                    </div>

                 
                  </div>
                </div>
              </div>

              <hr className="hr"></hr>

              <div className="row">
                <div className="col-xl-11 col-12 p-0 m-auto">
                  <div className="last">
                    <p>
                      ©2023<span className="spa"> Quick</span>, All rights
                      reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
