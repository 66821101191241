import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import axios from "axios";
import Environment from "../utils/environment";
import "./allfeaturedbrandsnew.scss";
import MainLoader from "../utils/MainLoader";


const Allfeaturedbrandsnew = () => {

  const val = localStorage.getItem("accessToken");

  const history = useHistory();
  const api_url = Environment.base_url;
  const [brandData, setBrandData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const getFeaturedBrands = async () => {
    setIsLoading(true);
    await axios
      .get(`${api_url}/brands/all-publish?offset=1&&limit=400`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setBrandData(res?.data?.data?.brands);
      })
      .catch((err) => { });
    setIsLoading(false);
  };
  useEffect(() => {
    getFeaturedBrands();
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const filteredBrands = brandData.filter((item) =>
      item?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filteredBrands);
  }, [searchQuery, brandData]);






  return (
    <>
      {isLoading && <MainLoader />}
      <section className="main-category">
        <div className="container-fluid">
          <div className="row">
            <div className="col-11 m-auto p-0 ">
              <div className="mainoterdiv">
                <div className="outermaininputc">
                  <h1 className="allcad">
                    All Featured Brands - Quick Grocery Shopping
                  </h1>
                  <div className="inputscrhbar">
                    <input value={searchQuery}
                      onChange={(e) =>
                        setSearchQuery(e.target.value)
                      } className="featurinputonly">

                    </input>
                    <svg className="inputimgonly" xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                      <rect x="0.5" width="32" height="32" rx="16" fill="#F7C24A" />
                      <path d="M16.1249 22.7499C20.0599 22.7499 23.2499 19.56 23.2499 15.6249C23.2499 11.6899 20.0599 8.49994 16.1249 8.49994C12.1898 8.49994 8.99988 11.6899 8.99988 15.6249C8.99988 19.56 12.1898 22.7499 16.1249 22.7499Z" stroke="#264653" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M24.0001 23.4999L22.5001 21.9999" stroke="#264653" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>


                <div className="allinonecardss">
                  {filteredData?.map((data, index) => {
                    return (
                      <>
                        <Link
                          to={{
                            pathname: "/all-categories",
                            state: {
                              cat: data?.id,
                              type: "brand",
                            },
                          }}
                        >
                          <div key={index} className="card-browsesnd">
                            <div className="inner-imgssss pulse-animation">
                              <img
                                src={data?.imageUrl}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </Link>
                      </>
                    )
                  })}


                </div>

              </div>

            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default Allfeaturedbrandsnew;
