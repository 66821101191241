// import React, { useState, useEffect } from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
// import "./browsecategory.scss";
// import OwlCarousel from "react-owl-carousel";
// import axios from "axios";
// import Environment from "../../utils/environment";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import { Spinner } from "react-bootstrap";

// const BrowseCategory = ({ categoryData }) => {
//   const val = localStorage.getItem("accessToken");

//   const history = useHistory();
//   const api_url = Environment.base_url;
//   // const [categoryData, setCategoryData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const getCategories = async () => {
//     setIsLoading(true);
//     await axios
//       .get(`${api_url}/store/categories/minimal?offset=1&limit=11`, {
//         headers: {
//           Authorization: "Bearer " + val,
//         },
//       })
//       .then((res) => {
//         setIsLoading(false);
//         // setCategoryData(res?.data?.data?.categories);
//       })
//       .catch((err) => {});
//     setIsLoading(false);
//   };

//   useEffect(() => {
//     // getCategories();
//   }, []);

//   const owl_option = {
//     nav: true,
//     dots: false,
//     dotsEach: false,
//     loop: false,
//     autoplay: false,
//     navText: [
//       "<i class='fas fa-caret-left'></i>",
//       "<i class='fas fa-caret-right'></i>",
//     ],
//     responsive: {
//       0: {
//         items: 1,
//         margin: 15,
//       },
//       361: {
//         items: 2.2,
//         loop: true,
//         margin: 15,
//       },
//       600: {
//         items: 1,
//         margin: 15,
//       },
//       700: {
//         items: 3.5,
//         margin: 40,
//         loop: true,
//       },
//       1000: {
//         items: 4.5,
//         margin: 40,
//         loop: true,
//       },
//       1200: {
//         items: 6,
//         margin: 20,
//       },
//     },
//   };

//   return (
//     <>
//       <section className="browsecategory">
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-xl-11 col-12 m-auto p-0">
//               <div className="main-heading">
//                 <h4>
//                   Categories{" "}
//                   <Link to="/all-categories">
//                     All Categories{" "}
//                     <img
//                       src="\assets\btn-icons\arrow-right.svg"
//                       alt="img"
//                       className="img-fluid ml-2"
//                     />
//                   </Link>
//                 </h4>
//               </div>

//               <div class="owl_option">
//                 {categoryData?.length > 0 ? (
//                   <OwlCarousel
//                     className="owl-theme Browse__by__Category"
//                     {...owl_option}
//                   >
//                     {categoryData?.map((item) => {
//                       return (
//                         <>
//                           <Link
//                             to={{
//                               pathname: "/all-categories",
//                               state: {
//                                 cat: item?.id,
//                                 type: "cat",
//                               },
//                             }}
//                           >
//                             <div className="card-browse">
//                               <div className="inner-img">
//                                 <img
//                                   src={item?.imageUrl}
//                                   alt="img"
//                                   className="img-fluid"
//                                 />
//                               </div>
//                               <h5>{item?.name}</h5>
//                             </div>
//                           </Link>
//                         </>
//                       );
//                     })}
//                   </OwlCarousel>
//                 ) : isLoading ? (
//                   <div className="text-center py-4">
//                     <Spinner animation="border" variant="info" />
//                     {/* <div className="loader-image-landing">
//                       <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
//                     </div> */}
//                   </div>
//                 ) : (
//                   <h5>No Categories To Show</h5>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default BrowseCategory;

import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./browsecategory.scss";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import Environment from "../../utils/environment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from "react-bootstrap";

const BrowseCategory = ({ categoryData }) => {
  const val = localStorage.getItem("accessToken");

  const history = useHistory();
  const api_url = Environment.base_url;
  // const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCategories = async () => {
    setIsLoading(true);
    await axios
      .get(`${api_url}/store/categories/minimal?offset=1&limit=11`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setIsLoading(false);
        // setCategoryData(res?.data?.data?.categories);
      })
      .catch((err) => { });
    setIsLoading(false);
  };

  useEffect(() => {
    // getCategories();
  }, []);

  const category = () => {

  }

  const owl_option = {
    nav: true,
    dots: false,
    dotsEach: false,
    loop: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-caret-left'></i>",
      "<i class='fas fa-caret-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      361: {
        items: 2.2,
        loop: true,
        margin: 15,
      },
      600: {
        items: 1,
        margin: 15,
      },
      700: {
        items: 3.5,
        margin: 40,
        loop: true,
      },
      1000: {
        items: 4.5,
        margin: 40,
        loop: true,
      },
      1200: {
        items: 7,
        margin: 20,
      },
      1900: {
        items: 9,
        margin: 20,
      },
    },
  };

  return (
    <>
      <section className="browsecategory">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="main-heading">
                <h4>
                  Browse by Category
                  <Link to="/Allcategoriesnew">
                    All Categories{" "}
                    <img
                      src="\assets\btn-icons\arrow-right.svg"
                      alt="img"
                      className="img-fluid ml-2"
                    />
                  </Link>
                </h4>
              </div>
              <div class="owl_option">
                <OwlCarousel
                  className="owl-theme Browse__by__Category"
                  {...owl_option}
                >

                  {/* <a
                
                  >
                    <div className="card-browse">
                      <div className="inner-img pulse-animation">
                        <img
                          src="categories-icons\pharmacy.png"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <h5>Pharmacy</h5>
                    </div>
                  </a> */}
                  {categoryData?.map((item, index) => {
                    return (
                      <>
                        <a
                          href={"/all-categories?category=" + encodeURIComponent(item?.name)}
                          key={index}
                        //  to={{
                        //    pathname: "/all-categories",
                        //    state: {
                        //      cat: item?.id,
                        //      type: "cat",
                        //    },
                        //  }}
                        >
                          <div className="card-browse">
                            <div className="inner-img pulse-animation">
                              <img
                                src={item?.imageUrl}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <h5>{item?.name}</h5>
                          </div>
                        </a>
                      </>
                    )
                  })}



                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BrowseCategory;

