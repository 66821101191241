import React, { useEffect } from 'react'
import Navbar from '../landing/header/Navbar'
import "./privacy.scss"
import { Link } from 'react-router-dom/cjs/react-router-dom'
const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {/* <Navbar /> */}
            <section className="bread-crumb">
                <div className="bread">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <Link to="/">
                                <li class="breadcrumb-item">
                                    <a href="#">Home /</a>
                                </li>
                            </Link>
                            <li class="breadcrumb-item">
                                <a href="#">Privacy Policy</a>
                            </li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section className='main-privacy'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-11 m-auto'>
                            <Link to='/'>
                                <div className='backbtn'>
                                    <a>Back</a>
                                </div>
                            </Link>
                            <div className='privacy-heading'>
                                <h2>Privacy Policy </h2>
                                <h4>WELCOME TO THE QUICK SHOP</h4>
                                <p>At Quick Shop, accessible from <Link to="/">quick.shop</Link>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Quick Shop and how we use it.</p>
                                <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
                                <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regard to the information that they share and/or collect in Quick Shop. This policy is not applicable to any information collected offline or via channels other than this website. </p>
                                <h4 className='consent'>CONSENT</h4>
                                <p className='font-bold'>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                                <h5>INFORMATION WE COLLECT</h5>
                                <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                                <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                                <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                                <h4 className='consent'>HOW WE USE YOUR INFORMATION</h4>
                                <p className='font-bold'>We use the information we collect in various ways, including</p>
                                <p className='list'>1: Provide, operate, and maintain our website</p>
                                <p className='list'>2: Improve, personalize, and expand our website</p>
                                <p className='list'>3: Understand and analyze how you use our website</p>
                                <p className='list'>4: Develop new products, services, features, and functionality</p>
                                <p className='list'>5: Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</p>
                                <p className='list'>6: Send you emails</p>
                                <p className='list'>7: Find and prevent fraud</p>
                                <h4 className='consent'>LOG FILES</h4>
                                <p>Quick Shop follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and are a part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
                                <h4 className='consent'>COOKIES AND WEB BEACONS</h4>
                                <p>Like any other website, Quick Shop uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
                                <h4 className='consent'>GOOGLE DOUBLE CLICK DART COOKIE</h4>
                                <p>Google is one of the third-party vendors on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following link <a href=' https://policies.google.com/technologies/ads' target='blank'> google.com/technologies/ads</a></p>
                                <h4 className='consent'>ADVERTISING PARTNER’S PRIVACY POLICIES</h4>
                                <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Quick Shop.</p>
                                <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Quick Shop, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
                                <p>Note that Quick Shop has no access to or control over these cookies that are used by third-party advertisers.</p>
                                <h4 className='consent'>THIRD-PARTY PRIVACY POLICIES</h4>
                                <p>Quick Shop's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt out of certain options.</p>
                                <h4 className='consent'>CCPA PRIVACY RIGHTS (DO NOT SELL MY PERSONAL INFORMATION)</h4>
                                <p>Under the CCPA, among other rights, users have the right to:</p>
                                <p>Request that a business that collects a user's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                                <p>Request that a business deletes any personal data about the user that a business has collected.</p>
                                <p>Request that a business that sells a user’s personal data, not sell this personal data.</p>
                                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                                <h4 className='consent'>GDPR DATA PROTECTION RIGHTS</h4>
                                <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                                <p><span>The right to access – </span>You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                                <p><span>The right to rectification – </span> You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                                <p><span>The right to erasure –</span>You have the right to request that we erase your personal data, under certain conditions.</p>
                                <p><span>The right to restrict processing –</span>You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                                <p><span>The right to object to processing – </span>You have the right to object to our processing of your personal data, under certain conditions.</p>
                                <p><span>The right to data portability –</span> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                                <h4 className='consent'> CHILDREN'S INFORMATION</h4>
                                <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                                <p>Quick Shop does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                                <p>For any queries reach us at<a href='mailto:Info@quick.shop'> Info@quick.shop</a></p>
                            </div>

                            {/*   <div className='privacy-policy-heading'>
                                <h3>Security & Privacy Policy</h3>
                                <p>We at Quick Grocery Shopping  (us, we, our) know that users (you, your) and customers (you, your) care about how their personal information is collected, used and shared.</p>
                                <p>Keeping your personal information private is something very important for us and we take it seriously, therefore, we have created the “Security & Privacy Policy” so that you and other customers and users can understand how we may collect, use and disclose personal data, whether through the  website at (www.quick.shop) (“Site”), or our mobile application (“App”).</p>
                            </div>

                            <div className='privacy-policy-heading'>
                                <h3>Consent</h3>
                                <p>By accessing the Site or the App providing personal data to us, you hereby acknowledge that you have read the “Security & Privacy Policy” and that you consent to it.</p>
                                <p> If you do not consent to the terms of this Policy, please do not access the Site, App or provide your personal data to us.</p>
                            </div>

                            <div className='privacy-policy-heading'>
                                <h3>What is Personal Data?</h3>
                                <p>“Personal data” means data about a person who can be identified (I) from that data, or (ii) from that data and other information to which we have or likely to have access to.</p>
                            </div>

                            <div className='privacy-policy-heading'>
                                <h3>What personal data do we collect?</h3>
                                <p>We gather different types of personal data of our users and customers, as explained more fully below.</p>
                                <p>Data that is voluntarily provided. We collect personal data that you voluntarily provide to us, whether through the Site, App or other platforms or channels such as email, telephone and other forms of communication.</p>
                                <p>At various places on the Site or App, or in the course of obtaining the services provided by us, you may be required to provide certain personal data such as your full name, email address, address, country of residence, telephone number and other personal information, in order to access or use certain features or services of the Site or App, or to otherwise obtain our services.</p>
                                <p>You can always choose not to provide your personal data, but you may not be able to access or use certain features or services of the Site or App, or to obtain certain services from us.</p>
                                <p>Browser, computer, mobile or tablet device. This may include your IP address, the address of the web page you were visiting when you accessed our Site, date and time of your visit, information about the device you are using and other information.</p>
                                <p>Please check the settings of your browser and/or device if you want to know what data is automatically sent or if you wish to change your settings. Please also refer to the section on “Use of Cookies” below.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>How do we use your data?</h3>
                                <p>Generally, we collect personal data in order to provide our products and services to you, and to personalize and improve our services and your user and customer experience with us. Personal data collected by us may be used for:</p>
                                <p> - to process, record, monitor and fulfil your orders and deliveries</p>
                                <p> - to plan and schedule routes for deliveries</p>
                                <p> - to verify and process your personal particulars and payment</p>
                                <p>- to communicate with you, including to confirm and update you on the status of your orders and deliveries, and respond to your queries and requests</p>
                                <p> - to improve our products or services</p>
                                <p> - to inform you of promotions, offers, surveys, events, products and services, which may be of interest to you</p>
                                <p> - to conduct marketing research, user profile and statistical analysis</p>
                                <p> - to customize your online experience according to your chosen interests and preferences and enhance your current and future visits to the Site, use of the App and customer experience with us</p>
                                <p> - to update and back-up our records</p>
                                <p> - to reduce credit risk, detect and protect us against error, fraud and other criminal activity</p>
                                <p> - to comply with law, the requests of law enforcement and regulatory officials, or orders of court</p>
                                <p> - to enforce our legal rights and remedies</p>
                                <p>- for such other purposes notified to you on or before collection or use of the data.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>Who do we disclose your data to?</h3>
                                <p><span>Quick Grocery Shop</span> uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem.</p>
                                <p>If you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such services and to monitor your use of such services.<span> Quick Grocery Shop</span> and its subsidiaries and affiliates (the “Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users and to improve the services provided.</p>
                                <p>Quick Grocery Shop and its affiliates may use this information to contact you in the future to tell you about services we believe will be of interest to you. If we do so, each marketing communication we send you will contain instructions permitting you to "opt-out" of receiving future marketing communications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.</p>
                                <p>If  Quick Grocery Shop intends on using any Personal Data in any manner that is not consistent with this Privacy Policy, you will be informed of such anticipated use prior to or at the time at which the Personal Data is collected.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>How long do we retain your data?</h3>
                                <p> Personal data provided by you will be retained as long as the purpose for which the data was collected continues. Thereafter, we will destroy or delete the information, or remove the means by which the data can be associated with you, unless its retention is required to satisfy legal, regulatory, accounting or other business requirements or to protect our interests.</p>
                            </div>
                              <div className='privacy-policy-heading'>
                                <h3>Is your data secure? </h3>
                                <p>As your Account is protected by a password for your privacy and security, we ask that you take steps to protect against unauthorized access or use of your Account. Please select and protect your password appropriately and limit access to your device and browser by signing off after you have finished accessing your Account. You are responsible for any loss, theft and compromise of your password and Account Information, and any activity on your Account that takes place through unauthorized password use.</p>
                                <p>We understand the importance of keeping your personal data private and secure. We therefore put in place security arrangements to protect your privacy and personal data, in a manner that</p>
                                <p>we believe is reasonably appropriate to prevent unauthorized access, collection, use, disclosure, copying, modification, disposal or similar risks. Please be informed that despite our best efforts, no security measures are impenetrable. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of your personal data. We do not warrant or guarantee in any way that your personal information or private communications will always remain private and/or safe. We hereby disclaim any responsibility or liability directly or indirectly arising out of or in connection with, any loss, theft, or unauthorized access, collection, use, disclosure, copying, modification, disposal or similar actions with regard to any personal data held or maintained by us, except to the extent caused by our fault or negligence.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>Linked Sites</h3>
                                <p>We may provide links to other sites ("Linked Sites") that may be of relevance and interest to you. We have no control over, and are not responsible for the privacy practices or the content of such Linked Sites, and you hereby waive any claim against us with respect to the Linked Sites.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>Use of Cookies</h3>
                                <p>In order to improve our products and services, we collect data by way of “cookies”. Cookies help us measure the number of visits, average time spent, page views and other statistics relating to your access to the Site or App. This information allows us to better administer the Site and App, and provide a more tailored and user-friendly service to our Site visitors and App users. Cookies also enable you to use or access certain features or services of our Site or App, including the shopping cart and check-out features. For example, cookies help us store preferences or retrieve products in your shopping cart between visits, from our servers. Cookies are small data files that are sent to your browser from our Site or App and are stored on your computer’s or device’s hard drive. Each time you visit our Site or App from the same computer or device, the cookie will be retrieved from your computer or device, enabling our Site or App to recognize your computer or device as having previously visited our Site or App and thereby increase the functionality of our Site or App on your computer or device. Our cookies cannot be used to get data from your hard drive, to get your email address or any other data that can be traced to you personally. Most web browsers and devices can be set to notify you when you receive a cookie or to prevent cookies from being sent; if you use these features, you may limit the functionality we can provide you when you visit our Site or App.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>How to access and correct your data?</h3>
                                <p>If you wish to access, correct or update your personal data, you may do so at any time by accessing your Account settings through the Site or App. Alternatively, you may contact us at <span> Info@quick.shop.</span> You are responsible for providing us with correct and updated information.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>What choices do you have?</h3>
                                <p>Keep in mind that the information we gather is in an effort to offer you a superior online shopping experience that is customized. You may be able to access, correct or update certain</p>
                                <p>Information as explained previously. When you update information, however, we may maintain a copy of the unrevised information in our record.</p>
                                <p>If you do not wish to receive email or other communications from us, you can unsubscribe from our mailing list by using the unsubscribe facility that may be provided with such email or communication, or contact us at <span> Info@quick.shop</span> or at our operating hours mentioned above.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>Questions or Concerns</h3>
                                <p>Please direct any questions or concerns that you may have regarding our handling of your personal data to:<span> Info@quick.shop</span>  </p>
                                <p>Please provide your name, contact number, email address and all relevant details along with your question or concern, so that we can review and respond to your question or concern in an efficient and effective manner.</p>
                            </div>
                            <div className='privacy-policy-heading'>
                                <h3>Right to Amend Policy</h3>
                                <p>We reserve the right to amend the prevailing Security & Privacy Policy at any time and will place any such amendments on this Site or App.</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Privacy