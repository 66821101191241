import {
  React,
  useEffect,
  useState,
  useSyncExternalStore,
  useRef,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import MainLoader from "../utils/MainLoader";
import Navbar from "../landing/header/Navbar";
import Related from "../RelatedProduct/Related";
import "./productdetail.scss";
import axios from "axios";
import moment from "moment";
import Environment from "../utils/environment";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { cartItems } from "../../redux/action/index";
import Location from "../Location/Location";
import imgliketransparent from "../../assets/like-transparent.svg";
import imglikeblue from "../../assets/like-blue.svg";
import Dropdown from "react-bootstrap/Dropdown";

const Productdetail = (props) => {
  const [activeTab1, setActiveTab1] = useState(0);
  const [id, setId] = useState("");

  //   const { id } = useParams();
  //   const id = props.location.state.id;
  const dispatch = useDispatch();
  const [shopcategory, setShopCategory] = useState([]);
  const [counter, setCounter] = useState(0);
  const [pharCounter, setPharCounter] = useState(0);
  const [phar, setPhar] = useState(0);
  const history = useHistory();
  const api_url = Environment.base_url;
  const [productDetail, setProductDetail] = useState([]);
  const [catName, setCatName] = useState("");
  const val = localStorage.getItem("accessToken");

  const [loader, setLoader] = useState(null);


  const decrease = () => {
    if (counter > 0) {
      setCounter((count) => count - 1);
    }
  };

  const goBack = () => {
    history.goBack();
    prorefresh();
  };

  const prorefresh = () => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 250);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getProductDetails();
    }
  }, [id]);

  const [catId, setCatId] = useState("");
  const [discount, setDiscount] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      window.scroll(0, 0);
    }
  }, [isLoading]);
  
  const getProductDetails = async () => {
    setIsLoading(true);
    setLoader(true);
    const config = {
      method: "get",
      url: `${api_url}/store/products/${id}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setProductDetail(res?.data?.data);
        setCatName(res?.data?.data?.category[0]?.name);
        setCatId(res?.data?.data?.category[0]?.id);
        setDiscount(res?.data?.data?.discountedProducts[0].discount);
        setLoader(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
        setIsLoading(false);
      });
  };
 
  //   useEffect(() => {
  //     getProductDetails();
  //   }, [id]);

  const [show12, setShow12] = useState(false);
  const handleCartItem = (productToAdd) => {
    setCounter((count) => count + 1);
    productToAdd.cartAmount = 1;
    const temp = [productToAdd];
    temp.push(1);

    dispatch(cartItems(temp));
    // setShow12(true);
  };

  const handleCartItemTwo = (productToAdd) => {
    setPharCounter((pharCounter) => pharCounter + 1);
    setPhar((phar) => phar + productToAdd?.packSize);
    productToAdd.cartAmount = productToAdd?.packSize;
    const temp = [productToAdd];
    temp.push( productToAdd?.packSize);

    dispatch(cartItems(temp));
    // setShow12(true);
};

const handleCartItemMinus = (productToAdd) => {
  setPharCounter((pharCounter) => pharCounter - 1);
  setPhar((phar) => phar - productToAdd?.packSize);
  productToAdd.cartAmount = -productToAdd?.packSize;
  const temp = [productToAdd];
  temp.push( -productToAdd?.packSize);

  dispatch(cartItems(temp));
  // setShow12(true);
  // setShow12(true);
};

  const handleCartItemdes = (productToAdd) => {
    setCounter((count) => count - 1);
    productToAdd.cartAmount = -1;
    const temp = [productToAdd];
    temp.push(-1);

    dispatch(cartItems(temp));
    // setShow12(true);
  };

  const [wishlist, setWishlist] = useState([]);
  const [wishCount, setWishCount] = useState(0);
  const [cls, setcls] = useState(false);
  const AddtoWishList = (data) => {
    data.wish = 1;
    let temp = [];
    if (localStorage.getItem("wishlist") == null) {
      temp = [];
    } else {
      temp = JSON.parse(localStorage.getItem("wishlist"));
    }

    let existingItem = temp.find((item) => item?.id == data?.id);
    if (existingItem) {
      setcls(true);
      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?.wish % 2 == 1) {
          temp.splice(i, 1);
          localStorage.removeItem("wishlist");
          localStorage.setItem("wishlist", JSON.stringify(temp));
        }
      }
    } else {
      setcls(false);
      temp.push(data);
      localStorage.setItem("wishlist", JSON.stringify(temp));
    }

    setWishlist(temp);

    setWishCount(temp.length);
  };

  useEffect(() => {
    const data = localStorage.getItem("wishlist");
    if (data) {
      const wishlistItems = JSON.parse(data);
      const existingItem = wishlistItems.find((item) => item.id === data.id);
      if (existingItem) {
        setcls(true);
      } else {
        setcls(false);
      }
    }
  }, []);

  const getShopCategories = async () => {
    await axios
      .get(
        `${Environment.base_url}/store/categories?parentOnly=true&afterElement=0&limit=50&allCategories=false`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setShopCategory(res?.data?.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getShopCategories();
  }, []);

  return (
    <>
      {isLoading && <MainLoader />}
      {/* <Navbar
        cartItems={cartItems}
        wishCount={wishCount}
        setWishCount={setWishCount}
        setWishlist={setWishlist}
        wishlist={wishlist}
      /> */}
      {isLoading ? (
        <div className="loader-image-product-detail">
          <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
        </div>
      ) : (
        <section className="productdetail">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12 m-auto p-0">
                <div className="top-button d-flex">
                  <img
                    onClick={goBack}
                    className="back"
                    src="assets\btn-icons\Frame 6.png"
                  ></img>
                  <Link to="/all-categories">
                    <button className="all-product">All Product</button>
                  </Link>
                  {/* <button >Dairy & Eggs<img  src="assets\btn-icons\Vector.svg" alt="Icon"></img></button> */}
                  {catName ? <Dropdown>
                    {/* <Dropdown.Toggle className="all-product2" variant="success" id="dropdown-basic">
                    {catName}
                      <img className="" src="assets\btn-icons\Vector.svg" alt="Icon"></img>
                    </Dropdown.Toggle> */}
                    <Dropdown.Toggle
                      className="all-product2"
                      variant="success"
                      id="dropdown-basic"
                    >
                      {catName.slice(0, 10)}
                      {catName.length > 10 ? "..." : ""}
                      <img
                        className=""
                        src="assets\btn-icons\Vector.svg"
                        alt="Icon"
                      ></img>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {shopcategory.map((data, index) => {
                        return (
                          <>
                            <Dropdown.Item>


                              <Link to={`/all-categories?category=${encodeURIComponent(data?.name)}`}>
                                {data?.name}
                              </Link>
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown> : ""}

                </div>
                <div className="productmargin">
                  <div className="row distop">
                    <div className="col-xl-4 col-lg-6 col-12 padd-md">
                      <div className="mainproduct">
                        <div className="backto"></div>
                        {discount && <h6>{discount}% off</h6>}
                        <img
                          src={productDetail?.imageUrl ?productDetail?.imageUrl:"assets\Frame 186 (1).png"}
                          alt="img"
                          // className="innerimage"
                          className={
                            productDetail?.sourceProductId != null
                              ? productDetail?.quantity <=
                                productDetail?.outOfStockThreshold
                                ? "outimagee"
                                : "innerimage"
                              : productDetail?.productCap?.maxCap <=
                                productDetail?.productCap?.todaySold
                                ? "outimagee"
                                : "innerimage"
                          }
                        />
                      </div>
                    </div>
                    {productDetail?.packSize === 1 ?   <div className="col-xl-4 col-lg-6 col-12 padd-md mid-detail">
                      <div className="information">
                        <div className="writteninfo">
                          {/* <p className="para">
                          Home Shop Coffees and Teas Fizzy Drinks Taylors of<br></br>
                          Harrogate Yorkshire Coffee
                        </p> */}
                          <h5 className="head">
                            {productDetail?.product?.name ||
                              productDetail?.name}
                          </h5>
                          <p className="secondpara">
                            {productDetail?.description}
                          </p>
                          {/* <p className="weight">
                          {productDetail?.product?.unitQuantity ||
                            productDetail?.unitQuantity}

                          <span className="ps-1">
                            {productDetail?.unit?.abbreviation}
                          </span>
                        </p> */}
                          <p className="discount">
                            {/* Rs. {productDetail?.price} */}
                            {discount && (
                              <del style={{ color: "red" }}>
                                Rs.{" "}
                                {productDetail?.product?.price ||
                                  productDetail?.price}
                              </del>
                            )}
                            <h6 className="discount1">
                              Rs.{" "}
                              {Math.trunc(
                                productDetail?.price -
                                productDetail?.price * (discount / 100)
                              ) || 0}
                            </h6>
                            {/* <span className="price">Rs. 2,800</span> */}
                          </p>
                          {productDetail?.sourceProductId != null ? (
                            productDetail?.quantity <=
                              productDetail?.outOfStockThreshold ? (
                              <span className="outstock">Out of Stock</span>
                            ) : (
                              <span className="stock">In Stock</span>
                            )
                          ) : productDetail?.productCap?.maxCap <=
                            productDetail?.productCap?.todaySold ? (
                            <span className="outstock">Out of Stock</span>
                          ) : (
                            <span className="stock">In Stock</span>
                          )}
                        </div>
                        <div className="quantityinfo">
                          <p className="qpara">Quantity:</p>
                          <div className="innercart">
                            <div className="counter">
                              <div className="btn__container">
                                <button
                                  // className="control__btn minusbtn"
                                  className={
                                    counter === 0 ? "minusbtn" : "minusbtn2"
                                  }
                                  onClick={() => handleCartItemdes(productDetail)}
                                  disabled={counter <= 0}
                                >
                                  -
                                </button>
                                <span className="counter__output">
                                  {counter}
                                </span>
                                <button
                                   className={
                                    counter === productDetail?.quantity-productDetail?.outOfStockThreshold ? "control__dis" : "control__btn"
                                  }
                                  onClick={() => handleCartItem(productDetail)}
                                  disabled={
                                  counter === productDetail?.quantity-productDetail?.outOfStockThreshold 
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            {/* <button
                              className="cartbtn"
                              onClick={() => handleCartItem(productDetail)}
                            >
                              Add to cart
                            </button> */}
                          </div>
                          {/* <div className="wishlist">
                          <img
                            onClick={() => AddtoWishList(productDetail)}
                            src={cls ? imglikeblue : imgliketransparent}
                            alt="img"
                            className={
                              cls == productDetail?.productId
                                ? "img-fluid 11"
                                : "img-fluid 22"
                            }
                          />
                          <p className="wishpara">Wishlist</p>
                        </div> */}
                        </div>
                      </div>
                    </div>: <div className="col-xl-4 col-lg-6 col-12 padd-md mid-detail">
                      <div className="information">
                        <div className="writteninfo">
                          {/* <p className="para">
                          Home Shop Coffees and Teas Fizzy Drinks Taylors of<br></br>
                          Harrogate Yorkshire Coffee
                        </p> */}
                          <h5 className="head">
                            {productDetail?.product?.name ||
                              productDetail?.name}
                          </h5>
                          <p className="secondpara">
                            {productDetail?.description}
                          </p>
                          {/* <p className="weight">
                          {productDetail?.product?.unitQuantity ||
                            productDetail?.unitQuantity}

                          <span className="ps-1">
                            {productDetail?.unit?.abbreviation}
                          </span>
                        </p> */}
                          {productDetail?.sourceProductId != null ? (
                            productDetail?.quantity <=
                              productDetail?.outOfStockThreshold ? (
                              <span className="outstock">Out of Stock</span>
                            ) : (
                              <span className="stock">In Stock</span>
                            )
                          ) : productDetail?.productCap?.maxCap <=
                            productDetail?.productCap?.todaySold ? (
                            <span className="outstock">Out of Stock</span>
                          ) : (
                            <span className="stock">In Stock</span>
                          )}
                        </div>
                        <div className="lowertabsss">
                          <div className="lowertabssssx">

                            <a className={activeTab1 == 0 ? 'tabshnd active' : 'tabshnd'} onClick={() => { setActiveTab1(0) }}>Tablet (s)</a>
                            <a className={activeTab1 == 1 ? 'tabshnd active' : 'tabshnd'} onClick={() => { setActiveTab1(1) }}>Box (s)</a>

                          </div>

                        </div>
                        {activeTab1 == 0 && (
                          <>
                            <div className="quantityinfo">
                              <div className="pasasad">
                                <p className="tabelatsds">
                                  Rs. {productDetail?.price}/tablet
                                </p>
                              
                                <p className="tabelatsds">
                                  Pack Size: {productDetail?.packSize} tablets
                                </p>
                              </div>
                              <p className="qpara">Quantity:</p>
                              <div className="innercart">
                                <div className="counter">
                                  <div className="btn__container">
                                    <button
                                      // className="control__btn minusbtn"
                                      className={
                                        counter === 0 ? "minusbtn" : "minusbtn2"
                                      }
                                      onClick={() => handleCartItemdes(productDetail)}
                                      disabled={counter <= 0}
                                    >
                                      -
                                    </button>
                                    <span className="counter__output">
                                      {counter}
                                    </span>
                                    <button
                                      className={
                                        counter === productDetail?.quantity - productDetail?.outOfStockThreshold ? "control__dis" : "control__btn"
                                      }
                                      onClick={() => handleCartItem(productDetail)}
                                      disabled={
                                        counter === productDetail?.quantity - productDetail?.outOfStockThreshold
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                                {/* <button
                                  className="cartbtn"
                                  onClick={() => handleCartItem(productDetail)}
                                >
                                  Add to cart
                                </button> */}
                              </div>
                              {/* <div className="wishlist">
                          <img
                            onClick={() => AddtoWishList(productDetail)}
                            src={cls ? imglikeblue : imgliketransparent}
                            alt="img"
                            className={
                              cls == productDetail?.productId
                                ? "img-fluid 11"
                                : "img-fluid 22"
                            }
                          />
                          <p className="wishpara">Wishlist</p>
                        </div> */}
                            </div>
                          </>
                        )}
                        {activeTab1 == 1 && (
                             <>
                             <div className="quantityinfo">
                               <div className="pasasad">
                                 <p className="tabelatsds">
                                   Rs. {productDetail?.packPrice}/Box
                                 </p>
                                 <p className="tabelatsds">
                                   Pack Size: {productDetail?.packSize} tablets
                                 </p>
                               </div>
                               <p className="qpara">Quantity:</p>
                               <div className="innercart">
                                 <div className="counter">
                                   <div className="btn__container">
                                     <button
                                       // className="control__btn minusbtn"
                                       className={
                                         pharCounter === 0 ? "minusbtn" : "minusbtn2"
                                       }
                                       onClick={() => handleCartItemMinus(productDetail)}
                                       disabled={pharCounter <= 0}
                                     >
                                       -
                                     </button>
                                     <span className="counter__output">
                                       {pharCounter}
                                     </span>
                                     <button
                                       className={
                                        pharCounter === productDetail?.quantity - productDetail?.outOfStockThreshold || phar + productDetail?.packSize > productDetail?.quantity - productDetail?.outOfStockThreshold ? "control__dis" : "control__btn"
                                       }
                                       onClick={() => handleCartItemTwo(productDetail)}
                                       disabled={
                                         pharCounter === productDetail?.quantity - productDetail?.outOfStockThreshold ||  phar + productDetail?.packSize > productDetail?.quantity - productDetail?.outOfStockThreshold
                                       }
                                     >
                                       +
                                     </button>
                                   </div>
                                 </div>
                                 {/* <button
                                   className="cartbtn"
                                   onClick={() => handleCartItem(productDetail)}
                                 >
                                   Add to cart
                                 </button> */}
                               </div>
                               {/* <div className="wishlist">
                           <img
                             onClick={() => AddtoWishList(productDetail)}
                             src={cls ? imglikeblue : imgliketransparent}
                             alt="img"
                             className={
                               cls == productDetail?.productId
                                 ? "img-fluid 11"
                                 : "img-fluid 22"
                             }
                           />
                           <p className="wishpara">Wishlist</p>
                         </div> */}
                             </div>
                           </>
                        )}


                      </div>
                    </div>}
                   
                    <div className="col-xl-4 col-lg-12 col-12  padd-lg">
                      <div className="shippinginfo">
                        <div className="main">
                          <div className="outermain">
                            <img
                              src="\Assests\rocket.svg"
                              alt="img"
                              className="shipimg"
                            />
                            <div className="innermain">
                              <h5 className="shiphead">Free Delivery </h5>
                              <p className="shippara">
                                For certain order limits.
                              </p>
                            </div>
                          </div>
                          <div className="outermain">
                            <img
                              src="\Assests\one.svg"
                              alt="img"
                              className="shipimg"
                            />
                            <div className="innermain">
                              <h5 className="shiphead">
                                Sasti Tareen Grocery{" "}
                              </h5>
                              <p className="shippara">
                                Comparatively, lower prices.
                              </p>
                            </div>
                          </div>
                          <div className="outermain">
                            <img
                              src="\Assests\access.svg"
                              alt="img"
                              className="shipimg"
                            />
                            <div className="innermain">
                              <h5 className="shiphead">Quick Service </h5>
                              <p className="shippara">
                                Swift delivery service!{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="helpline">
                        <h5 className="helphead">Hotline Order</h5>
                        <p className="helppara">Mon - Fri: 7:00 am - 18:00PM</p>
                        <p className="helpnumber">+92 312 345 6789</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Related
        id={catId}
        setId={setId}
        AddtoWishList={AddtoWishList}
        handleAddToCart={handleCartItem}
      />

      <Location show12={show12} setShow12={setShow12} />
    </>
  );
};

export default Productdetail;
