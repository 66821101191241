import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import "./cartsuccess.scss"
const Cartsuccess = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className='main-success'>
        <div className='container-fluid p-0'>
          <div className='row'>
            <div className='col-xl-12 m-auto p-0'>
              <div className='row'>
                <div className='col-xl-12'>
                  <div className='parent1'>
                    <div className='main-img'>
                      <img src='assets\btn-icons\quick.gif' className='img-fluid' />
                    </div>
                    <div className='heading'>
                      <h2>Checkout Successful</h2>
                    </div>
                    <div className='orderbtn'>
                      <Link to='/account'><button>My Orders</button></Link>
                    </div>
                    <div className='back'>
                      <Link to='/'>Back to Home</Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
     
    </>

  )
}

export default Cartsuccess