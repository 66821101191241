import "./App.scss";
import { useState, useEffect } from "react";
import Landing from "./components/landing/Landing.js";
import Account from "./components/Account/Account";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/landing/footer/Footer";
import Cart from "./components/CartShop/Cart";
import Homeshop from "./components/CartShop/Homeshop";
import Cartsuccess from "./components/CartShop/Cartsuccess";
import Cartcheckout from "./components/CartShop/Cartcheckout";
import Allcategories from "./components/AllCategories/Allcategories";
import Login from "./components/Login/Login";
import Forgotpass from "./components/Forgotpass/Forgotpass";
import Checkemail from "./components/Checkemail/Checkemail";
import Newpass from "./components/Newpass/Newpass";
import Wishlist from "./components/Wishlist/Wishlist";
import Contactus from "./components/Contactus/Contactus";
import Terms from "./components/Terms-Condition/Terms";
import Privacy from "./components/Privacypolicy/Privacy";
import AboutUs from "./components/AboutUs/AboutUs";
import Careers from "./components/Careers/Careers";
import OtherIssues from "./components/OtherIssues/OtherIssues";
import LoyalityProgram from "./components/LoyalityProgram/LoyalityProgram";
import CorporateOrders from "./components/CorporateOrders/CorporateOrders";
import Faqs from "./components/Faqs/Faqs";
import Faqs1 from "./components/Faqs/Faqs1";
import Faqs2 from "./components/Faqs/Faqs2";
import Location from "./components/Location/Location";
import Productdetail from "./components/Productdetail/Productdetail";
import Related from "./components/RelatedProduct/Related";
import PaymentCartSuccess from "./components/CartShop/PaymentCartSuccess";
import Return from "./components/Return/Return";
import Productreturn from "./components/Productreturn/productreturn";
import Payment from "./components/payment/Payment";
import Shipping from "./components/Shipping/Shipping";
import Navbar from "./components/landing/header/Navbar";
import axios from "axios";
import Geocode from "react-geocode";
import Homeshopnew from "./components/CartShop/Homeshopnew";
import Allcategoriesnew from "./components/AllCategoriesnew/Allcategoriesnew.js";
import Allfeaturedbrandsnew from "./components/Allfeaturedbrandsnew/Allfeaturedbrandsnew.js";
// import OtherIssues from "./components/OtherIssues/OtherIssues";

function App() {
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [location, setLocation] = useState("");
  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [wishCount, setWishCount] = useState(0);
  const [catState, setCatState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function savePosition(position) {
    setLng(position?.coords?.longitude);
    setLat(position?.coords?.latitude);
  }
  function positionError(error) {
    if (error.PERMISSION_DENIED) {
      // history.push("/location");
      // axiosCallForLocation();
      // showError('Geolocation is not enabled. Please enable to use this feature.');
    } else {
      // Handle other kinds of errors.
    }
  }

  const ipURL = "https://ip.nf/me.json";
  const axiosCallForLocation = () => {
    axios
      .get(ipURL)
      .then((response) => {
        setLat(response?.data?.ip?.latitude);
        setLng(response?.data?.ip?.longitude);
      })
      .catch((err) => { });
  };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(savePosition);
  //   }
  // }, []);

  useEffect(() => {
    Geocode.setApiKey("AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0");

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        setLocation(address);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [lat, lng]);


  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        <Navbar
          cartCount={cartCount}
          setCartCount={setCartCount}
          cartItems={cartItems}
          setCartItems={setCartItems}
          locaiton={location}
          wishCount={wishCount}
          setWishCount={setWishCount}
          setCatState={setCatState}
        />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/home-shop" component={Homeshop} />
          <Route exact path="/home-sshop" component={Homeshopnew} />
          <Route exact path="/cart-success" component={Cartsuccess} />
          <Route
            exact
            path="/payment-cartsuccess"
            component={PaymentCartSuccess}
          />
          <Route exact path="/cart-checkout" component={Cartcheckout} />
          <Route
            exact
            path="/all-categories"
            render={(routeProps) => (
              <Allcategories {...routeProps} catState={catState} />
            )}
          />
          <Route exact path="/Allcategoriesnew" component={Allcategoriesnew} />
          <Route exact path="/Allfeaturedbrandsnew" component={Allfeaturedbrandsnew} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={Forgotpass} />
          <Route exact path="/checkemail" component={Checkemail} />
          {/* <Route exact path="/newpassword" component={Newpass} /> */}
          <Route exact path="/shopwishlist" component={Wishlist} />
          <Route exact path="/contact-us" component={Contactus} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/otherissues" component={OtherIssues} />
          <Route exact path="/loyality-program" component={LoyalityProgram} />
          <Route exact path="/corporate-orders" component={CorporateOrders} />
          <Route exact path="/faqs" component={Faqs} />
          <Route exact path="/faqs1" component={Faqs1} />
          <Route exact path="/faqs2" component={Faqs2} />
          <Route exact path="/location" component={Location} />
          <Route exact path="/product-detail" component={Productdetail} />
          <Route exact path="/related" component={Related} />
          <Route exact path="/return" component={Return} />
          <Route exact path="/product-return" component={Productreturn} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/shipping" component={Shipping} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
