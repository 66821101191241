import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import "./contactus.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Environment from "../utils/environment";

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const api_url = Environment.base_url;

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const contactUs = async () => {
    const config = {
      method: "post",
      url: api_url + "/contact-us",

      data: {
        name: name,
        subject: subject,
        message: message,
        email: email,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
      });
  };
  useEffect(() => {
    prorefresh1();
  }, []);

  const prorefresh1 = () => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      {/* <Navbar /> */}
      <section className="main-contact">
        <div className="container-fluid p-0">



          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12 p-0">
              <div className="main-heading">
                <h2>Contact Information</h2>
              </div>
              <div className="location">
                <h3>Location</h3>
                <h4>Store</h4>
                <p>Plot 31, Faqir Aipee Road,Sector<br /> I-11/3 Islamabad</p>
                <div className='d-flex'>
                  <a href="https://wa.me/923005668000" target="_blank">
                    <img className='mt-3' src='assets\btn-icons\icons8-whatsapp (1).svg' ></img>
                    <p className='mt-3 pt-1 pl-3'>+92 300 566 8000</p></a>
                </div>
              </div>
              <div className="social-icon">
                <div className="social-head">
                  <h3>Socials</h3>
                </div>
                <div className="img-icons">
                  <a href="https://www.facebook.com/quickdotshop" target="_blank">
                    <img
                      src="\assets\social-icons\facebook1.svg"
                      className="img-fluid ml-5"
                    /></a>
                  <a href="https://twitter.com/quickdotshop" target="_blank">
                    <img
                      src="\assets\social-icons\twitter1.svg"
                      className="img-fluid"
                    /></a>
                  <a href="https://www.instagram.com/quickdotshop" target="_blank">
                    <img
                      src="\assets\social-icons\instagram1.svg"
                      className="img-fluid ml-5"
                    /></a>
                  <a href="https://www.linkedin.com/company/quickdotshop" target="_blank">
                    <img
                      src="\assets\social-icons\linkedin1.svg"
                      className="img-fluid pb-1"
                    /></a>
                </div>
              </div>
              {/* <div className="main-feild">
                <input
                  type="text"
                  placeholder="Subject"
                  onChange={(e) => setSubject(e.target.value)}
                />
                <textarea
                  className="areass"
                  placeholder="Write your message here"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <div className="secondfeild">
                  <input
                    type="text"
                    placeholder="Your Name"
                    className="firstfeild"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="messagebtn">
                  <button >Send message</button>
                </div>
              </div> */}
            </div>
            <div className="col-xl-8 col-lg-8 col-12">
              <div className="drop-head">
                {/* <h2>Drop Us A Line</h2> */}
                {/* <p className="drop-sub-1">Thank you for choosing Quick Grocery Shopping!</p>
                <p className="drop-sub">For queries & concerns, Contact us on <a href='mailto:Info@quick.shop'><span> Info@quick.shop</span></a></p> */}
              </div>


              <div className="main-imgcontact">
                {/* <img src="\assets\bg-imgs\Frame3.svg" className="img-fluid" />   */}
                {/* {isLoading ? (
                              <div className="loader-image-myaccounts">
                                <img
                                  src="assets\btn-icons\2nd_v2.gif"
                                  alt="Loading..."
                                />
                              </div>
                            ) : ( */}
                <div>
                  {/* <iframe
                    className="iframe-map"
                    title="Google Maps"
                    width="100%"
                    height="600"
                  
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=quick grocery  store i11/3&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                  </iframe> */}
                </div>
                {/* )} */}
              </div>
            </div>


          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;
