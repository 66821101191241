import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Navbar from '../landing/header/Navbar'
import "./loyalityprogram.scss"
const LoyalityProgram = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        // </Navbar>  
        <>
            <section className="bread-crumb">
                <div className="bread">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                        <Link to="/">
                            <li class="breadcrumb-item">
                                <a href="#">Home /</a>
                            </li>
                            </Link>
                            <li class="breadcrumb-item">
                                <a href="#">Loyality Program</a>
                            </li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section className='main-term'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-11 m-auto'>
                            {/* <div className='backbtn'>
                                <a>Back</a>
                            </div> */}
                            <div className='loyalityprogram-up'>
                                <h2>Quick Loyalty Program </h2>
                                {/* <h5>Shop, Earn, Redeem, & Enjoy!</h5> */}
                                <p className='upper-text'>Visit Quick Grocery Shopping today to apply for the loyalty program membership<br /> and gain special discounts & reward points.</p>
                            </div>
                            <div className='loyalityprogram-center'>
                                <div className='row'>
                                    <div className='col-xl-6'>
                                        <img className='loyality-card img-fluid' src='Assests\loyality card.png' ></img>
                                    </div>
                                    <div className='col-xl-6 center-right'>
                                        <h4>Benefits</h4>
                                        <div className='d-flex'>
                                            <img className='img-fluid' src='Assests\check.svg'></img>
                                            <p>Monthly 30k above shoppers will get a 2% Discount</p>
                                        </div>
                                        <div className='d-flex'>
                                            <img className='img-fluid' src='Assests\check.svg'></img>
                                            <p>Monthly 45k above shoppers will get a 3% Discount</p>
                                        </div>
                                        <div className='d-flex'>
                                            <img className='img-fluid' src='Assests\check.svg'></img>
                                            <p>Points will be collected, as you shop during the 90-day period. If you fail to shop during the 90-day period, your accumulated points will be expired.</p>
                                        </div>
                                        <div className='d-flex'>
                                            <img className='img-fluid' src='Assests\check.svg'></img>
                                            <p>You will be removed from the loyalty program; if no shopping occurs for more than 6 months.</p>
                                        </div>
                                        <div className='d-flex'>
                                            <img className='img-fluid' src='Assests\check.svg'></img>
                                            <p>10 points=10 Rupees</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='loyalityprogram-down'>
                                <div className='row'>
                                    <div className='col-xl-6 left'>
                                        <h2>Become part of our<br/>loyalty program</h2>
                                        <p>To get exclusive deals,discounts and offers</p>
                                    </div>
                                    <div className='col-xl-5 down-right'>
                                        <div className='d-flex'>
                                            <input className='loyality-input' placeholder='Email Here'></input>
                                            <input className='loyality-input-second' placeholder='Phone Number'></input>
                                        </div>
                                        <input className='loyality-email' placeholder='Address'></input>
                                        <button className='subcribe-btn'>Sign Up</button>
                                    </div>
                                    <div className='col-xl'></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoyalityProgram