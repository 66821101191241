import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Navbar from '../landing/header/Navbar'
import "./corporateorders.scss"
const CorporateOrders = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        // </Navbar>  
        <>
            <section className="bread-crumb">
                <div className="bread">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <Link to="/">
                                <li class="breadcrumb-item">
                                    <a href="#">Home /</a>
                                </li>
                            </Link>
                            <li class="breadcrumb-item">
                                <a href="#">Corporate Orders</a>
                            </li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-11 m-auto'>
                            {/* <div className='backbtn'>
                                <a>Back</a>
                            </div> */}
                            <div className='corporateorders-up'>
                                <h2>Quick Corporate Orders</h2>
                                <p className='upper-text'>In need of a swift and effortless solution for business grocery orders?<br /> 
Your search ends with Quick Grocery Shopping! </p>
                            </div>
                            <div className='row corporateorders-center'>
                                <div className='col-xl-4 col-lg-4 col-sm-12 '>
                                    <div className='left'>
                                        <img src='assets\btn-icons\corporate logo.svg'></img>
                                        <h4>Variety of Products</h4>
                                        <p>You can choose from a wide variety of products for your business needs.</p>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-sm-12 '>
                                    <div className='left'>
                                        <img src='assets\btn-icons\corporate logo2.svg'></img>
                                        <h4>Easy Delivery Option </h4>
                                        <p>Order your office groceries and we will deliver them to your doorstep.</p>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-sm-12 '>
                                    <div className='left'>
                                        <img src='assets\btn-icons\corporate logo3.svg'></img>
                                        <h4>Corporate Discounts</h4>
                                        <p>Offer exclusive & additional discounts to our special corporate customers.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className='main-term'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-12 m-auto'>
                            <div className='corporateorders-down'>
                                <div className='row'>
                                    <div className='col-xl-6 left'>
                                        <img className='img-fluid' src='Assests\Shipping Insurance.png' ></img>
                                    </div>
                                    <div className='col-xl-6 right'>
                                        <h3>Order Seamlessly!</h3>
                                        <p className='upper-textt'>Want a solution for your corporate grocery needs?</p>
                                        <p>Stay hassle-free with the Quick Corporate Grocery experience. So why wait? Sign up for Quick Grocery Shopping's corporate grocery ordering service today and experience the ultimate convenience, quality, and value!</p>
                                        <p>Reach us now at <span> Info@quick.shop</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-xl-11 col-12 m-auto p-0">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-12">
                                    <div className="main-heading-orders">
                                        <h2>Contact Information</h2>
                                    </div>
                                    <div className="location-orders">
                                        <h3>Location</h3>
                                        <h4>Store</h4>
                                        <p>Plot 31, Faqir Aipee Road,Sector I-11/3 Islamabad</p>
                                        <div className='d-flex'>
                                            <img className='mt-3' src='assets\btn-icons\icons8-whatsapp (1).svg' ></img>
                                            <a href="wa.me/923005668000" target="_blank">
                                            <p className='mt-3 pt-1 pl-3'>+92 300 566 8000</p></a>
                                        </div>
                                    </div>
                                    <div className="social-icon">
                                        <div className="social-head-orders">
                                            <h3>Socials</h3>
                                        </div>
                                        <div className="img-icons-orders">
                                            <a href="https://www.facebook.com/quickdotshop" target="_blank">
                                                <img
                                                    src="\assets\social-icons\facebook1.svg"
                                                    className="img-fluid"
                                                /></a>
                                         
                                            <a href="https://www.instagram.com/quickdotshop" target="_blank">
                                                <img
                                                    src="\assets\social-icons\instagram1.svg"
                                                    className="img-fluid"
                                                /></a>
                                            <a href="https://www.linkedin.com/company/quickdotshop" target="_blank">

                                                <img
                                                    src="\assets\social-icons\linkedin1.svg"
                                                    className="img-fluid"
                                                /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-12">
                                    <div className="drop-head-orders">
                                        
                                        <p className="drop-sub-1">Thank you for choosing Quick Grocery Shopping!</p>
                                        <p className="drop-sub">For queries & concerns, fill out the form below and we will get back to you as soon as possible.</p>
                                    </div>
                                    <div className="main-field-orders">
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            disabled

                                        />
                                        <div className="secondfeild">
                                            <input
                                                type="text"
                                                placeholder="Your Name"
                                                className="firstfeild"
                                                disabled
                                            />
                                            <input
                                                type="text"
                                                placeholder="Company Name"
                                                className="firstfeild"
                                                disabled
                                            />
                                            <input
                                                type="text"
                                                placeholder="Email address"
                                                disabled
                                            />
                                        </div>
                                        <div className="messagebtn">
                                            <button>Send message</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

        </>
    )
}

export default CorporateOrders