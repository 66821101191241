import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'


const Faqs2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        {/* <Navbar /> */}
        <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
            <Link to="/">
                <li class="breadcrumb-item">
                  <a href="#">Home /</a>
                </li>
              </Link>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
            <section className='main-faqs faqs3'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xl-12 m-auto p-0'>
                            <div className='row'>
                                <div className='col-xl-11 m-auto p-0'>
                                    <div className='faqshead'>
                                        <h2>FAQs</h2>
                                    </div>
                                    <div className='main-parent'>
                                       
                                            <div className='info'>
                                            <Link to="/faqs"><h3>ORDER INFORMATION</h3></Link>
                                              <Link to="/faqs1">  <h5 style={{marginBottom: "30px"}}>Shipping</h5></Link>
                                               <Link to="/faqs2"> <h3 className='color-spec marg-set'>Returns & Cancellation</h3></Link>
                                                <p>Need more help?</p>
                                                <Link to="/contactus"> <button>Contact Us</button></Link>
                                            </div>
                                       
                                        
                                            <div className='head'>
                                                <h2>How will the charge show up on my credit card?</h2>
                                                <p>The charge will appear on your credit card as:<br></br> "DRI*Western Digital Online Store"..</p>
                                                <div className='brdr'></div>
                                                <h2>Why are you not accepting my credit card?
                                                </h2>
                                                <p>We will not bill you until your product(s), including <br></br>backordered or preordered items, are actually shipped.</p>
                                            </div>
                                       
                                      
                                            <div className='head'>
                                            <div className="brdr-mbl"></div>
                                                <h2>How do I enter a shipping address?</h2>
                                                <p>A shipping information page will be presented where you can enter a separate shipping address and the shipping method can be chosen. </p>
                    
                                            </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Faqs2