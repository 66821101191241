import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Navbar from '../landing/header/Navbar'
import "./aboutus.scss"
const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        // </Navbar>  
        <>
            <section className="bread-crumb">
                <div className="bread">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                        <Link to="/">
                            <li class="breadcrumb-item">
                                <a href="#">Home /</a>
                            </li>
                            </Link>
                            <li class="breadcrumb-item">
                                <a href="#">About us</a>
                            </li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section className='main-term'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-11 m-auto'>
                            <div className='aboutus-upper'>
                                <div className='row'>
                                    <div className='col-xl-6 left'>
                                        <img className='img-fluid' src='Assests\Grocery Basket.png' ></img>
                                    </div>
                                    <div className='col-xl-6 right'>
                                        <h3>About Us</h3>
                                        <p>Introducing Quick, the ultimate ease store with a mission to offer grocery essentials at the most affordable prices in town. We don't just sell products; we provide unmatched value to our customers. <br/> We are located in the heart of twin cities, Islamabad and Rawalpindi, Quick brings the convenience of doorstep delivery for all your grocery needs. With us, you can enjoy the ease of shopping without leaving your home. Don't miss out on this incredible opportunity! Place your order now with Quick and experience the convenience, value, and excellent service that we bring right to your doorstep. Shop with us and witness the difference.</p>
                                        {/* <p className='right-lower-text'>We are the experts because we are Quickers! </p> */}
                                    </div>
                                </div>
                            </div>
                            <div className='row aboutus-middle'>
                                <div className='col-xl-4 col-lg-4'>
                                    <div className='aboutus-middle-left'>
                                    <img src='assets\btn-icons\mission.svg'></img>
                                    <h4>Mission</h4>
                                    <p>“Serving people with valuable grocery essentials, to help them shop with ease”.</p>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4'>
                                    <div className='aboutus-middle-center'>
                                    <img src='assets\btn-icons\vision.svg'></img>
                                    <h4>Vision </h4>
                                    <p>“Enhancing everyday living through effortless convenience."</p>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 '>
                                    <div className='aboutus-middle-left'>
                                    <img src='assets\btn-icons\usp.svg'></img>
                                    <h4>Values</h4>
                                    <p>Loyalty, Commitment, Respect, and Cooperation. </p>
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className='main-term'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-12 p-0 m-auto'>
                            <div className='aboutus-end'>
                                <div className='row'>
                                    <div className='col-xl-6 aboutus-end-left m-auto'>
                                        <h3>Convenient Shopping Experience</h3>
                                        <p>Come for Groceries, Stay for Experience!</p>
                                        <div className='last-text'>
                                            <div className='d-flex'>
                                                <img className='bullet-1' src='assets\btn-icons\bullet1.svg'></img>
                                                <p className='bullet-text'>Get your essentials delivered hassle-free!</p>
                                            </div>
                                            <div className='d-flex'>
                                                <img className='bullet-1' src='assets\btn-icons\bullet2.svg'></img>
                                                <p className='bullet-text'>Discover a vast selection to meet all your needs!</p>
                                            </div>
                                            <div className='d-flex'>
                                                <img className='bullet-1' src='assets\btn-icons\bullet3.svg'></img>
                                                <p className='bullet-text'>Experience seamless convenience!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-6 p-0 aboutus-end-right m-auto cdvyvycdvyvcyvd'>
                                        <img className='img-fluid' src='Assests\groceryy.png'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs