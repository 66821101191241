import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import "./homeshop.scss";
// import * as React from 'react';
// import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Checkbox from "@mui/material/Checkbox";
import Cart from "./Cart";
import axios from "axios";
import Environment from "../utils/environment";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "react-toastify/dist/ReactToastify.css";

const Homeshop = () => {
  const api_url = Environment.base_url2;
  const api_url2 = Environment.base_url;

  const history = useHistory();
  const userId = localStorage.getItem("userId");
  const [loader, setLoader] = useState(false);
  const [select, setSelect] = useState(false);

  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems"))
  );
  const [isAddressEdit, setIsAddressEdit] = useState(false);
  const [addresserrors, setAddressErrors] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [checkoutAddressId, setCheckoutAddressId] = useState("");
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [addressTypeId, setAddressTypeId] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("Islamabad");
  const [state, setState] = useState("");
  const [nearBy, setNearBy] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [name, setName] = useState("");
  const [checkactive, setCheckActive] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [activeState, setActiveState] = useState("");

  const [showlocation, setShowlocation] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");

  const [couponCode, setCouponCode] = useState("");
  const [shippingFee, setShippingFee] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cityerrorss, setCityErrorss] = useState("");
  const [nameerrorss, setNameErrorss] = useState("");
  const [mobileerrorss, setMobileErrorss] = useState("");
  const [val, setVal] = useState(null);

  const handleShowlocation = () => {
    setShowlocation(true);
  };
  const [location, setLocation] = useState({
    center: {
      lat: 33.6844,
      lng: 73.0479,
    },
    zoom: 13,
  });

  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setAddress(searchValue);
  
    if (!searchValue) {
      setSuggestions([]);
      return;
    }
  
    if (window.google && window.google.maps) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: searchValue,
          types: ["address"],
          componentRestrictions: {
            country: "pk",
          },
        },
        (predictions, status) => {
          console.log("preds: ", predictions, status);
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // Filter suggestions for Islamabad and Rawalpindi
            const filteredSuggestions = predictions.filter(suggestion =>
              suggestion.description.includes("Islamabad") || suggestion.description.includes("Rawalpindi")
            );
            setSuggestions(filteredSuggestions);
          } else {
            setSuggestions([]);
          }
        }
      );
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion) {
      const selectedPlaceId = suggestion?.place_id;
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      placesService.getDetails(
        { placeId: selectedPlaceId },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            console.log("Place details:", place);
            const location = place.geometry.location;
            console.log("Location:", location.lat(), location.lng());
            setLocation({
              center: {
                lat: location.lat(),
                lng: location.lng(),
              },
              zoom: 15,
            });
          } else {
            console.error("Error fetching place details:", status);
          }
        }
      );
    }
    setAddress(suggestion.description);
    setSuggestions([]);
  };

  const [clickedLocation, setClickedLocation] = useState(null);
  const handleCloselocation = () => {
    setShowlocation(false);
    setAddressId("");
    setIsAddressEdit(false);
    setAddressErrors("");
    setAddress("");
    setCity("Islamabad");
    setName("");
    setMobileNumber("");
    setNearBy("");
    setPostalCode("");
    setState("");
    setActiveState("");
    setCityErrorss("");
    setNameErrorss("");
    setMobileErrorss("");
  };

  const Marker = () => <div className="marker">📍</div>;

  const Validateaddress = () => {
    if (address.length === 0) {
      setAddressErrors("Address is Required");
    }
    if (city.length === 0) {
      setCityErrorss("City is Required");
    }
    if (name.length === 0) {
      setNameErrorss("Name is Required");
    }
    if (mobileNumber.length === 0) {
      setMobileErrorss("Mobile number is Required");
    } else if (mobileNumber.length != 11) {
      setMobileErrorss("Mobile number is invalid");
    }
  };

  const addNewAddress = async () => {
    Validateaddress();
    if (mobileNumber?.length === 11 && select === true) {
      handleCloselocation();
      setLoader(true);
      const config = {
        method: "post",
        url: api_url + "/address",
        data: {
          address: address,
          city: city,
          ...(state && { state: state }),
          ...(nearBy && { nearByPlace: nearBy }),
          ...(postalCode && { postalCode: postalCode }),
          name: name,
          contactNo: mobileNumber,
          latitude: location?.center?.lat,
          longitude: location?.center.lng,
          addressTypeId: addressTypeId,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          getAddressList();
          handleCloselocation();
          toast.success(res?.data?.message);
          setLoader(false);
          setAddress("");
          setCity("Islamabad");
          setName("");
          setMobileNumber("");
          setNearBy("");
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            // toast.error(err?.response?.data?.message, {
            //   position: "bottom-left",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
        });
    }
    else{
      setAddressErrors("Select Address from suggestion");
    }
  };

  const editAddress = async () => {
    Validateaddress();
    if (mobileNumber?.length === 11) {
      handleCloselocation();
      setLoader(true);
      window.scrollTo(0, 0);
      const config = {
        method: "patch",
        url: api_url + "/address/" + addressId,
        data: {
          address: address,
          city: city,
          ...(state && { state: state }),
          ...(nearBy && { nearByPlace: nearBy }),
          ...(postalCode && { postalCode: postalCode }),
          name: name,
          contactNo: mobileNumber,
          latitude: location?.center?.lat,
          longitude: location?.center.lng,
          addressTypeId: addressTypeId,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          handleCloselocation();
          toast.success(res?.data?.message);
          getAddressList();
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            // toast.error(err?.response?.data?.message, {
            //   position: "bottom-left",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
        });
    }
  };

  const handleMapClick = (event) => {
    setAddressErrors("");
    setSelect(true);
    setLocation({
      center: {
        lat: event.lat,
        lng: event.lng,
      },
      zoom: 15,
    });
    const clickedLat = event.lat;
    const clickedLng = event.lng;
    setClickedLocation({ lat: clickedLat, lng: clickedLng });
    Geocode.setApiKey("AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0");

    Geocode.fromLatLng(event.lat, event.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        setAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );

    // Do something with the location data, e.g. update state
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(false);
    cartPrice(cartItems);
  }, []);
  const [value, setValue] = useState(dayjs());
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [deliveryType, setDeliveryType] = useState("standard");
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (val !== "null" || val !== "undefined") {
      getAddressList();
    }
  }, [val]);

  const [defaults, setDefault] = useState("");
  const getAddressList = async () => {
    setLoader(true);
    await axios
      .get(`${api_url}/address`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setAddressList(res?.data?.data);
        calculateDeliveryFee(res?.data?.data[0]);
        // setCheckoutAddressId(res?.data?.data[0]?.id);

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getAddressTypeList = async () => {
    // Validateaddress();
    setLoader(true);
    const config = {
      method: "get",
      url: `${api_url}/address/types`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setAddressTypeList(resData);
        setAddressTypeId(resData[0]?.id);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const getAddressDetails = async (id) => {
    setLoader(true);
    if (id) {
      const config = {
        method: "get",
        url: `${api_url}/address/${id}`,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;

          setAddress(resData?.addressDetails?.address);
          setCity(resData?.addressDetails?.city);
          setNearBy(resData?.addressDetails?.nearByPlace);
          setPostalCode(resData?.addressDetails?.postalCode);
          setMobileNumber(resData?.addressDetails?.contactNo);
          setName(resData?.addressDetails?.name);
          setState(resData?.addressDetails?.state);
          for (let i = 0; i < resData?.addressTypes.length; i++) {
            if (resData?.addressTypes[i]?.userAddress != null) {
              setActiveState(resData?.addressTypes[i]?.addressTypeId);
            }
          }
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else {
            toast.error(err?.response?.data?.message, {
              position: "top-right",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    }
  };

  const calculateDeliveryFee = async (item) => {
    if (item) {
      const config = {
        method: "post",
        url: `${api_url2}/orders/delivery-charges`,
        data: {
          latitude: item?.latitude,
          longitude: item?.longitude,
          total: totalPrice,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          setCheckoutAddressId(item?.id);
          setSelectedAddress(item);
          setShippingFee(resData?.deliveryCharges);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else {
            // toast.error(err?.response?.data?.message, {
            //   position: "top-right",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
        });
    }
  };

  const [eligible, setEligible] = useState("");

  const [discount, setDiscount] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [errorcoupon, setErrorCoupon] = useState("");
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  const verifyCoupon = async () => {
    if (couponCode.length != 0) {
      const config = {
        method: "post",
        url: `${api_url2}/discounts/verify-coupon`,
        data: {
          code: couponCode,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          setLoader(false);
          if (resData.criteria > totalPrice) {
            // toast.error(`Please shope atleast this amount ${resData.criteria} `, {
            //   position: "top-right",
            //   autoClose: 2000,
            // });
            toast.warning(`You are not eligible for the discount`, {
              position: "top-right",
              autoClose: 2000,
            });
            setCouponCode("");
            setIsValidCoupon(false);
          } else {
            setEligible("Coupon Discount has been applied!.");
            // let a = totalPrice - resData?.discountAmount
            // setTotalPrice(a)
            setDiscount(resData?.discountAmount);
            localStorage.setItem("couponCode", couponCode);
            localStorage.setItem("discountAmount", resData?.discountAmount);
            setIsValidCoupon(true);
            setDisableInput(true);
            setDisableApplyButton(true);
            window.location.reload();
            // setDissable(true)
            // toast.success("Code is verified and total price is discounted", resData.criteria, {
            //   position: "top-right",
            //   autoClose: 2000,
            // });
          }
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else {
            if (err?.response?.data?.message?.includes("limit reached")) {
              setErrorCoupon("User limit has been reached");
            } else {
              setErrorCoupon("Invalid Promo Code");
            }
            // toast.error(err?.response?.data?.message, {
            //   position: "top-right",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
          setIsValidCoupon(false);
          setCouponCode("");
        });
    } else {
      setErrorCoupon("Please enter your promo code");
    }
  };

  const handleRemoveCoupon = () => {
    localStorage.removeItem("couponCode");
    localStorage.removeItem("discountAmount");
    setDiscountAmount(0);
    setIsValidCoupon(false);
    setDisableInput(false);
    setDisableApplyButton(false);
    setCouponCode("");
    window.location.reload();
  };

  const handleDecrease = (index) => {
    let tempCart = [...cartItems];
    let tempCartAmount = tempCart[index]?.cartAmount - 1;
    tempCart[index] = { ...tempCart[index], cartAmount: tempCartAmount };
    setCartItems(tempCart);
    cartPrice(tempCart);
    localStorage.setItem("cartItems", JSON.stringify(tempCart));
  };

  const handleIncrease = (index) => {
    let tempCart = [...cartItems];
    let tempCartAmount = tempCart[index]?.cartAmount + 1;
    tempCart[index] = { ...tempCart[index], cartAmount: tempCartAmount };
    setCartItems(tempCart);
    cartPrice(tempCart);
    localStorage.setItem("cartItems", JSON.stringify(tempCart));
  };

  const handleCartItemDelete = (val) => {
    let cartCopy = [...cartItems];
    setCartCount(cartCopy?.length - 1);
    cartCopy = cartCopy.filter((item) => item?.id != val?.id);
    setCartItems(cartCopy);
    cartPrice(cartCopy);
    localStorage.setItem("cartItems", JSON.stringify(cartCopy));
    window.location.reload();
  };
  const cartPrice = (cart) => {
    let price = 0;
    for (let i = 0; i < cart?.length; i++) {
      if (cart[i].discount) {
        price =
          Math.trunc(
            cart[i]?.product?.price -
              cart[i]?.product?.price * (cart[i]?.discount / 100)
          ) *
            cart[i]?.cartAmount +
          price;
      } else {
        price = cart[i]?.price * cart[i]?.cartAmount + price;
      }
    }
    setTotalPrice(price);
  };

  //   const savedLocationString = localStorage.getItem('location');
  //   const savedLocation = JSON.parse(savedLocationString);
  //  console.log(savedLocation);
  useEffect(() => {
    // const savedLocationString = localStorage.getItem("location");
    // const savedLocation = JSON.parse(savedLocationString);
    // if (savedLocation) {
    //   setSelectedAddress(savedLocation);
    // } else if (addressList.length > 0) {
    //   setSelectedAddress(addressList[0].address);
    // } else {
    //   setSelectedAddress("Please select an address");
    // }
  }, []);

  let a = localStorage.getItem("couponCode");

  useEffect(() => {
    const res = localStorage.getItem("accessToken");

    if (res) {
      setUserIsLoggedIn(true);
      setVal(res);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


 

  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <Link to="/">
                <li class="breadcrumb-item">
                  <a href="#">Home /</a>
                </li>
              </Link>
              <Link to="/faqs">
                <li class="breadcrumb-item">
                  <a href="#">FAQS</a>
                </li>
              </Link>
            </ol>
          </nav>
        </div>
      </section>
      <section className="homeshop">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="row">
              <div className="col-xl-11 m-auto">
                <div className="shophead">
                  <h2>Cart</h2>
                </div>
                {isLoading ? (
                  <div className="loader-image-myaccounts">
                    <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
                  </div>
                ) : (
                  <div className="main-tables">
                    <table>
                      <tr>
                        <th>Products</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th>Remove</th>
                      </tr>
                      {cartItems?.length > 0 ? (
                        cartItems?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  <div className="parent">
                                    <div className="main-img">
                                      <img
                                        src={
                                          item?.product?.imageUrl ||
                                          item?.imageUrl
                                        }
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="texttable">
                                      <h5>
                                        {item?.product?.name || item?.name}
                                      </h5>
                                      {/* <p>{item?.product?.description || item?.description}</p> */}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="pricetable">
                                    <h4>
                                      Rs.
                                      {Math.trunc(
                                        item?.product?.price -
                                          item?.product?.price *
                                            (item?.discount / 100)
                                      ) || item?.price}
                                    </h4>
                                  </div>
                                </td>
                                <td>
                                  <div className="counter">
                                    <div className="btn__container">
                                      <button
                                        className="control__btn"
                                        onClick={() => handleDecrease(index)}
                                        disabled={item?.cartAmount <= 1}
                                      >
                                        -
                                      </button>
                                      <button className="reset">
                                        {item?.cartAmount}
                                      </button>
                                      <button
                                        className="control__btn plus"
                                        onClick={() => handleIncrease(index)}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="pricetable">
                                    <h4>
                                      Rs.{" "}
                                      {Math.trunc(
                                        item?.product?.price -
                                          item?.product?.price *
                                            (item?.discount / 100)
                                      ) * item?.cartAmount ||
                                        item?.price * item?.cartAmount}
                                    </h4>
                                  </div>
                                </td>
                                <td>
                                  <div className="imgtable">
                                    <img
                                      src="\assets\social-icons\trash.png"
                                      className="img-fluid"
                                      onClick={() => handleCartItemDelete(item)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <Redirect to="/cart" />
                      )}
                    </table>
                  </div>
                )}

                {/* mobile design */}
                {cartItems?.length > 0 ? (
                  cartItems?.map((item, index) => {
                    return (
                      <>
                        <div className="phone-design">
                          <div className="pro-first">
                            <div className="pro-pic">
                              <img
                                className="pic-product"
                                src={item?.product?.imageUrl || item?.imageUrl}
                              ></img>
                              <div className="pro-name">
                                <p> {item?.product?.name || item?.name}</p>
                                <h4>
                                  {" "}
                                  Rs.
                                  {Math.trunc(
                                    item?.product?.price -
                                      item?.product?.price *
                                        (item?.discount / 100)
                                  ) || item?.price}
                                </h4>
                              </div>
                            </div>
                            <img
                              className="delet"
                              onClick={() => handleCartItemDelete(item)}
                              src="\assets\social-icons\trash.png"
                            ></img>
                          </div>
                          <div className="pro-counter">
                            <div className="counter">
                              <div className="btn__container">
                                <button
                                  className="control__btn"
                                  onClick={() => handleDecrease(index)}
                                  disabled={item?.cartAmount <= 1}
                                >
                                  -
                                </button>
                                <button className="reset">
                                  {item?.cartAmount}
                                </button>
                                <button
                                  className="control__btn plus"
                                  onClick={() => handleIncrease(index)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="pro-total">
                            <p>
                              {" "}
                              Rs.{" "}
                              {Math.trunc(
                                item?.product?.price -
                                  item?.product?.price * (item?.discount / 100)
                              ) * item?.cartAmount ||
                                item?.price * item?.cartAmount}
                            </p>
                          </div>
                          <hr />
                        </div>
                      </>
                    );
                  })
                ) : (
                  <Redirect to="/cart" />
                )}

                {/* mobile design */}

                <div className="mainbutton">
                  <div className="leftbtn">
                    <Link to="/all-categories">
                      <button>
                        {" "}
                        <img
                          src="\assets\btn-icons\arrow-left.png"
                          className="img-fluid"
                        />
                        Continue Shopping
                      </button>
                    </Link>
                    <Link to="/">
                      <button>
                        {" "}
                        <img
                          src="\assets\btn-icons\home-2.png"
                          className="img-fluid"
                        />
                        Back to home
                      </button>
                    </Link>
                  </div>
                  <div className="rightbtn">
                    {/* <button
                      onClick={() =>
                        setCartItems(
                          JSON.parse(localStorage.getItem("cartItems"))
                        )
                      }
                    >
                      <img
                        src="\assets\btn-icons\Vector.png"
                        className="img-fluid"
                      />
                      Update cart
                    </button> */}
                  </div>
                </div>
                <div className="brdr"></div>
                <div className="row">
                  <div className="col-xl-3 col-md-6 col-12">
                    <div className="codehead">
                      <h2>Shipping Address</h2>
                    </div>
                    {val != null && val != undefined ? (
                      <div className="shippingaddresses">
                        {addressList?.length > 0 ? (
                          addressList?.map((item) => {
                            return (
                              <>
                                <div
                                  className={checkactive === item ? "mainaddress-active" :"mainaddress"}
                                  tabindex="0"
                                  onClick={() => {
                                    setCheckActive(item);
                                    calculateDeliveryFee(item);
                                  }}
                                >
                                  <div className="addresshead">
                                    <h6 className="heading">{item?.name}</h6>
                                    <img
                                      onClick={() => {
                                        setIsAddressEdit(true);
                                        setAddressId(item?.id);
                                        handleShowlocation();
                                        getAddressTypeList();
                                        getAddressDetails(item?.id);
                                      }}
                                      src="\Assests\edit-2.svg"
                                      alt="edit"
                                      className="edit"
                                    />
                                  </div>
                                  <div className="inneraddress">
                                    <span className="imglocate">
                                      <img
                                        src="\Assests\home-2.svg"
                                        alt="img"
                                        className="home"
                                      />
                                      <p className="locatetext">
                                        {item?.addressType?.name}
                                      </p>
                                    </span>
                                    <p className="uppertext">{item?.address}</p>
                                    <p className="number">{item?.contactNo}</p>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        ) : loader ? (
                          <>
                            <div className="text-center py-4">
                              {<Spinner animation="border" variant="info" />}
                            </div>
                          </>
                        ) : (
                          <h5>No Addresses</h5>
                        )}
                        <button
                          className="addnewbtn"
                          onClick={() => {
                            handleShowlocation();
                            getAddressTypeList();
                          }}
                        >
                          Add New Address
                        </button>
                      </div>
                    ) : (
                      <Link to="/login">
                        <p className="phone-login">Please Login To Continue</p>
                      </Link>
                    )}
                  </div>
                  <div className="col-xl-4 col-md-6 col-12">
                    <div className="centerheading">
                      <h2>Delivery Type</h2>
                    </div>
                    <div className="checkedmain">
                      <Checkbox
                        {...label}
                        checked={deliveryType == "standard"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDeliveryType("standard");
                          } else {
                            setDeliveryType("scheduled");
                          }
                        }}
                      />
                      <p className="checkedpara">Standard Delivery</p>
                    </div>
                    <div className="checkedmain">
                      <Checkbox
                        {...label}
                        checked={deliveryType == "scheduled"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDeliveryType("scheduled");
                          } else {
                            setDeliveryType("standard");
                          }
                        }}
                      />
                      <p className="checkedpara">Select Delivery Date</p>
                    </div>
                    {deliveryType == "scheduled" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="Delivery Time"
                          value={value}
                          onChange={(newValue) => {
                            let time = newValue.toISOString();
                            setValue(time);
                          }}
                        />
                      </LocalizationProvider>
                    ) : null}
                  </div>
                  <div className="col-xl-5 col-md-12 col-12 padd-sm">
                    <div className="promoheading">
                      <h6 className="promohead">Using A Promo Code?</h6>
                    </div>
                    <div className="felidinput">
                      <div class="input-group">
                        <input
                          type="text"
                          className={`form-control ${
                            errorcoupon ? "error-input-homeshop" : ""
                          }`}
                          placeholder="Coupon code"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          value={couponCode}
                          onChange={(e) => {
                            setCouponCode(e.target.value);
                            setErrorCoupon();
                          }}
                          disabled={disableInput}
                        />
                        <button
                          class="btn  apply"
                          type="button"
                          id="button-addon2"
                          onClick={verifyCoupon}
                          disabled={isValidCoupon || a}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    {errorcoupon && (
                      <p className="homeshop-validations">{errorcoupon}</p>
                    )}
                    {/* {errorinvaliddiscount ? (
                      <p className="homeshop-validations">{errorinvaliddiscount}</p>
                    ) : null} */}

                    <div>
                      {isValidCoupon ||
                        (a && (
                          <div>
                            <div className="coupen-remove-main">
                              <div className="d-flex">
                                <img
                                  src="assets\btn-icons\coupen img.svg"
                                  alt="coupon image"
                                />
                                <p className="coupen-name">{a}</p>
                              </div>
                              <div className="coupen-discount">
                                <img
                                  src="\Assests\delete-btn.svg"
                                  onClick={handleRemoveCoupon}
                                ></img>
                              </div>
                            </div>
                            <div className="aboutdiscount">
                              <h5>Coupon Discount has been applied!</h5>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="subtotal">
                      <div className="parenttotal">
                        <h3>Subtotal</h3>
                        <p>Rs. {totalPrice}</p>
                      </div>
                      <div className="parenttotal1">
                        {localStorage.getItem("discountAmount") && (
                          <>
                            <h3>Discount</h3>
                            <p>
                              Rs.
                              <span className="pdis">
                                {" "}
                                {localStorage.getItem("discountAmount")}
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                      {/* <div className="parenttotal1">
                        <h3>Shipping</h3>
                        <del style={{ color: 'red' }}>
                          <p>Rs. {totalPrice}</p>
                        </del>
                      </div>
                      <div className="warning">
                        <h5>Spend Rs 200 more to get free shipping </h5>
                      </div> */}
                      <div className="brdr2"></div>
                      <div className="totalhead">
                        <h4>Shipping</h4>
                      </div>
                      <p>
                        {selectedAddress ? (
                          <>
                            {selectedAddress?.address}
                          </>
                        ) : (
                          <></>
                        )}
                      </p>
                      <div className="brdr2"></div>
                      <div className="totalsub">
                        <h3>Total</h3>
                        <p>
                          Rs.{" "}
                          {localStorage.getItem("discountAmount")
                            ? totalPrice -
                              parseInt(localStorage.getItem("discountAmount"))
                            : totalPrice}
                        </p>
                      </div>
                      <div className="btntotal">
                        {selectedAddress && val ? (
                          <Link
                            to={{
                              pathname: "/cart-checkout",
                              state: {
                                deliveryTime:value,
                                cartItems: cartItems,
                                deliveryType: deliveryType,
                                addressId: checkoutAddressId,
                                shippingFee: shippingFee,
                                latitude: selectedAddress?.latitude,
                                longitude: selectedAddress?.longitude,
                                discountCoupon: localStorage.getItem(
                                  "discountAmount"
                                )
                                  ? localStorage.getItem("couponCode")
                                  : "",
                                totalPrice: totalPrice,
                                discount:
                                  localStorage.getItem("discountAmount"),
                              },
                            }}
                          >
                            <button>Proceed to checkout</button>
                          </Link>
                        ) : (
                          <div>
                            <p className="login-check-homeshop text-danger mb-2">
                              {userIsLoggedIn ? (
                                <div>
                                  <p className="add-adress">
                                    Please Add Address{" "}
                                    <span
                                      onClick={() => {
                                        handleShowlocation();
                                        getAddressTypeList();
                                      }}
                                      className="here"
                                    >
                                      Here!
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                <Link to="/login">
                                  <p className="login-user">
                                    {/* Please Login To Continue */}
                                  </p>
                                </Link>
                              )}
                            </p>
                            <Link to="/login">
                            <button className="secondary" >
                               Login To Proceed 
                            </button>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="locationmodal"
        show={showlocation}
        onHide={handleCloselocation}
        centered
      >
        <Modal.Header closeButton>
          {isAddressEdit ? (
            <Modal.Title>Edit Address</Modal.Title>
          ) : (
            <Modal.Title>Add New Address</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="locationss">
            <div className="map-div">
              <GoogleMapReact
                onClick={handleMapClick}
                bootstrapURLKeys={{
                  key: "AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0",
                }}
                defaultCenter={location.center}
                defaultZoom={location.zoom}
                yesIWantToUseGoogleMapApiInternals
              >
                {clickedLocation && (
                  <Marker lat={clickedLocation.lat} lng={clickedLocation.lng} />
                )}
              </GoogleMapReact>
            </div>
            {/* <img src="\Assests\modalmap.png" alt="map" className="map" /> */}
            <div className="formlocation">
              {/* <h6 className="headmodal">
                {address + " " + city + " " + state}
              </h6> */}
              <p className="mt-3">Full Address</p>
              <input
                type="text"
                placeholder="Enter or Pin your full address~!"
                className={`maininput ${
                  addresserrors ? "error-input-account" : ""
                }`}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setAddressErrors("");
                  handleSearch(e);
                }}
              />

              {suggestions.length > 0 && (
                <ul className="suggestions">
                  {suggestions.map((suggestion, index) => (
                    <a>
                      {" "}
                      <li
                        key={index}
                        onClick={() => {handleSuggestionClick(suggestion);setSelect(true)}}
                      >
                        {suggestion.description}
                      </li>
                    </a>
                  ))}
                </ul>
              )}
              {addresserrors ? (
                <p className="address-error-text">{addresserrors}</p>
              ) : null}
              <div className="inputss">
                <div className="innerinputs">
                  <p>City</p>
                  <select
                    className="maininput"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      // setCityErrorss("");
                    }}
                  >
                    <option value="Islamabad">Islamabad</option>
                    <option value="Rawalpindi">Rawalpindi</option>
                  </select>
                  {/* {cityerrorss ? (
                    <p className="homeshopp-validations">{cityerrorss}</p>
                  ) : null} */}
                </div>
                <div className="innerinputs">
                  <p>Nearby Place (Optional)</p>
                  <input
                    type="text"
                    placeholder="Nearby Place"
                    className="maininput"
                    value={nearBy}
                    onChange={(e) => setNearBy(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="inputss">
                <div className="innerinputs">
                  <p>Postal Code</p>
                  <input
                    type="text"
                    placeholder="Postal Code"
                    className="maininput"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div>
                <div className="innerinputs">
                  <p>Nearby Place (Optional)</p>
                  <input
                    type="text"
                    placeholder="Nearby Place "
                    className="maininput"
                    value={nearBy}
                    onChange={(e) => setNearBy(e.target.value)}
                  />
                </div>
              </div> */}
              <div className="inputss">
                <div className="innerinputs">
                  <p>Name</p>
                  <input
                    type="text"
                    placeholder="Name"
                    className={`maininput ${
                      nameerrorss ? "error-input-homeshop " : ""
                    }`}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameErrorss("");
                    }}
                  />
                  {nameerrorss ? (
                    <p className="homeshopp-validations">{nameerrorss}</p>
                  ) : null}
                </div>
                <div className="innerinputs">
                  <p>Mobile Number</p>
                  <input
                    type="number"
                    placeholder="Mobile Number"
                    className={`maininput ${
                      mobileerrorss ? "error-input-homeshop " : ""
                    }`}
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                      setMobileErrorss("");
                    }}
                  />
                  {mobileerrorss ? (
                    <p className="homeshopp-validations">{mobileerrorss}</p>
                  ) : null}
                </div>
              </div>
              <div className="spanbtns">
                {addressTypeList.length > 0
                  ? addressTypeList?.map((item) => {
                      return (
                        <>
                          <span
                            className={
                              activeState == item?.id ? "spanactive" : ""
                            }
                            onClick={() => {
                              setActiveState(item?.id);
                              setAddressTypeId(item?.id);
                            }}
                          >
                            <img
                              src="\Assests\home-2.svg"
                              alt="home"
                              className="spanicon"
                            />
                            {item?.name}
                          </span>
                        </>
                      );
                    })
                  : null}
              </div>
            </div>
            <button
              className="savebtn"
              disabled={loader}
              onClick={isAddressEdit ? editAddress : addNewAddress}
            >
              Save Address
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Homeshop;
