import React, { useState, useEffect } from "react";
import "./forgotpass.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import Environment from "../utils/environment";
import axios from "axios";
import { toast } from "react-toastify";

const Forgotpass = () => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url2;
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const ForgetPassword = async () => {
    setLoader(true);
    setError("");
    if (isValidEmail(email)) {
      const config = {
        method: "post",
        url: api_url + "/users-auth/forget-password",
        data: {
          email: email,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          history.push("/Checkemail");
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.data?.statusCode == 501) {
            history.push("/");
          } else if (err?.response?.data?.statusCode == 404) {
            setError("user doesn't exist!");
          } else {
            toast.error(err?.response?.data?.message, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    } else {
      setError("Invalid Email! Please enter a correct email address.");
    }

    // }
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
            <Link to="/">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              </Link>
              <Link to="/faqs">
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
              </Link>
            </ol>
          </nav>
        </div>
      </section>
      <section className="password">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="main">
                <img
                  src="\Assests\Mail-bro 1.png"
                  alt="img"
                  className="imgmain"
                />
                <h5>Forgot your Password?</h5>
                <p>
                  Enter your registered email to receive password reset
                  instructions.
                </p>
                <div className="inputmsg">
                  <img src="\Assests\sms.svg" alt="img" className="imgmsg" />
                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError("");
                    }}
                    type="email"
                    placeholder="Email Address"
                    className="emailinput"
                  />
                  <p className="text-danger">{error}</p>
                </div>
                {/* <Link to="/checkemail" className='w-100 '> */}
                <button onClick={ForgetPassword} className="snd-btn m-auto">
                  Send
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Forgotpass;
