// import React, { useState, useEffect } from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
// import "./featured.scss";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import axios from "axios";
// import Environment from "../../utils/environment";
// import { Spinner } from "react-bootstrap";

// const Featured = ({ brandData }) => {
//   const val = localStorage.getItem("accessToken");

//   const history = useHistory();
//   const api_url = Environment.base_url;
//   // const [brandData, setBrandData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const getFeaturedBrands = async () => {
//     setIsLoading(true);
//     await axios
//       .get(`${api_url}/brands/all-publish?offset=1&&limit=100`, {
//         headers: {
//           Authorization: "Bearer " + val,
//         },
//       })
//       .then((res) => {
//         setIsLoading(false);
//         // setBrandData(res?.data?.data?.brands);
//       })
//       .catch((err) => {});
//     setIsLoading(false);
//   };
//   useEffect(() => {
//     // getFeaturedBrands();
//   }, []);

//   const owl_option = {
//     nav: true,
//     dots: false,
//     dotsEach: false,
//     loop: false,
//     autoplay: false,
//     navText: [
//       "<i class='fas fa-caret-left'></i>",
//       "<i class='fas fa-caret-right'></i>",
//     ],
//     responsive: {
//       0: {
//         items: 1,
//         margin: 15,
//       },
//       361: {
//         items: 1.7,
//         loop: true,
//         margin: 10,
//       },
//       600: {
//         items: 1,
//         margin: 15,
//       },
//       700: {
//         items: 2.5,
//         margin: 20,
//         loop: true,
//       },
//       1000: {
//         items: 3.4,
//         margin: 20,
//         loop: true,
//       },
//       1200: {
//         items: 6,
//         margin: 20,
//       },
//       1600: {
//         items: 8,
//         margin: 20,
//       },
//     },
//   };
//   return (
//     <>
//       <section className="featured">
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-xl-11 col-12 m-auto p-0">
//               <div className="main-heading">
//                 <h4>
//                   Featured Brands{" "}
//                   <Link to="/all-categories">
//                     All Brands{" "}
//                     <img
//                       src="\assets\btn-icons\arrow-right.svg"
//                       alt="img"
//                       className="img-fluid ml-2"
//                     />
//                   </Link>
//                 </h4>
//               </div>
//               {isLoading ? (
//                 <div className="text-center py-4">
//                   <Spinner animation="border" variant="info" />
//                 </div>
//               ) : (
//                 // <div className="loader-image-landing">
//                 //   <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
//                 // </div>
//                 <div class="owl_option">
//                   {brandData?.length > 0 ? (
//                     <OwlCarousel className="owl-theme" {...owl_option}>
//                       {brandData?.map((item) => {
//                         return (
//                           <>
//                             <Link
//                               to={{
//                                 pathname: "/all-categories",
//                                 state: {
//                                   cat: item?.id,
//                                   type: "brand",
//                                 },
//                               }}
//                             >
//                               <div className="card-featured">
//                                 <div className="inner-img">
//                                   <img
//                                     src={item?.imageUrl}
//                                     alt="img"
//                                     className="img-fluid"
//                                   />
//                                 </div>
//                                 {/* <h6>{item?.name}</h6> */}
//                                 {/* <h5>Happy Tea 100% Organic</h5> */}
//                               </div>
//                             </Link>
//                           </>
//                         );
//                       })}
//                     </OwlCarousel>
//                   ) : null}
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Featured;


import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./featured.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Environment from "../../utils/environment";
import { Spinner } from "react-bootstrap";

const Featured = ({ brandData }) => {
  const val = localStorage.getItem("accessToken");

  const history = useHistory();
  const api_url = Environment.base_url;
  // const [brandData, setBrandData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFeaturedBrands = async () => {
    setIsLoading(true);
    await axios
      .get(`${api_url}/brands/all-publish?offset=1&&limit=100`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setIsLoading(false);
        // setBrandData(res?.data?.data?.brands);
      })
      .catch((err) => { });
    setIsLoading(false);
  };
  useEffect(() => {
    // getFeaturedBrands();
  }, []);

  const owl_option = {
    nav: true,
    dots: false,
    dotsEach: false,
    loop: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-caret-left'></i>",
      "<i class='fas fa-caret-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      361: {
        items: 1.7,
        loop: true,
        margin: 10,
      },
      600: {
        items: 1,
        margin: 15,
      },
      700: {
        items: 2.5,
        margin: 20,
        loop: true,
      },
      1000: {
        items: 3.4,
        margin: 20,
        loop: true,
      },
      1200: {
        items: 6,
        margin: 20,
      },
      1600: {
        items: 8,
        margin: 20,
      },
    },
  };
  return (
    <>
      <section className="featured">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="main-heading">
                <h4>
                  Featured Brands{" "}
                  <Link to="/Allfeaturedbrandsnew">
                    All Brands{" "}
                    <img
                      src="\assets\btn-icons\arrow-right.svg"
                      alt="img"
                      className="img-fluid ml-2"
                    />
                  </Link>
                </h4>
              </div>


              <div class="owl_option">
                <OwlCarousel className="owl-theme" {...owl_option}>
                {brandData?.map((item) => {
                       return (
                           <>
                             <Link
                               to={{
                                 pathname: "/all-categories",
                                 state: {
                                   cat: item?.id,
                                   type: "brand",
                                 },
                               }}
                             >
                  <div className="card-featured">
                    <div className="inner-img">
                      <img
                        src={item?.imageUrl}
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  </Link>
               </>
                       )
                })}
          
                </OwlCarousel>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Featured;
