import React from "react";
import "./collection.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Environment from "../../utils/environment";

const Collection = () => {
  const val = localStorage.getItem("accessToken");
  const [post, setPost] = useState([]);

  const getBanner = async () => {
    // setPost([]);
    await axios
      .get(
        `${Environment.base_url}/banners?offset=1&&limit=11`,

        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )

      .then((res) => {
        setPost(res?.data?.data?.banners);
      })
      .catch((err) => {});
  };

  const filteredData = post.filter(
    (data) => data?.type === "main banner 3" && data?.isPublish === true
  );

  // useEffect(() => {
  //   if (!val) {
  //   } else {
  //     getBanner();
  //   }
  // }, []);
  return (
    <>
      <section className="collection-items">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="row collection__items__row">
                {filteredData?.map((data, index) => {
                  return (
                    <div className="col-xl-4 col-lg-12 col-12 padd-sm mobilemb-2">
                      <div className="img-wrap">
                        <img
                          src={data?.imageUrl}
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Collection;
