import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./login.scss";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Navbar from "../landing/header/Navbar";
import Environment from "../utils/environment";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import Location from "../Location/Location";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const api_url = Environment.base_url2;
  const history = useHistory();
  const [id, setId] = useState("");

  const [passwordError, setPasswordError] = useState({});
  const [emailError, setEmailError] = useState("");
  const [emaillogin, setEmailLogin] = useState("");
  const [passwordlogin, setPasswordLogin] = useState("");
  const [emailerrorregister, setEmailErrorRegister] = useState("");
  const [passwordloginerror, setPasswordLoginError] = useState("");
  const [nameerror, setNameError] = useState("");
  const [errorpassword, setErrorPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(null);
  const [name, setName] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordreg, setShowPasswordReg] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    emaillogin: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);

  const { email, password } = inputs;
  const val = localStorage.getItem("accessToken");

  useEffect(() => {
    if (val && val !== undefined) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("token"));
    // window.scroll(0, 0);
    if (id) {
      localStorage.setItem("accessToken", id);
      const savedRoute = localStorage.getItem('currentRoute');
      history.push(savedRoute);
      localStorage.removeItem('currentRoute');
    }
  }, [id]);

  const handleChange = (e) => {
    // setEmailError("")
    // setPasswordLoginError("")
    setError("");
    // setNameError("")
    // setEmailErrorRegister("")
    // setErrorPassword("")
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const googleAuth = (e) => {
    // e.preventDefault();
    setLoader(true);
    setError(null);

    var config = {
      method: "get",
      url: `${api_url}/users-auth/google`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // localStorage.setItem(
        //   "accessToken",
        //   response?.data?.data?.accessToken
        // );
        // localStorage.setItem(
        //   "refreshToken",
        //   response?.data?.data?.refreshToken
        // );
        //  history.push("/admin/dashboard");
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // setError("Incorrect email or password");
      });
  };

  const userRegister = () => {
    let hasError = false;
  
    if (name.length === 0) {
      setNameError("Name is Required");
      hasError = true;
    }
  
    if (registerEmail.length === 0) {
      setEmailErrorRegister("Email is Required");
      hasError = true;
    } else if (!isValidEmail(registerEmail)) {
      setEmailErrorRegister("Email is invalid");
      hasError = true;
    }
  
    if (createPassword.length === 0) {
      setErrorPassword("Password is Required");
      hasError = true;
    }
  
    if (!hasError) {
      var data = JSON.stringify({
        name: name,
        email: registerEmail,
        password: createPassword,
      });
      var config = {
        method: "post",
        url: `${api_url}/users-auth/signup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          // history?.push("/account");
          // toast.success(response?.data?.message);
          setShowRegister(true);
        })
        .catch(function (error) {
          toast.error(error.response?.data?.message);
        });
    }
  };
  

  const userLogin = (e) => {
    if (emaillogin.length === 0) {
      setEmailError("Email is Required");
    } else if (!isValidEmail(emaillogin)) {
      setEmailError("Email is invalid");
      // setLoader(true);
      // setError(null);
    }
    if (passwordlogin.length === 0) {
      setPasswordLoginError("Password is Required");
    }
    var data = JSON.stringify({
      email: emaillogin,
      password: passwordlogin,
      rememberMe:rememberMe,
    });

    var config = {
      method: "post",
      url: `${api_url}/users-auth/signin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        localStorage.setItem("accessToken", resData?.accessToken);
        localStorage.setItem("refreshToken", resData?.refreshToken);
        localStorage.setItem("userId", resData?.user?.id);
        const savedRoute = localStorage.getItem('currentRoute');
        history.push(savedRoute);
        localStorage.removeItem('currentRoute');
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        if (
          emaillogin.length > 0 &&
          isValidEmail(emaillogin) &&
          passwordlogin.length > 0
        ) {
          setError("Incorrect email or password!");
        }
      });
  };

  function isValidEmail(emaillogin, registerEmail) {
    return /\S+@\S+\.\S+/.test(emaillogin, registerEmail);
  }
  // const handleClick = () => {
  //   window.location.href =
  //     "http://ec2-18-237-149-141.us-west-2.compute.amazonaws.com:5000/auth/users/google";
  // };

  const clientId =
    "1017307220638-e5l4r43nti312ksp06bjmdsk931nt9ih.apps.googleusercontent.com";
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  }, []);

  const loginSuccess = (res) => {
    // console.log("GetGoogle::", res)
    const { googleId, name, imageUrl, email } = res.profileObj;
    // console.log('data',googleId,name,imageUrl,email);
    const { id_token } = res.tokenObj;
    // console.log('id',id_token);
    axios
      .post(`${api_url}/users-auth/googlesignin`, {
        name: name,
        imageUrl: imageUrl,
        email: email,
        googleId: googleId,
      })
      .then((res) => {
        // console.log('res ))))',res)
        // const Token = res.data.token.accessToken
        localStorage.setItem("accessToken", res?.data?.data?.accessToken);
        localStorage.setItem("userId", res?.data?.data?.user?.id);
        const savedRoute = localStorage.getItem('currentRoute');
        history.push(savedRoute);
        localStorage.removeItem('currentRoute');
        // window.open('/account')
        toast.success("Login Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message, {
          position: "top-right",
          autoClose: 1500,
        });
      });
    // addUser(googleId, name, imageUrl, email, id_token);
  };
  // const addUser = async (googleId, name, imageUrl, email, id_token) => {
  //   await axios.post(`${api_url}/auth/users/googlesignin`, { name: name, imageUrl: imageUrl, email: email, googleId: googleId })
  //     .then((res) => {
  //       console.log('res',res)
  //       // const Token = res.data.token.accessToken
  //       // localStorage.setItem('LoginToken', Token)
  //       toast.success('Login Successfully Done', {
  //         position: "top-center",
  //         autoClose: 3000,
  //       });
  //       history.push("/account");
  //     })
  //     .catch((e) => {
  //       console.log('e', e);
  //       toast.error(e, {
  //         position: "top-right",
  //         autoClose: 1500,
  //       });
  //     });
  // }

  const failure = (res) => {
    console.log("login Failure::", res);
  };

  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Account</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">Login</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="loginmap">
        <div className="container-fluid p-0">
          <div className="row m-auto">
            <div className="col-lg-5 col-md-12 m-auto login-main">
              <div className="mainform">
                <Tabs defaultActiveKey="login" id="uncontrolled-tab-example">
                  <Tab eventKey="login" title="Log in">
                    <div className="form">
                      <div className="innerinput mb-2">
                        <p>Email</p>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={emaillogin}
                          onChange={(e) => {
                            setEmailLogin(e.target.value);
                            setEmailError("");
                            setError("");
                          }}
                          className={emailError ? "error-input" : ""}
                        />
                        {/* {Object.keys(emailError).map((key) => {
                          return (
                            <p className="inputErrors">{emailError[key]}</p>
                          );
                        })} */}
                        {emailError ? (
                          <p className="inputErrors text-danger">
                            {emailError}
                          </p>
                        ) : null}
                      </div>
                      <div className="innerinput">
                        <p>Password</p>
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={passwordlogin}
                          onChange={(e) => {
                            setPasswordLogin(e.target.value);
                            setPasswordLoginError("");
                            setError("");

                          }}
                          className={passwordloginerror ? "error-input" : ""}
                        />
                        {passwordloginerror ? (
                          <p className="text-danger">Password is Required</p>
                        ) : null}
                        <img
                          onClick={() => setShowPassword(!showPassword)}
                          src="assets\btn-icons\eye.svg"
                          alt=""
                          className="eye-icon"
                        />
                      </div>
                      <div className="forf">
                        <div class="custom-control custom-checkbox d-flex align-items-center">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                          />
                          <label
                            class="custom-control-label ms-2"
                            for="customCheck1"
                          >
                            Remember me
                          </label>
                        </div>
                        <Link to="/forgot-password">
                          <a className="link" href="#">
                            Forgot Password?
                          </a>
                        </Link>
                      </div>
                      {error ? (
                        <p className="input-Errors pb-3">{error}</p>
                      ) : null}
                      <div className="login">
                        <button
                          className="m-0"
                          // disabled={
                          //   inputs.email !== "" && inputs.password !== ""
                          //     ? false
                          //     : true
                          // }
                          onClick={(e) => userLogin(e)}
                        >
                          LOG IN
                        </button>
                        <hr className="line" />
                      </div>
                      <div className="lastbtns">
                        <p className="linklog">or</p>
                        {/* <button>
                          <img
                            src="\Assests\Facebook.svg"
                            alt="img"
                            className="btnimg"
                          />
                          Continue with Facebook
                        </button> */}
                        {/* <button
                          onClick={(e) => {
                            handleClick();
                          }}
                        >
                          <img
                            src="\Assests\Google.svg"
                            alt="img"
                            className="btnimg"
                          />
                          Continue with Google
                        </button> */}
                        <GoogleLogin
                          clientId={clientId}
                          className="sjhchx"
                          buttonText="Login with google"
                          onSuccess={loginSuccess}
                          onFailure={failure}
                          cookiePolicy={"single_host_origin"}
                        />
                        {/* <button>
                          <img
                            src="\Assests\Twitter.svg"
                            alt="img"
                            className="btnimg"
                          />
                          Continue with Twitter
                        </button> */}
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="reg" title="Register">
                    <div className="form">
                      <div className="innerinput">
                        <p>Name</p>
                        <input
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setNameError("");
                          }}
                          type="text"
                          placeholder="Name"
                          className={nameerror ? "error-input" : ""}
                        />
                        {nameerror ? (
                          <p className="text-danger">{nameerror}</p>
                        ) : null}
                      </div>
                      <div className="innerinput">
                        <p>Email</p>
                        <input
                          value={registerEmail}
                          onChange={(e) => {
                            setRegisterEmail(e.target.value);
                            setEmailErrorRegister("");
                          }}
                          type="text"
                          placeholder="Email"
                          className={emailerrorregister ? "error-input" : ""}
                        />
                        <div>
                          {emailerrorregister ? (
                            <p className="text-danger">{emailerrorregister}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="innerinput">
                        <p>Password</p>
                        <input
                          value={createPassword}
                          onChange={(e) => {
                            setCreatePassword(e.target.value);
                            setErrorPassword("");
                          }}
                          className={errorpassword ? "error-input" : ""}
                          type={showPasswordreg ? "text" : "password"}
                          placeholder="Password"
                        />
                        <img
                          onClick={() => setShowPasswordReg(!showPasswordreg)}
                          src="assets\btn-icons\eye.svg"
                          alt=""
                          className="eye-icon"
                        />
                        {errorpassword && (
                          <p className="text-danger">{errorpassword}</p>
                        )}
                        <div className="password-condition">
                          {/* <p>
                            Password Must Contain At Least One Lowercase Letter,
                            One Uppercase Letter, One Digit, One Special
                            Character And Be At Least 8 Characters Long.
                          </p> */}
                        </div>
                      </div>
                      <div className="login">
                        <button onClick={(e) => userRegister(e)}>
                          Register
                        </button>
                      </div>
                      <div className="lastbtns">
                        {/* <button>
                          <img
                            src="\Assests\Facebook.svg"
                            alt="img"
                            className="btnimg"
                          />
                          Continue with Facebook
                        </button> */}
                        <GoogleLogin
                          clientId={clientId}
                          className="sjhchx"
                          buttonText="Login with google"
                          onSuccess={loginSuccess}
                          onFailure={failure}
                          cookiePolicy={"single_host_origin"}
                        />
                        {/* <button
                          onClick={(e) => {
                            // googleAuth(e);
                            handleClick();
                          }}
                        >
                          <img
                            src="\Assests\Google.svg"
                            alt="img"
                            className="btnimg"
                          />
                          Continue with Google
                        </button> */}
                        {/* <button>
                          <img
                            src="\Assests\Twitter.svg"
                            alt="img"
                            className="btnimg"
                          />
                          Continue with Twitter
                        </button> */}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Location showRegister={showRegister} setShowRegister={setShowRegister} />
    </>
  );
};

export default Login;
