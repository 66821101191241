// import React, { useState, useEffect } from "react";
// import { Link, useHistory } from "react-router-dom";
// import "./justlanding.scss";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import axios from "axios";
// import moment from "moment";
// import Environment from "../../utils/environment";
// import Spinner from "react-bootstrap/Spinner";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Location from "../../Location/Location";
// import { useDispatch } from "react-redux";
// import { cartItems } from "../../../redux/action/index";

// const JustLanding = ({ justLanding }) => {
//   const dispatch = useDispatch();

//   const [isShown, setIsShown] = useState(false);
//   const val = localStorage.getItem("accessToken");

//   const history = useHistory();
//   const api_url = Environment.base_url;
//   // const [justLanding, setJustLanding] = useState([]);
//   const [loader, setLoader] = useState(null);
//   const [categories, setCategories] = useState([]);

//   const [isLoading, setIsLoading] = useState(false);

//   // const handleClick = () => {
//   //   setIsShown((current) => !current);
//   // };

//   const getJustLandingList = async () => {
//     setIsLoading(true);
//     setLoader(true);
//     const config = {
//       method: "get",
//       url: `${api_url}/store/products?limit=50&offset=1&sort[]=createdAt@desc&filter=isPublish=eq:true`,
//       headers: {
//         Authorization: "Bearer " + val,
//       },
//     };
//     await axios(config)
//       .then((res) => {
//         setIsLoading(false);
//         // setJustLanding(res?.data?.data?.products);
//         setLoader(false);
//       })
//       .catch((err) => {
//         if (err?.response?.status == 501) {
//           history.push("/");
//         } else {
//           toast.error(err?.response?.data?.message, {
//             position: "top-right",
//             autoClose: 2000,
//           });
//         }
//         setLoader(false);
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     // getJustLandingList();
//   }, []);

//   const owl_option = {
//     nav: true,
//     dots: false,
//     dotsEach: false,
//     loop: false,
//     autoplay: false,
//     navText: [
//       "<i class='fas fa-caret-left'></i>",
//       "<i class='fas fa-caret-right'></i>",
//     ],
//     responsive: {
//       0: {
//         items: 1,
//         margin: 15,
//       },
//       361: {
//         items: 1.5,
//         loop: true,
//         margin: 15,
//       },
//       600: {
//         items: 1,
//         margin: 15,
//       },
//       700: {
//         items: 2.5,
//         margin: 20,
//         loop: true,
//       },
//       1000: {
//         items: 3.5,
//         margin: 20,
//         loop: true,
//       },
//       1200: {
//         items: 5,
//         margin: 20,
//       },
//     },
//   };

//   const getJustLandingCategories = async () => {
//     await axios
//       .get(
//         `${Environment.base_url}/store/categories?parentOnly=true&afterElement=0&limit=25&allCategories=false`,
//         {
//           headers: {
//             Authorization: "Bearer " + val,
//           },
//         }
//       )
//       .then((res) => {
//         setCategories(res?.data?.data);
//       })
//       .catch((err) => {});
//   };
//   useEffect(() => {
//     getJustLandingCategories();
//   }, []);

//   const [show12, setShow12] = useState(false);
//   const handleCartItem = (productToAdd) => {
//     productToAdd.cartAmount = 1;
//     const temp = [productToAdd];
//     temp.push(1);
//     dispatch(cartItems(temp));
//     setShow12(true);
//   };

//   // const checkQuantity = (item) => {
//   //   let res = false
//   //   if (item?.sourceProductId) {
//   //     if (item?.quantity >
//   //       item?.outOfStockThreshold) {
//   //       return true;
//   //     }
//   //   } else {
//   //     if(item?.productCap
//   //       ?.maxCap >
//   //       item?.productCap
//   //         ?.todaySold){
//   //           return true;
//   //         }
//   //   }
//   //   return res;
//   // }

//   const [posts, setPosts] = useState([]);
//   const getBanner = async () => {
//     setPosts([]);
//     await axios
//       .get(
//         `${Environment.base_url2}/banners?offset=1&&limit=30`,

//         {
//           headers: {
//             Authorization: "Bearer " + val,
//           },
//         }
//       )

//       .then((res) => {
//         setPosts(res?.data?.data?.banners);
//       })
//       .catch((err) => {});
//   };

//   const banner4 = posts.filter(
//     (data) => data?.type === "main banner 4" && data?.isPublish === true
//   );

//   useEffect(() => {
//     getBanner();
//   }, []);

//   return (
//     <>
//       <section className="justlanding">
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-xl-11 col-12 m-auto p-0">
//               <div className="row">
//                 <div className="col-xl-12 p-0">
//                   <div className="banner">
//                     <img src={banner4[0]?.imageUrl} alt="" />
//                   </div>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-xl-12 col-12 p-0">
//                   <div className="parent">
//                     <div className="main-heading">
//                       <h4>New Landings </h4>
//                     </div>
//                     {/* <div className="social-tags">
//                       <a onClick={() => showAllCategories()}>All</a>
//                       {categories?.map((data, index) => {
//                         return (
//                           <a
//                             onClick={(e) => {
//                               setFilterJustLanding(data?.id);
//                             }}
//                           >
//                             {data?.name}
//                           </a>
//                         );
//                       })}
//                     </div> */}
//                   </div>
//                   {isLoading ? (
//                     <div className="text-center py-4">
//                       <Spinner animation="border" variant="info" />
//                     </div>
//                   ) : (
//                     // <div className="loader-image-landing">
//                     //   <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
//                     // </div>
//                     <div class="owl_option">
//                       {justLanding?.length > 0 ? (
//                         <OwlCarousel className="owl-theme" {...owl_option}>
//                           {justLanding?.map((item) => {
//                             return (
//                               <>
//                                 {/* {checkQuantity(item) && */}
//                                 <div className="card-topsaver">
//                                   <div className="top-abs">
//                                     <span className="off">New</span>
//                                   </div>
//                                   <Link to={"/product-detail?id=" + item?.id}>
//                                     <div className="inner-img">
//                                       <img
//                                         src={
//                                           item?.imageUrl ? item?.imageUrl : ""
//                                         }
//                                         alt="img"
//                                         className={
//                                           item?.sourceProductId != null
//                                             ? item?.quantity <=
//                                               item?.outOfStockThreshold
//                                               ? "outimage"
//                                               : "img-fluid"
//                                             : item?.productCap?.maxCap <=
//                                               item?.productCap?.todaySold
//                                             ? "outimage"
//                                             : "img-fluid"
//                                         }
//                                       />
//                                     </div>
//                                     <div className="bottom-text">
//                                       <span>
//                                         {item?.category
//                                           ? item?.category.name
//                                           : ""}
//                                       </span>
//                                       <h5>{item?.name}</h5>
//                                       <h6>Rs. {item?.price}</h6>
//                                     </div>
//                                   </Link>
//                                   <button
//                                     // className="common-btn"
//                                     className={
//                                       item?.sourceProductId != null
//                                         ? item?.quantity <=
//                                           item?.outOfStockThreshold
//                                           ? "outofstock"
//                                           : "common-btn"
//                                         : item?.productCap?.maxCap <=
//                                           item?.productCap?.todaySold
//                                         ? "outofstock"
//                                         : "common-btn"
//                                     }
//                                     onClick={() => handleCartItem(item)}
//                                     disabled={
//                                       item?.sourceProductId != null
//                                         ? item?.quantity <=
//                                           item?.outOfStockThreshold
//                                         : item?.productCap?.maxCap <=
//                                           item?.productCap?.todaySold
//                                     }
//                                   >
//                                     {item?.sourceProductId != null ? (
//                                       item?.quantity <=
//                                       item?.outOfStockThreshold ? (
//                                         <svg
//                                           width="20"
//                                           height="20"
//                                           viewBox="0 0 20 20"
//                                           fill="none"
//                                           xmlns="http://www.w3.org/2000/svg"
//                                           className="change-my-color me-2"
//                                         >
//                                           <path
//                                             d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
//                                             fill="#AFE0F4"
//                                           />
//                                           <path
//                                             d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
//                                             fill="#AFE0F4"
//                                           />
//                                         </svg>
//                                       ) : (
//                                         <svg
//                                           width="20"
//                                           height="20"
//                                           viewBox="0 0 20 20"
//                                           fill="none"
//                                           xmlns="http://www.w3.org/2000/svg"
//                                           className="change-my-color me-2"
//                                         >
//                                           <path
//                                             d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
//                                             fill="#009DE0"
//                                           />
//                                           <path
//                                             d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
//                                             fill="#009DE0"
//                                           />
//                                         </svg>
//                                       )
//                                     ) : item?.productCap?.maxCap <=
//                                       item?.productCap?.todaySold ? (
//                                       <svg
//                                         width="20"
//                                         height="20"
//                                         viewBox="0 0 20 20"
//                                         fill="none"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         className="change-my-color me-2"
//                                       >
//                                         <path
//                                           d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
//                                           fill="#AFE0F4"
//                                         />
//                                         <path
//                                           d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
//                                           fill="#AFE0F4"
//                                         />
//                                       </svg>
//                                     ) : (
//                                       <svg
//                                         width="20"
//                                         height="20"
//                                         viewBox="0 0 20 20"
//                                         fill="none"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         className="change-my-color me-2"
//                                       >
//                                         <path
//                                           d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
//                                           fill="#009DE0"
//                                         />
//                                         <path
//                                           d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
//                                           fill="#009DE0"
//                                         />
//                                       </svg>
//                                     )}
//                                     {item?.sourceProductId != null
//                                       ? item?.quantity <=
//                                         item?.outOfStockThreshold
//                                         ? "Out of Stock"
//                                         : "Add to Cart"
//                                       : item?.productCap?.maxCap <=
//                                         item?.productCap?.todaySold
//                                       ? "Out of Stock"
//                                       : "Add to Cart"}
//                                   </button>
//                                 </div>
//                                 {/* } */}
//                               </>
//                             );
//                           })}
//                         </OwlCarousel>
//                       ) : (
//                         <h5>No Posts To Show</h5>
//                       )}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Location show12={show12} setShow12={setShow12} />
//     </>
//   );
// };

// export default JustLanding;

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./justlanding.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import moment from "moment";
import Environment from "../../utils/environment";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Location from "../../Location/Location";
import { useDispatch } from "react-redux";
import { cartItems } from "../../../redux/action/index";

const JustLanding = ({ justLanding, setJustLanding, quantity }) => {
  const dispatch = useDispatch();
  const [rend, setRend] = useState(false);
  const [startposition, setstartposition] = useState(0);

  const [isShown, setIsShown] = useState(false);
  const val = localStorage.getItem("accessToken");

  const history = useHistory();
  const api_url = Environment.base_url;
  // const [justLanding, setJustLanding] = useState([]);
  const [loader, setLoader] = useState(null);
  const [categories, setCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // const handleClick = () => {
  //   setIsShown((current) => !current);
  // };

  const getJustLandingList = async () => {
    setIsLoading(true);
    setLoader(true);
    const config = {
      method: "get",
      url: `${api_url}/store/products?limit=50&offset=1&sort[]=createdAt@desc&filter=isPublish=eq:true`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setIsLoading(false);
        // setJustLanding(res?.data?.data?.products);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // getJustLandingList();
  }, []);

  const owl_option = {
    nav: true,
    dots: false,
    dotsEach: false,
    loop: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-caret-left'></i>",
      "<i class='fas fa-caret-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      361: {
        items: 1.5,
        loop: true,
        margin: 15,
      },
      600: {
        items: 1,
        margin: 15,
      },
      700: {
        items: 2.5,
        margin: 20,
        loop: true,
      },
      1000: {
        items: 3.5,
        margin: 15,
        loop: true,
      },
      1200: {
        items: 6,
        margin: 15,
      },
      1900: {
        items: 6,
        margin: 15,
      },
    },
  };

  const getJustLandingCategories = async () => {
    await axios
      .get(
        `${Environment.base_url}/store/categories?parentOnly=true&afterElement=0&limit=25&allCategories=false`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setCategories(res?.data?.data);
      })
      .catch((err) => {});
  };
  // useEffect(() => {
  //   getJustLandingCategories();
  // }, []);

  const [show12, setShow12] = useState(false);

  // const checkQuantity = (item) => {
  //   let res = false
  //   if (item?.sourceProductId) {
  //     if (item?.quantity >
  //       item?.outOfStockThreshold) {
  //       return true;
  //     }
  //   } else {
  //     if(item?.productCap
  //       ?.maxCap >
  //       item?.productCap
  //         ?.todaySold){
  //           return true;
  //         }
  //   }
  //   return res;
  // }

  const [posts, setPosts] = useState([]);
  const getBanner = async () => {
    setPosts([]);
    await axios
      .get(
        `${Environment.base_url2}/banners?offset=1&&limit=30`,

        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )

      .then((res) => {
        setPosts(res?.data?.data?.banners);
      })
      .catch((err) => {});
  };

  const banner4 = posts.filter(
    (data) => data?.type === "main banner 4" && data?.isPublish === true
  );

  // useEffect(() => {
  //   getBanner();
  // }, []);

  const [update, setUpdate] = useState(0);

  const handleCartItem = (productToAdd, index) => {
    setstartposition(index);
    setJustLanding([]);
    let dumArr = justLanding;
    let count = productToAdd;
    // setJustLanding([]);
    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    // console.log("DDDDDDDD", dumArr, count);
    setJustLanding(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = 1;
    let temp = [productToAdd];
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update + 1);
  };

  const increase = (productToAdd, index) => {
    setstartposition(index);
    setJustLanding([]);
    let dumArr = justLanding;
    let count = productToAdd;
    // setJustLanding([]);
    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
  
    setJustLanding(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = productToAdd.cartAmount + 1;
    let temp = [productToAdd];
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update + 1);
  };

  const decrease = (productToAdd, index) => {
    setstartposition(index);
    setJustLanding([]);
    let dumArr = justLanding;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount - 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);

    setJustLanding(dumArr);

    productToAdd.cartAmount = productToAdd.cartAmount - 1;
    let temp = [productToAdd];
    temp[1] = -1;

    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update + 1);
  };

  return (
    <>
      <section className="justlanding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-12 col-12 p-0">
                  <div className="parent">
                    <div className="main-heading">
                      <h4>
                      Everything You Need{" "}
                        <Link to="/all-categories">
                          View All{" "}
                          <img
                            src="\assets\btn-icons\arrow-right.svg"
                            alt="img"
                            className="img-fluid ml-2"
                          />
                        </Link>
                      </h4>
                    </div>
                    {/* <div className="social-tags">
                      <a onClick={() => showAllCategories()}>All</a>
                      {categories?.map((data, index) => {
                        return (
                          <a
                            onClick={(e) => {
                              setFilterJustLanding(data?.id);
                            }}
                          >
                            {data?.name}
                          </a>
                        );
                      })}
                    </div> */}
                  </div>
                  {justLanding?.length > 0 && (
                      <div className="multi-card-set">
                        {justLanding?.map((item, index) => {
                          return (
                            <>
                              <div className="card-topsaver" key={index}>
                                {/* <div className="top-abs">
                            <span className="off">New</span>
                            <div className="zzzzzzzzzzz">
                              <img
                                src={cls ? imglikeblue : imgliketransparent}
                                alt="img"
                                className="img-fluid "
                              />
                            </div>
                          </div> */}
                                <div className="inner-img">
                                  {/* {console.log(indx, index, 'indx === index')} */}
                                  {item?.quantityAmount ? (
                                    <div className="btn-add add-counter">
                                      <a onClick={() => decrease(item, index)}>
                                        <img
                                          src="\assets\minus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                      <span>{item?.quantityAmount}</span>
                                      <a
                                        onClick={() => {
                                          if (
                                            item.quantityAmount !==
                                            item.quantity-item?.outOfStockThreshold
                                          ) {
                                            increase(item, index);
                                          }
                                        }}
                                      >
                                        <img
                                          src="\assets\plus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        handleCartItem(item, index);
                                      }}
                                      className="btn-add"
                                    >
                                      <img
                                        src="\assets\btn-add.png"
                                        alt="img"
                                        className="img-fluid"
                                        style={{
                                          width: "38px",
                                          height: "38px",
                                        }}
                                      />
                                    </div>
                                  )}
                                  <Link to={"/product-detail?id=" + item?.id}>
                                    <img
                                      src={item?.imageUrl ? item?.imageUrl : "assets\Frame 186 (1).png"}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </Link>
                                </div>
                                <Link to={"/product-detail?id=" + item?.id}>
                                  <div className="bottom-text">
                                    <span>{item?.category[0]?.name}</span>
                                    <h5>{item?.name}</h5>
                                    <h6>Rs.{item?.price}</h6>
                                  </div>
                                </Link>
                              </div>
                            </>
                          );
                        })}
                        </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
                <div className="col-xl-11 m-auto p-0">
                <div className="bannernneeww m-0">
                    {/* <img
                      src="\assets\dummy-imgs\fastestdelievryintown.png"
                      alt="img"
                      className="img-fluid"
                    /> */}

                    <div className="leftdownn">
                      <h4 className="downlodeed">Download The App</h4>
                      <p className="aasdasdsad">
                        and avail RS 200 off on your 1st order
                      </p>
                    </div>




                    <div className="listddd">
                      <p>


                        < a href="https://play.google.com/store/apps/details?id=com.quick.groceryapp" target="blank">  <img
                          className=" "
                          src="\footerassets\googleplay1.png"
                        /></a>
                      </p>
                      <p>
                        < a className="" href="https://apps.apple.com/pk/app/quick-grocery-shopping/id1643695889" target="blank">  <img
                          className="  "
                          src="\footerassets\appstore2.png"
                        /></a>
                      </p>
                    </div>

                  </div>
                </div>
              </div> 
        </div>
      </section>

      <Location show12={show12} setShow12={setShow12} />
    </>
  );
};

export default JustLanding;
