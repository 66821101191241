import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cartItems } from "../../redux/action";
import "./related.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import moment from "moment";
import Environment from "../utils/environment";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imglikeblue from "../../assets/like-blue.svg";
import imgliketransparent from "../../assets/like-transparent.svg";

const Related = ({ id, setId, handleAddToCart }) => {
  const dispatch = useDispatch();
  const [startposition, setstartposition] = useState(0);
  const [wishlist, setWishlist] = useState([]);
  const [wishCount, setWishCount] = useState(0);
  const [cls, setcls] = useState(false);
  const history = useHistory();
  const api_url = Environment.base_url;

  const [loader, setLoader] = useState(null);

  const val = localStorage.getItem("accessToken");

  const [isShown, setIsShown] = useState(false);
  const [categoriesProduct, setCategoriesProduct] = useState([]);

  const owl_option = {
    nav: true,
    dots: false,
    dotsEach: false,
    loop: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-caret-left'></i>",
      "<i class='fas fa-caret-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      361: {
        items: 1.5,
        loop: true,
        margin: 15,
      },
      600: {
        items: 1,
        margin: 15,
      },
      700: {
        items: 3,
        margin: 40,
      },
      1000: {
        items: 4,
        margin: 40,
      },
      1200: {
        items: 6,
        margin: 20,
      },
    },
  };

  useEffect(() => {
    if (id) {
      getCategoryProducts();
    }
  }, [id]);
  const getCategoryProducts = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url: `${api_url}/store/categories/${id}/products?limit=6&offset=1`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setCategoriesProduct(res?.data?.data?.products);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const AddtoWishList = (data) => {
    data.wish = 1;
    let temp = [];
    if (localStorage.getItem("wishlist") == null) {
      temp = [];
    } else {
      temp = JSON.parse(localStorage.getItem("wishlist"));
    }
    let existingItem = temp.find((item) => item?.id == data?.id);
    if (existingItem) {
      setcls(true);
      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?.wish % 2 == 1) {
          temp.splice(i, 1);
          localStorage.removeItem("wishlist");
          localStorage.setItem("wishlist", JSON.stringify(temp));
        }
      }
    } else {
      setcls(false);
      temp.push(data);
      localStorage.setItem("wishlist", JSON.stringify(temp));
    }

    setWishlist(temp);

    setWishCount(temp.length);
  };

  useEffect(() => {
    const data = localStorage.getItem("wishlist");
    if (data) {
      const wishlistItems = JSON.parse(data);
      const existingItem = wishlistItems.find((item) => item.id === data.id);
      if (existingItem) {
        setcls(true);
      } else {
        setcls(false);
      }
    }
  }, []);

  const [rend, setRend] = useState(false);
  const [update, setUpdate] = useState(0);


  const handleCartItem = (productToAdd, index) => {
    setstartposition(index)
    setCategoriesProduct([]);
    let dumArr = categoriesProduct;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    //  console.log("DDDDDDDD", dumArr, count);
    setCategoriesProduct(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = 1;
    let temp = [productToAdd];
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update+1)
  };

  

  const increase = (productToAdd, index) => {
    setstartposition(index)
    setCategoriesProduct([]);
    let dumArr = categoriesProduct;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    //  console.log("DDDDDDDD", dumArr, count);
    setCategoriesProduct(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = productToAdd.cartAmount+1;
    let temp = [productToAdd];
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update+1)
  };

  const decrease = (productToAdd, index) => {
    setstartposition(index)
    setCategoriesProduct([]);
     let dumArr = categoriesProduct;
     let count = productToAdd;
 
     dumArr = dumArr?.filter((i) => {
       return i?.id !== count?.id;
     });
     let quantityAmount = 1;
     if (productToAdd?.quantityAmount) {
       quantityAmount = productToAdd?.quantityAmount - 1;
     }
     count.quantityAmount = quantityAmount;
     dumArr?.splice(index, count, count);
     //  console.log("DDDDDDDD", dumArr, count);
     setCategoriesProduct(dumArr);
     // setOpen(true);
     // setIndx(index);
     productToAdd.cartAmount = productToAdd.cartAmount-1;
     let temp = [productToAdd];
     temp[1]= -1
     temp.push(1);
     dispatch(cartItems(temp));
     setRend(!rend);
     setUpdate(update+1)
 
   };
   const prorefresh = () => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 500);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <section className="related">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-12 col-12 p-0">
                  <div className="parent">
                    <div className="main-heading">
                      <h4>Related Products</h4>
                    </div>
                  </div>
                  <div class="owl_option">
                    <OwlCarousel className="owl-theme" startPosition={startposition} key={update} {...owl_option}>
                      {categoriesProduct?.length > 0
                        ? categoriesProduct?.map((item, index) => {
                            return (
                              <>
                                 <div  className="card-topsaver" key={index}>
                                <div className="top-abs">
                                  <span className="off">New</span>
                                  {/* <div className="zzzzzzzzzzz">
                                    <img
                                      src={
                                        cls ? imglikeblue : imgliketransparent
                                      }
                                      alt="img"
                                      className="img-fluid "
                                    />
                                  </div> */}
                                </div>
                                <div className="inner-img">
                                  {item?.quantityAmount  ? (
                                    <div className="btn-add add-counter">
                                      <a onClick={() => decrease(item, index)}>
                                        <img
                                          src="\assets\minus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                      <span>{item?.quantityAmount}</span>
                                      <a
                                          onClick={() => {
                                            if (
                                              item.quantityAmount !==
                                              item?.quantity-item?.outOfStockThreshold
                                            ) {
                                              increase(item, index);
                                            }
                                          }}
                                      >
                                        <img
                                          src="\assets\plus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  ) : (
                                    <a
                                      onClick={() =>
                                        handleCartItem(item, index)
                                      }
                                      className="btn-add pulse-animation"
                                    >
                                      <img
                                        src="\assets\btn-add.png"
                                        alt="img"
                                        className="img-fluid"
                                        style={{
                                          width: "38px",
                                          height: "38px",
                                        }}
                                      />
                                    </a>
                                  )}
                                <Link onClick={prorefresh} to={"/product-detail?id=" + item?.id}>
                               
                                  <img
                                    src={item?.imageUrl ? item?.imageUrl:"assets\Frame 186 (1).png"}
                                    alt="img"
                                    className="img-fluid"
                                  /></Link>
                                </div>
                                <Link onClick={prorefresh} to={"/product-detail?id=" + item?.id}>
                                <div className="bottom-text">
                                  <span> {item?.category[0]?.name}</span>
                                  <h5> {item?.name}</h5>
                                  <h6>Rs. {item?.price}</h6>
                                </div>
                                </Link>
                              </div>
                              </>
                            );
                          })
                        : null}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Related;