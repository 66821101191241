// import React, { useState, useEffect } from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
// import "./seller.scss";
// import OwlCarousel from "react-owl-carousel";
// import process from "process/browser";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import Environment from "../../utils/environment";
// import axios from "axios";
// import { toast } from "react-toastify";
// import imgliketransparent from "../../../assets/like-transparent.svg";
// import imglikeblue from "../../../assets/like-blue.svg";
// import { useDispatch } from "react-redux";
// import { cartItems } from "../../../redux/action/index";
// import Location from "../../Location/Location";
// import Related from "../../RelatedProduct/Related";
// import { Spinner } from "react-bootstrap";

// const Seller = ({ wishlist, setWishlist, wishCount, setWishCount }) => {
//   const val = localStorage.getItem("accessToken");

//   const dispatch = useDispatch();

//   const [posts, setPosts] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [filtercategories, setFilterCategories] = useState([]);
//   const [filterbestseller, setFilterBestSeller] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [post, setPost] = useState([]);

//   // setIsShown((current) => !current);
//   const [cls, setcls] = useState(false);

//   // const handleClick = (data) => {
//   //   let val = localStorage.getItem("wishlist");
//   //   let temp = [];

//   //   if (val !== "null" || val !== "undefined") {
//   //     val = JSON.parse(val);
//   //     temp = [];
//   //   }
//   //   temp = [...temp];

//   //   let existingItem = temp.some((temp) => temp.productId == data.productId);

//   //   if (existingItem === false && val) {
//   //     for (let i = 0; i < temp?.length; i++)
//   //     setcls(true);
//   //     localStorage.removeItem("wishlist");

//   //   } else if (existingItem === false) {
//   //     setcls(false);
//   //     temp.push(data);
//   //     localStorage.setItem("wishlist", JSON.stringify(temp));
//   //   }
//   //   setWishCount(temp.length);

//   // };

//   const AddtoWishList = (data) => {
//     data.wish = 1;
//     let temp = [];
//     if (localStorage.getItem("wishlist") == null) {
//       temp = [];
//     } else {
//       temp = JSON.parse(localStorage.getItem("wishlist"));
//     }

//     let existingItem = temp.find((item) => item?.id == data?.id);
//     if (existingItem) {
//       setcls(true);
//       for (let i = 0; i < temp?.length; i++) {
//         if (temp[i]?.wish % 2 == 1) {
//           temp.splice(i, 1);
//           localStorage.removeItem("wishlist");
//           localStorage.setItem("wishlist", JSON.stringify(temp));
//         }
//       }
//     } else {
//       setcls(false);
//       temp.push(data);
//       localStorage.setItem("wishlist", JSON.stringify(temp));
//     }

//     setWishlist(temp);

//     setWishCount(temp.length);
//   };

//   useEffect(() => {
//     const data = localStorage.getItem("wishlist");
//     if (data) {
//       const wishlistItems = JSON.parse(data);
//       const existingItem = wishlistItems.find((item) => item.id === data.id);
//       if (existingItem) {
//         setcls(true);
//       } else {
//         setcls(false);
//       }
//     }
//   }, []);

//   const owl_option = {
//     nav: true,
//     dots: false,
//     dotsEach: false,
//     loop: false,
//     autoplay: false,
//     navText: [
//       "<i class='fas fa-caret-left'></i>",
//       "<i class='fas fa-caret-right'></i>",
//     ],
//     responsive: {
//       0: {
//         items: 1,
//         margin: 15,
//       },
//       361: {
//         items: 1.5,
//         loop: true,
//         margin: 15,
//       },
//       600: {
//         items: 1,
//         margin: 15,
//       },
//       700: {
//         items: 2.5,
//         margin: 20,
//         loop: true,
//       },
//       1000: {
//         items: 3.5,
//         margin: 20,
//         loop: true,
//       },
//       1200: {
//         items: 5,
//         margin: 20,
//       },
//     },
//   };
//   const getBestSeller = async () => {
//     setIsLoading(true);
//     await axios
//       .get(
//         `${Environment.base_url}/store/products/best-seller?offset=1&&limit=5&${
//           filterbestseller
//             ? "filter=productCategoryId=in:[" + filterbestseller + "]"
//             : ""
//         }`,
//         {
//           headers: {
//             Authorization: "Bearer " + val,
//           },
//         }
//       )
//       .then((res) => {
//         setIsLoading(false);
//         setPosts(res?.data?.data?.products);
//       })
//       .catch((err) => {});
//     setIsLoading(false);
//   };
//   useEffect(() => {
//     getBestSeller();
//   }, [filterbestseller]);

//   const getSellerCategories = async () => {
//     await axios
//       .get(
//         `${Environment.base_url}/store/categories?parentOnly=true&afterElement=0&limit=25&allCategories=false`,
//         {
//           headers: {
//             Authorization: "Bearer " + val,
//           },
//         }
//       )
//       .then((res) => {
//         setCategories(res?.data?.data);
//       })
//       .catch((err) => {});
//   };
//   useEffect(() => {
//     getSellerCategories();
//   }, []);

//   const FilterCategories = async (id) => {
//     await axios
//       .get(
//         `${Environment.base_url}/store/categories/${id}/products/best-seller?limit=5&offset=5`,
//         {
//           headers: {
//             Authorization: "Bearer " + val,
//           },
//         }
//       )
//       .then((res) => {
//         setFilterCategories(res?.data?.data);
//       })
//       .catch((err) => {});
//   };

//   // dropdown value show category
//   // const [selectedcheck, setSelectedCheck] = useState([]);
//   // const [rend, setRend] = useState(false)

//   // const AddCategory = (e, item) => {
//   //     let dumArr = selectedcheck;
//   //     if (e.target.checked === true) {
//   //         dumArr.push(item);
//   //     }
//   //     else if (e.target.checked === false) {
//   //         // dumArr.pop();
//   //     }
//   //     setSelectedCheck(dumArr);
//   //     setRend(!rend);
//   // }
//   const [show12, setShow12] = useState(false);

//   const handleCartItem = (productToAdd) => {
//     productToAdd.cartAmount = 1;
//     const temp = [productToAdd];
//     temp.push(1);
//     dispatch(cartItems(temp));
//     setShow12(true);
//   };

//   const showAllCategories = () => {
//     setFilterBestSeller(null);
//     getBestSeller(null);
//   };

//   const getBanner = async () => {
//     setPost([]);
//     await axios
//       .get(
//         `${Environment.base_url2}/banners?offset=1&&limit=30`,

//         {
//           headers: {
//             Authorization: "Bearer " + val,
//           },
//         }
//       )

//       .then((res) => {
//         setPost(res?.data?.data?.banners);
//       })
//       .catch((err) => {});
//   };

//   const banner3 = post.filter(
//     (data) => data?.type === "main banner 3" && data?.isPublish === true
//   );

//   useEffect(() => {
//     getBanner();
//   }, []);

//   return (
//     <>
//       <section className="seller">
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-xl-11 col-12 m-auto p-0">
//               <div className="row">
//                 <div className="col-xl-12 p-0">
//                   <div className="banner">
//                     <img src={banner3[0]?.imageUrl} alt="" />
//                   </div>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-xl-12 col-12 p-0">
//                   <div className="parent">
//                     <div className="main-heading">
//                       <h4>Quick Pick</h4>
//                     </div>
//                     {/* <div className="social-tags">
//                       <a onClick={() => showAllCategories()}>All</a>
//                       {categories?.map((data, index) => {
//                         return (
//                           <a
//                             onClick={(e) => {
//                               FilterCategories(data?.id);
//                               setFilterBestSeller(data?.id);
//                             }}
//                           >
//                             {data?.name}
//                           </a>
//                         );
//                       })}
//                     </div> */}
//                   </div>
//                   {isLoading ? (
//                     <div className="text-center py-4">
//                       <Spinner animation="border" variant="info" />
//                     </div>
//                   ) : (
//                     // <div className="loader-image-landing">
//                     //   <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
//                     // </div>
//                     <div>
//                       {posts?.length > 0 ? (
//                         <div class="owl_option">
//                           <OwlCarousel className="owl-theme" {...owl_option}>
//                             {posts?.map((item, index) => {
//                               return (
//                                 <div key={index} className="card-topsaver">
//                                   <div className="top-abs">
//                                     {/* <span className="off">New</span> */}

//                                     {/* <div className="zzzzzzzzzzz">
//                                   <img
//                                     onClick={() => AddtoWishList(item)}
//                                     src={cls ? imglikeblue : imgliketransparent}
//                                     alt="img"
//                                     className={
//                                       cls == item?.productId
//                                         ? "img-fluid 11"
//                                         : "img-fluid 22"
//                                     }
//                                   />
//                                 </div> */}
//                                   </div>
//                                   <Link
//                                     to={
//                                       "/product-detail?id=" + item?.product?.id
//                                     }
//                                   >
//                                     <div className="inner-img">
//                                       <img
//                                         src={item?.product?.imageUrl}
//                                         alt="img"
//                                         className={
//                                           item?.product?.sourceProductId != null
//                                             ? item?.product?.quantity <=
//                                               item?.product?.outOfStockThreshold
//                                               ? "outimage"
//                                               : "img-fluid"
//                                             : item?.product?.productCap
//                                                 ?.maxCap <=
//                                               item?.product?.productCap
//                                                 ?.todaySold
//                                             ? "outimage"
//                                             : "img-fluid"
//                                         }
//                                       />
//                                     </div>
//                                     <div className="bottom-text">
//                                       <span>
//                                         {item?.product?.category?.name}
//                                       </span>
//                                       <h5>{item?.product?.name}</h5>
//                                       <h6>Rs. {item?.product?.price}</h6>
//                                     </div>
//                                   </Link>
//                                   <button
//                                     // className="common-btn"
//                                     className={
//                                       item?.product?.sourceProductId != null
//                                         ? item?.product?.quantity <=
//                                           item?.product?.outOfStockThreshold
//                                           ? "outofstock"
//                                           : "common-btn"
//                                         : item?.product?.productCap?.maxCap <=
//                                           item?.product?.productCap?.todaySold
//                                         ? "outofstock"
//                                         : "common-btn"
//                                     }
//                                     onClick={() =>
//                                       handleCartItem(item?.product)
//                                     }
//                                     disabled={
//                                       item?.product?.sourceProductId != null
//                                         ? item?.product?.quantity <=
//                                           item?.product?.outOfStockThreshold
//                                         : item?.product?.productCap?.maxCap <=
//                                           item?.product?.productCap?.todaySold
//                                     }
//                                   >
//                                     {item?.product?.sourceProductId != null ? (
//                                       item?.product?.quantity <=
//                                       item?.product?.outOfStockThreshold ? (
//                                         <svg
//                                           width="20"
//                                           height="20"
//                                           viewBox="0 0 20 20"
//                                           fill="none"
//                                           xmlns="http://www.w3.org/2000/svg"
//                                           className="change-my-color me-2"
//                                         >
//                                           <path
//                                             d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
//                                             fill="#AFE0F4"
//                                           />
//                                           <path
//                                             d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
//                                             fill="#AFE0F4"
//                                           />
//                                         </svg>
//                                       ) : (
//                                         <svg
//                                           width="20"
//                                           height="20"
//                                           viewBox="0 0 20 20"
//                                           fill="none"
//                                           xmlns="http://www.w3.org/2000/svg"
//                                           className="change-my-color me-2"
//                                         >
//                                           <path
//                                             d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
//                                             fill="#009DE0"
//                                           />
//                                           <path
//                                             d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
//                                             fill="#009DE0"
//                                           />
//                                         </svg>
//                                       )
//                                     ) : item?.product?.productCap?.maxCap <=
//                                       item?.product?.productCap?.todaySold ? (
//                                       <svg
//                                         width="20"
//                                         height="20"
//                                         viewBox="0 0 20 20"
//                                         fill="none"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         className="change-my-color me-2"
//                                       >
//                                         <path
//                                           d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
//                                           fill="#AFE0F4"
//                                         />
//                                         <path
//                                           d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
//                                           fill="#AFE0F4"
//                                         />
//                                       </svg>
//                                     ) : (
//                                       <svg
//                                         width="20"
//                                         height="20"
//                                         viewBox="0 0 20 20"
//                                         fill="none"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         className="change-my-color me-2"
//                                       >
//                                         <path
//                                           d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
//                                           fill="#009DE0"
//                                         />
//                                         <path
//                                           d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
//                                           fill="#009DE0"
//                                         />
//                                       </svg>
//                                     )}
//                                     {item?.product?.sourceProductId != null
//                                       ? item?.product?.quantity <=
//                                         item?.product?.outOfStockThreshold
//                                         ? "Out of Stock"
//                                         : "Add to Cart"
//                                       : item?.product?.productCap?.maxCap <=
//                                         item?.product?.productCap?.todaySold
//                                       ? "Out of Stock"
//                                       : "Add to Cart"}
//                                   </button>
//                                 </div>
//                               );
//                             })}
//                           </OwlCarousel>
//                         </div>
//                       ) : (
//                         <h6>No posts to show</h6>
//                       )}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <wishlist
//           selectedcheck={selectedcheck}
//         /> */}
//       <Location show12={show12} setShow12={setShow12} />
//     </>
//   );
// };

// export default Seller;

import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./seller.scss";
import OwlCarousel from "react-owl-carousel";
import process from "process/browser";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Environment from "../../utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import imgliketransparent from "../../../assets/like-transparent.svg";
import imglikeblue from "../../../assets/like-blue.svg";
import { useDispatch } from "react-redux";
import { cartItems } from "../../../redux/action/index";
import Location from "../../Location/Location";
import Related from "../../RelatedProduct/Related";
import { Spinner } from "react-bootstrap";
import * as $ from "jquery"

const Seller = ({ wishlist, setWishlist, wishCount, setWishCount }) => {
  const val = localStorage.getItem("accessToken");

  

  // $('.owl-nav button.owl-next').onClick(event => console.log("eal::",event))

  const dispatch = useDispatch();
  const [startposition, setstartposition] = useState(0);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filtercategories, setFilterCategories] = useState([]);
  const [filterbestseller, setFilterBestSeller] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [post, setPost] = useState([]);

  // setIsShown((current) => !current);
  const [cls, setcls] = useState(false);
  const [tempCartAmount, setTempCartAmount] = useState(0);

  // const handleClick = (data) => {
  //   let val = localStorage.getItem("wishlist");
  //   let temp = [];

  //   if (val !== "null" || val !== "undefined") {
  //     val = JSON.parse(val);
  //     temp = [];
  //   }
  //   temp = [...temp];

  //   let existingItem = temp.some((temp) => temp.productId == data.productId);

  //   if (existingItem === false && val) {
  //     for (let i = 0; i < temp?.length; i++)
  //     setcls(true);
  //     localStorage.removeItem("wishlist");

  //   } else if (existingItem === false) {
  //     setcls(false);
  //     temp.push(data);
  //     localStorage.setItem("wishlist", JSON.stringify(temp));
  //   }
  //   setWishCount(temp.length);

  // };

  const AddtoWishList = (data) => {
    data.wish = 1;
    let temp = [];
    if (localStorage.getItem("wishlist") == null) {
      temp = [];
    } else {
      temp = JSON.parse(localStorage.getItem("wishlist"));
    }

    let existingItem = temp.find((item) => item?.id == data?.id);
    if (existingItem) {
      setcls(true);
      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?.wish % 2 == 1) {
          temp.splice(i, 1);
          localStorage.removeItem("wishlist");
          localStorage.setItem("wishlist", JSON.stringify(temp));
        }
      }
    } else {
      setcls(false);
      temp.push(data);
      localStorage.setItem("wishlist", JSON.stringify(temp));
    }

    setWishlist(temp);

    setWishCount(temp.length);
  };

  useEffect(() => {
    const data = localStorage.getItem("wishlist");
    if (data) {
      const wishlistItems = JSON.parse(data);
      const existingItem = wishlistItems.find((item) => item.id === data.id);
      if (existingItem) {
        setcls(true);
      } else {
        setcls(false);
      }
    }
  }, []);

  const owl_option = {
    nav: true,
    dots: false,
    dotsEach: false,
    loop: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-caret-left'></i>",
      "<i class='fas fa-caret-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      361: {
        items: 1.5,
        loop: true,
        margin: 15,
      },
      600: {
        items: 1,
        margin: 15,
      },
      700: {
        items: 2.5,
        margin: 15,
        loop: true,
      },
      1000: {
        items: 3.5,
        margin: 15,
        loop: true,
      },
      1200: {
        items: 6,
        margin: 15,
      },
      1900: {
        items: 6,
        margin: 15,
      },
    },
  };
  const getBestSeller = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${
          Environment.base_url
        }/store/products/best-seller?offset=1&&limit=6&${
          filterbestseller
            ? "filter=productCategoryId=in:[" + filterbestseller + "]"
            : ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);

        // let items = res?.data?.data?.products;

        // for (let i = 0; i < items?.length; i++) {
        //   items[i].product.cartAmount = 0;
        // }

        // // productDetail.cartAmount = productDetail.cartAmount + 1;

        // setPosts(res?.data?.data?.products);
         setPosts(res?.data?.data?.products);
      })
      .catch((err) => {});
    setIsLoading(false);
  };
  useEffect(() => {
    getBestSeller();
  }, [filterbestseller]);

  const getSellerCategories = async () => {
    await axios
      .get(
        `${Environment.base_url}/store/categories?parentOnly=true&afterElement=0&limit=25&allCategories=false`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setCategories(res?.data?.data);
      })
      .catch((err) => {});
  };
  // useEffect(() => {
  //   getSellerCategories();
  // }, []);

  const FilterCategories = async (id) => {
    await axios
      .get(
        `${Environment.base_url}/store/categories/${id}/products/best-seller?limit=5&offset=5`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setFilterCategories(res?.data?.data);
      })
      .catch((err) => {});
  };

  // dropdown value show category
  // const [selectedcheck, setSelectedCheck] = useState([]);
  // const [rend, setRend] = useState(false)

  // const AddCategory = (e, item) => {
  //     let dumArr = selectedcheck;
  //     if (e.target.checked === true) {
  //         dumArr.push(item);
  //     }
  //     else if (e.target.checked === false) {
  //         // dumArr.pop();
  //     }
  //     setSelectedCheck(dumArr);
  //     setRend(!rend);
  // }
  const [show12, setShow12] = useState(false);

  // const handleCartItem = (productToAdd) => {
  //   productToAdd.cartAmount = 1;
  //   const temp = [productToAdd];
  //   temp.push(1);
  //   dispatch(cartItems(temp));
  //   increase(productToAdd);
  //   // getCartItems();
  //   setUpdate(update+1)
  // };

  const getCartItems = () => {
    let tempCart = JSON.parse(localStorage.getItem("cartItems"));
    setTempCartAmount(tempCart);
  };

  const showAllCategories = () => {
    setFilterBestSeller(null);
    getBestSeller(null);
  };

  const getBanner = async () => {
    setPost([]);
    await axios
      .get(
        `${Environment.base_url2}/banners?offset=1&&limit=30`,

        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )

      .then((res) => {
        setPost(res?.data?.data?.banners);
      })
      .catch((err) => {});
  };

  const banner3 = post.filter(
    (data) => data?.type === "main banner 3" && data?.isPublish === true
  );

  // useEffect(() => {
  //   getBanner();
  // }, []);



  const [rend, setRend] = useState(false);


  const handleCartItem = (productToAdd, index) => {
    setstartposition(index)
    setPosts([]);
    let dumArr = posts;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.product?.id !== count?.product?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    setPosts(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = quantityAmount;
    let temp = [productToAdd?.product];
    if(temp?.cartAmount) {

    } else {
      temp[0].cartAmount = count.quantityAmount;
    }
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update+1)
  };

  const increase = (productToAdd, index) => {
 setstartposition(index)
    setPosts([]);
    let dumArr = posts;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.product?.id !== count?.product?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    setPosts(dumArr);
    // setOpen(true);
    // setIndx(index);
    
    productToAdd.cartAmount = productToAdd.cartAmount+1;
    let temp = [productToAdd?.product];
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update+1)
  };

  const decrease = (productToAdd, index) => {
 setstartposition(index)
    setPosts([]);
    let dumArr = posts;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.product?.id !== count?.product?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount - 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    setPosts(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = productToAdd.cartAmount-1;
    let temp = [productToAdd?.product];
    temp[1]= -1
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update+1)

  };



  // const onDragged = (object) =>{
  //   // setstartposition(object.item.index )
  //   if(object.item.index != startposition){ setstartposition(object.item.index)}
  // }

//   $(".owl-next").on("click", function(event){
//     console.log('before',startposition)
//     // setstartposition(startposition + 1)
//     // console.log('after',startposition)
//  });

  return (
    <>
      <section  className="seller">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              {/* <div className="row">
                <div className="col-xl-12 p-0">
                  <div className="banner">
                    <img src={banner3[0]?.imageUrl} alt="" />
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-xl-12 col-12 p-0">
                  <div className="parent">
                    <div className="main-heading">
                      <h4>
                        Best Seller
                        <Link to="/all-categories">
                          View All{" "}
                          <img
                            src="\assets\btn-icons\arrow-right.svg"
                            alt="img"
                            className="img-fluid ml-2"
                          />
                        </Link>
                      </h4>
                    </div>
                    {/* <div className="social-tags">
                      <a onClick={() => showAllCategories()}>All</a>
                      {categories?.map((data, index) => {
                        return (
                          <a
                            onClick={(e) => {
                              FilterCategories(data?.id);
                              setFilterBestSeller(data?.id);
                            }}
                          >
                            {data?.name}
                          </a>
                        );
                      })}
                    </div> */}
                  </div>
                  <div>
                    <div class="owl_option">
                      <OwlCarousel id = "waleed" className="owl-theme"  startPosition={startposition} key={update} {...owl_option}>
                        {posts?.map((item, index) => {
                          return (
                            <>
                              <div key={index} className="card-topsaver">
                                <div className="top-abs">
                                  <span className="off">New</span>
                                  {/* <div className="zzzzzzzzzzz">
                                    <img
                                      src={
                                        cls ? imglikeblue : imgliketransparent
                                      }
                                      alt="img"
                                      className="img-fluid "
                                    />
                                  </div> */}
                                </div>
                                <div className="inner-img">
                                  {item?.quantityAmount  ? (
                                    <div className="btn-add add-counter">
                                      <a onClick={() => decrease(item, index)}>
                                        <img
                                          src="\assets\minus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                      <span>{item?.quantityAmount}</span>
                                      <a
                                          onClick={() => {
                                            if (
                                              item.quantityAmount !==
                                              item?.product?.quantity-item?.product?.outOfStockThreshold
                                            ) {
                                              increase(item, index);
                                            }
                                          }}
                                      >
                                        <img
                                          src="\assets\plus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  ) : (
                                    <a
                                      onClick={() =>
                                        handleCartItem(item, index)
                                      }
                                      className="btn-add pulse-animation"
                                    >
                                      <img
                                        src="\assets\btn-add.png"
                                        alt="img"
                                        className="img-fluid"
                                        style={{
                                          width: "38px",
                                          height: "38px",
                                        }}
                                      />
                                    </a>
                                  )}
                                <Link to={"/product-detail?id=" + item?.product?.id}>
                                 
                                  <img
                                    src={item?.product?.imageUrl ? item?.product?.imageUrl: "assets\Frame 186 (1).png"}
                                    alt="img"
                                    className="img-fluid"
                                  /></Link>
                                </div>
                                <Link to={"/product-detail?id=" + item?.product?.id}>
                                <div className="bottom-text">
                                  <span> {item?.product?.category[0]?.name}</span>
                                  <h5> {item?.product?.name}</h5>
                                  {item?.product?.discountedProducts[0]?.discount ?
                                 <div className="d-flex">
                                 <del style={{ color: "red" }}>
                                       <h6 className="disamount">Rs.{item?.product?.price}</h6>
                                     </del>
                                     <h6 className="dis-price">
                                       Rs.{Math.trunc(
                                         item?.product?.price -
                                           item?.product?.price *
                                             (item?.product?.discountedProducts[0]?.discount  / 100)
                                       )}
                                     </h6>
                                     </div>
                                  :
                                  <h6>Rs. {item?.product?.price}</h6>
                                  }
                                </div>
                                </Link>
                              </div>
                            </>
                          );
                        })}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 p-0 my-5">
                  <div className="banner m-sm-0">
                    <img
                      src="\assets\dummy-imgs\fastestdelievryintown.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <wishlist
          selectedcheck={selectedcheck}
        /> */}
      <Location show12={show12} setShow12={setShow12} />
    </>
  );
};

export default Seller;
