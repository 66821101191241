import React, { useEffect } from 'react'
import Navbar from '../landing/header/Navbar'
import "./terms.scss"
import { Link } from 'react-router-dom/cjs/react-router-dom'
const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
            <Link to="/">
                <li class="breadcrumb-item">
                  <a href="#">Home /</a>
                </li>
              </Link>
              <li class="breadcrumb-item">
                <a href="#">Terms & condition</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className='main-term'>
        <div className='container-fluid p-0'>
          <div className='row'>
            <div className='col-xl-11 m-auto'>
              <Link to='/'>
                <div className='backbtn'>
                  <a>Back</a>
                </div>
              </Link>
              <div className='general-heading'>
                <h2>Terms & condition</h2>
                <h4>WELCOME TO THE QUICK SHOP</h4>
                <p>These terms and conditions outline the rules and regulations for the use of the Quick Grocery Delivery Website, located at<Link to="/"> quick.shop</Link></p>
                <p>The following terminology applies to these Terms & Conditions, Privacy Statement & Disclaimer Notice, and all Agreements:</p>
                <p>"Client", "You" and "Your" refers to you, the person who logs on to this website and is compliant with the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves.</p>
                <p>All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services, in accordance with and subject to, prevailing laws of Pakistan.</p>
                <p>Any use of the above terminologies or other words in the singular, plural, capitalization, and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</p>
                <h4>COOKIES</h4>
                <p>We employ the use of cookies. By accessing Quick Shop, you agreed to use cookies in agreement with the Quick Grocery Delivery Website’s Privacy Policy.</p>
                <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                <h4>LICENSE</h4>
                <p>Unless otherwise stated, the Quick Grocery Delivery website and/or its licensors own the intellectual property rights for all material on Quick Shop. All intellectual property rights are reserved. You may access this from Quick Shop for your own personal use subject to restrictions set in these terms and conditions.</p>
                <p>You must not:</p>
                <p className='side-spacing'>1: Republish material from Quick Shop</p>
                <p className='side-spacing'>2: Sell, rent, or sub-license material from Quick Shop</p>
                <p className='side-spacing'>3: Reproduce, duplicate, or copy material from Quick Shop</p>
                <p className='side-spacing'>4: Redistribute content from Quick Shop</p>
                <p>You hereby grant Quick Grocery Delivery Website a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your comments in any and all forms, formats, or media.</p>
                <p className='hyperlink'>HYPERLINKING TO OUR CONTENT</p>
                <p className='font-bold'>The following organizations may link to our Website without prior written approval:</p>
                <p className='side-spacing'>1: Government agencies;</p>
                <p className='side-spacing'>2: Search engines;</p>
                <p className='side-spacing'>3: News organizations;</p>
                <p className='side-spacing'>4: Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</p>
                <p className='side-spacing'>5: Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups that may not hyperlink to our Web site.</p>
                <p>These organizations may link to our home page, to publications, or to another Website information so long as the link: </p>
                <p>(a) is not in any way deceptive; </p>
                <p>(b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and </p>
                <p>(c) fits within the context of the linking party’s site.</p>
                <p className='font-bold'>We may consider and approve other link requests from the following types of organizations:</p>
                <p className='side-spacing'>1: commonly-known consumer and/or business information sources;</p>
                <p className='side-spacing'>2: dot.com community sites;</p>
                <p className='side-spacing'>3: associations or other groups representing charities;</p>
                <p className='side-spacing'>4: online directory distributors;</p>
                <p className='side-spacing'>5: internet portals;</p>
                <p className='side-spacing'>6: accounting, law, and consulting firms; and</p>
                <p className='side-spacing'>7: educational institutions and trade associations.</p>
                <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Quick Grocery Delivery Website; and (d) the link is in the context of general resource information.</p>
                <p>If you are one of the organizations listed above and are interested in linking to our website, you must inform us by sending an e-mail to the Quick Grocery Delivery Website. Please include your name, your organization name, and contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
                <p className='font-bold'>Approved organizations may hyperlink to our Website as follows:</p>
                <p className='side-spacing'>1: By use of our corporate name; or</p>
                <p className='side-spacing'>2: By use of the uniform resource locator being linked to; or</p>
                <p className='side-spacing'>3: By use of any other description of our Website being linked to, that makes sense within the context and format of content on the linking party’s site.</p>
                <p className='font-bold'>No use of the Quick Grocery Delivery website’s logo or other artwork will be allowed for linking absent trademark license agreements.</p>
                <p className='hyperlink'>IFRAMES</p>
                <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
                <p className='hyperlink'>CONTENT LIABILITY</p>
                <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third-party rights.</p>
                <h4>YOUR PRIVACY</h4>
                <p className='font-bold'>Please read the Privacy Policy</p>
                <p className='hyperlink'>RESERVATION OF RIGHTS</p>
                <p>We reserve the right to request that you remove all links or any particular link to our Website.</p>
                <p>You approve removing all links to our Website upon request immediately. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
                <p className='hyperlink'>REMOVAL OF LINKS FROM OUR WEBSITE</p>
                <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
                <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
                <h4>DISCLAIMER</h4>
                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                <p className='side-spacing'>1: limit or exclude our or your liability for death or personal injury;</p>
                <p className='side-spacing'>2: limit or exclude our or your liability for fraud or fraudulent misrepresentation;</p>
                <p className='side-spacing'>3: limit any of our or your liabilities in any way that is not permitted under applicable law; or</p>
                <p className='side-spacing'>4: exclude any of our or your liabilities that may not be excluded under applicable law.</p>
                <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.</p>
                <p>As long as the website, the information, and the services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
              </div>


              {/* <div className='general-heading'>
                <h3>Products & Prices</h3>
                <p>All products as depicted on this website (which may vary from time to time) are available for delivery to the UK mainland, unless stated otherwise. Certain product ranges are available for delivery in selected delivery areas only, as outlined on our delivery page.</p>
                <p>We are unable to offer ‘finishing touches’ to products ordered from selected ranges. If this would be the case, it will be made clear when placing your order.</p>
                <p>The product images shown on the website are intended for presentation purposes only. Gifts and their contents are configured for safe and secure transit using the appropriate packaging methods. All our Grocery gifts are packed in way to esnure grocery contents are secure to avoid damage and bruises while in transit.</p>
                <p>The Packed weights given are approximate only and packaging may vary from those shown depending upon availability.</p>
                <p>Our all products are available for same-day delivery. Further information regarding product availability and delivery options will be available when placing your order.</p>
                <p>All products are subject to availability. In the event of any supply difficulties, we reserve the right to substitute your chosen item with a suitable alternative product of equivalent value and quality without notification. Should we be unable to supply all, or part of, your order (the product, or any suitable substitute product), we will notify you as soon as possible and will reimburse your payment in full no later than 30 days after the intended delivery date.</p>
                <p>Prices listed on<span> Quick.shop</span> are valid for a maximum of 14 days after submitting your purchase form.</p>
                <p>All of our prices are subject to change and we may change our prices or offers without any prior notification. </p>
              </div>
              <div className='general-heading'>
                <h3>Orders & Payment</h3>
                <p>We give clear instructions to assist you throughout the ordering process. Along with your payment details, you are required to provide us with personal details including an accurate postcode and a telephone number, as well as contact details for the intended recipient, including their full name, address (including a postcode) and telephone number.</p>
                <p>We accept payment by all major credit and debit cards, as displayed on our homepage.</p>
                <p>We cannot accept orders from customers under the age of 18.</p>
                <p>By clicking on the 'checkout' button at the end of the order process, you are consenting to be bound by our terms and conditions </p>
                <p>For all telephone orders placed through our Customer Services team, it is the customer’s responsibility to check the order details and product information before the order is dispatched. </p>
                <p>Submitting your order is subject to our acceptance of the transaction. To prevent fraudulent orders and credit card identity frauds, the customer’s name and address are matched against the payment card details. We reserve the right to cancel your order or refuse to deliver without prior notification, based on unsatisfactory customer details or recipient details. It is the customer’s responsibility to make sure that the payment card used is registered to the customer’s name and postal address.</p>
                <p>On receipt of your order, we will send you an initial email confirming your order details. We will not consider ourselves bound by a contract with you until we have issued this email processing confirmation.</p>
              </div>
              <div className='general-heading'>
                <h3>Cancellations & Amendments</h3>
                <p>Should you need to cancel or make any changes to your order, please advise us at least 48 hours before the dispatch date by contacting our Customer Services team, quoting your order number and delivery date. Amendments to your order are not possible once your item has been dispatched.</p>
              </div>
              <div className='general-heading'>
                <h3>Returns & Refunds</h3>
                <p>We are able to exchange a non-perishable gift for an alternative product within seven days of delivery. All postage costs in such cases are the responsibility of the customer. Additional administration costs may apply. </p>
                <p>You may return a non-perishable order for a refund at any time without reason, within seven working days of delivery. Should you wish to cancel your order and receive a refund, you must confirm this in writing within seven working days of receipt and return the packaged item to us.  The item(s) must be returned to us unused and in as new condition. You will be liable for all postage costs incurred as a result of returning an item to us. We cannot issue a refund until we have received the goods. We recommend you obtain proof of posting when </p>
                <p>you return any goods to us. Please note this policy excludes all food products as these are perishable goods and exempt from this legislation. Perishable gifts. Where in exceptional circumstances we have agreed to the return of non damaged or non faulty perishable gift a re-stocking fee of not less than 40% but not more than 90% of the original cost will apply and be deducted from any refund.</p>
              </div>
              <div className='general-heading'>
                <h3>Refunds for Late Delivery of Orders</h3>
                <p>We regret that we are unable to refund orders sent which arrive late. </p>
                <p>We make every effort to ensure delivery of your order on your requested date. If a delivery attempt has not been made by your chosen Date on the specified day where you have paid for Next Day Gauranteed Delivery, we will arrange redelivery of your parcel the following working day, where possible, and you will be entitled to refund for the delivery charges. </p>
                <p>Please note that our Delivery staff do their best to make all deliveries on the day you requested but factors including peak trading periods at any Event such as, Christmas, Mother's day, Valentines day plus vehicle breakdowns, accidents, roadworks and adverse weather conditions can affect delivery times. </p>
                <p>We will not be held responsible for items that are delayed or not delivered due to incorrect or incomplete details of recipient.</p>
              </div>
              <div className='orders-heading'>
                <h3>Non-Delivery of Orders</h3>
                <p>Quick Grocery Shop does not accept any responsibility for any loss, damage, delay or other disruption in supply and deliveries brought about as a result of any of the following: </p>
                <p className='orders'>1. Inaccurate or incomplete delivery address details</p>
                <p  className='orders'>2. Delayed payment (cleared funds)</p>
                <p  className='orders'>3. Delayed order receipt</p>
                <p>It is the customer’s responsibility to ensure that all order and delivery details are correct at least two working days prior to dispatch or before the order is processed.</p>
                <p>Quick Grocery Shop reserves the right to charge the customer an additional fee for carriage, handling charges or unusable content in the event of a failed delivery caused by the supply of an incorrect or incomplete delivery address by the customer.</p>
                <p>Any claim for non-delivery of an order must be made in writing by the customer to Quick Grocery Shop’s Customer Services team within 10 days of the intended delivery date.</p>
                <p>Quick Grocery Shop reserves the right to impose a surcharge retrospectively for any balance arising from the selection of an incorrect delivery Area. If you are in any doubt as to the correct delivery option for your chosen destination, please contact our Customer Services team for advice.</p>
                <p>Please note that our delivery guarantee is limited to delivering to the building, not individual recipients. The signature of any person authorized to accept the delivery on behalf of the recipient shall be accepted as proof of delivery. Quick Grocery Shop will consider the order delivered and we will not accept any liability of the subsequent non-delivery of your item.
                </p>
                <p>For further delivery information, please contact us on our Customer Services </p>
              </div>
              <div className='complaints-heading'>
                <h3>Complaints </h3>
                <p>At Quick Grocery Shop, we are fully committed to providing you with the best-quality products, ensuring they reach their intended recipient in their optimum condition. If, for any reason, you are not fully satisfied with the quality of the product, or the delivered item is damaged or faulty, please inform us within 24 hours of receipt. Complaints concerning perishable products such as Fruits, Vegetables, Meat, Frozen Food cannot be investigated if made later than 24 hours following the delivery date (under these circumstances, Quick Grocery Shop will not held be responsible for refunds and the products will be non-returnable). </p>
                <p className='complain'>Any complaints should be addressed to:</p>
                <p className='complain'>Email: Info@quick.shop</p>
                <p className='complain'>Tel: 0300 5668000</p>
                <p>Quick Grocery Shop will investigate every complaint and will endeavor to respond within five working days from the date that the complaint is made. We will keep you updated regarding the progress of your complaint and will welcome your feedback. You can contact us at any time throughout the process by email or by telephone. </p>
                <p>If, after investigation, we are satisfied that your complaint is genuine, we will be happy to replace the product or issue a full refund.</p>
              </div>
              <div className='general-heading'>
                <h3>My Account Service </h3>
                <p>Quick Grocery Shop provides the My Account service as a complimentary feature for your personal use. It is intended to assist with your purchases made via our website</p>
                <p>The My Account Service is subject to the following Terms and Conditions:</p>
                <p>In order to provide the My Account service, we hold certain personal information. Except to the extent that we are required or permitted by law, any personal information which we collect will be used solely for the purposes of providing the service.</p>
                <p>To register with the service, you must be over 18 years-old.
                </p>
                <p>On registering, you will be required to create a password. Please keep this password confidential and do not disclose it to anyone. You will be responsible for all activities and orders that are made using your password. If you know or suspect that someone else knows your password, please notify us immediately. </p>
                <p>Should you forget your password, please use the password reminder service to retrieve it. This option can be found adjacent to the My Account login box.</p>
                <p>Please ensure all details that you provide on registration, or at any time thereafter, are correct and complete. It is the customer’s responsibility to make sure that the details that we have for you are accurate and up-to-date. You can update your details at any time in the 'Edit Profile' section of the My Account area on our website or by contacting our Customer Services team. </p>
                <p>The My Account Service must not be used for the following purposes: </p>
                <p>Disseminating any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene or otherwise objectionable material. Gaining unauthorised access to other computer systems.</p>
                <p>Interfering with any other person's use or enjoyment of our website.</p>
                <p>Whilst we take every reasonable precaution and care in relation to the My Account service, we do not make any representation or warranties of any kind (either express or implied) with respect to the contents or operation of this service, and any such representations and warranties are excluded by this notice. We do not warrant that use of the service will be uninterrupted or error free. Whilst every effort is made to ensure reminder emails set up on your account are processed and delivered as requested, we cannot be held responsible for non-delivery of these messages. Your use of the My Account Service is done so entirely at your own risk and we do not accept any liability whatsoever for losses or damages that you may suffer as a result of your use of the My Account Service. </p>
                <p>Your access to our website and My Account service may be occasionally restricted to allow for routine maintenance of the website.</p>
                <p>If Quick Grocery Shop has reason to believe a breach of security or misuse of the service or website is likely, we may require you to change your password or suspend use of the service. </p>
              </div>
              <div className='general-heading'>
                <h3>Intellectual Property</h3>
                <p>All other rights, including copyright of this website are owned by Gift Direct Ltd. Any use of this website or its contents, including copying and/or storing them in whole or part, other than for your own personal, non-commercial use, is strictly prohibited. You may not modify, distribute or repost something on this website for any purpose.</p>
              </div>
              <div className='general-heading'>
                <h3>Disclaimer</h3>
                <p>Whilst we agree to use all reasonable endeavors to ensure that our website www.quick.shop is fully operational and error-free, we cannot guarantee this and, therefore, accept no responsibility for any defects and/or interruption of the website or our service. We shall therefore be released from our obligations under these Terms and Conditions in the event of any cause beyond our reasonable control which renders the provision of our website, impossible or impractical.</p>
                <p>Within the website you may find Products, inks or references to third party materials and third-party web sites outside the website. We accept no liability of any description in respect of the contents of such materials or websites. We should not be taken to be endorsing, publishing, permitting or authorizing such websites or materials</p>
              </div>
              <div className='general-heading'>
                <h3>OUR LIABILITY</h3>
                <p>a) We will not be liable for any loss you suffer through failure to use the Products in a normal and proper manner or through altering or modifying the Products, failure to follow any guidelines or using the Products in a careless or negligent manner.</p>
                <p>b) Our liability to you for death or personal injury caused by our negligence is not limited.</p>
                <p>c) Our liability to you other than for death or personal injury caused by our negligence is limited to the cost of replacement of the Products or refund of the price paid for the Products.</p>
                <p>d)  We will not be responsible for:</p>
                <p>1) any other loss you suffer through our failure to comply with the terms of this contract. In particular we will not have any liability for any loss that was not a reasonably foreseeable consequence of our failure to comply with the contract such as any loss of profit, opportunity or liability to any third party; or</p>
                <p>2) any failure to deliver Products or delay due to any event or circumstance beyond our reasonable control.</p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Terms