import React, { useState, useEffect } from "react";
import "./wishlist.scss";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "react-bootstrap/Table";
import Navbar from "../landing/header/Navbar";

const Wishlist = () => {
  const [wishlist, setWishlist] = useState(
    JSON.parse(localStorage.getItem("wishlist"))
  );
  const [wishCount, setWishCount] = useState(0);

  //   useEffect(() => {
  //     let val = localStorage.getItem("wishlist");
  //     if (val !== "null" || val !== "undefined") {
  //       const dataFromLocalStorage = JSON.parse(val);
  //       setList(dataFromLocalStorage);
  //     }
  //   }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // function removeItemFromLocalStorage(key) {
  //   localStorage.removeItem("wishlist");
  //   window.location.reload();
  // }

  const removeItemFromLocalStorage = (val) => {
    let wishProduct = [...wishlist];
    setWishCount(wishProduct?.length - 1);
    wishProduct = wishProduct.filter((item) => item?.id != val?.id);
    setWishlist(wishProduct);
    localStorage.setItem("wishlist", JSON.stringify(wishProduct));
  };

  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      {wishlist?.length > 0 ? (
        <div className="wish"></div>
      ) : (
        <h4 className="wish">Wishlist is empty</h4>
      )}

      <section className="tap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 m-auto p-o">
              <div className="table">
                <Table>
                  <thead className="head">
                    <tr>
                      <th>Product name</th>
                      <th className="unit-price">Unit price</th>
                      <th>Stock status</th>
                      <th></th>
                    </tr>
                  </thead>
                  {wishlist?.map((item, index) => {
                    return (
                      <tr>
                        <td className="d-flex">
                          <img
                            onClick={() => removeItemFromLocalStorage(item)}
                            src="\assets\trash.svg"
                          />
                          <div className="product-img-main d-flex ">
                            <img
                              className="product-img"
                              src={item?.product?.imageUrl}
                            />
                          </div>
                          <div className="d-block product-text-main">
                            <p>{item?.product?.name}</p>
                            <p className="product-text">
                              {item?.product?.category?.name}
                            </p>
                          </div>
                        </td>
                        <td className="price">Rs.{item?.product?.price}</td>
                        <td></td>
                        <td>
                          {" "}
                          <button
                            className="cart-btn"
                            // onClick={() => handleCartItem(item?.product)}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="change-my-color me-2"
                            >
                              <path
                                d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
                                fill="#009DE0"
                              />
                              <path
                                d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
                                fill="#009DE0"
                              />
                            </svg>
                            Add to Cart
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wishlist;
