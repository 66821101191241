import React, { useEffect } from 'react'
import Navbar from '../landing/header/Navbar'
import "./careers.scss"
import { Link } from '@mui/material'
const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className="bread-crumb">
                <div className="bread">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="#">FAQS</a>
                            </li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section className='main-term'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-12 p-0 m-auto'>
                            {/* <div className='backbtn'>
                                <a>Back</a>
                            </div> */}
                            <div className='careers-main'>
                                <div className='career-sub'>
                                    <div className='row'>
                                        <div className='col-xl-1'></div>
                                        <div className='col-xl-7'>
                                            <h4 className='career'>Career</h4>
                                            <p className='career-text'>Join our team and become part of a dynamic and fast-paced work environment!</p>
                                            <a href='mailto:careers@quick.shop'>
                                            <p className='career-text2'>info@quick.shop</p></a>
                                        </div>
                                        <div className='col-xl-4 p-0'>
                                            <img className='img-fluid career-img' src='Assests\career.png'></img>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    <div className='col-xl-2'></div>
                                    <div className='col-xl-8'>
                                        <div className='career-jobs'>
                                            <div className='career-job-card'>
                                            <p className='top-card-text'>hot</p>
                                                <h6 className='career-job-text'>General Manager</h6>
                                                <div>
                                                    <button className='btn-detail'>Details</button>
                                                    <button className='btn-apply'>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='career-jobs-second'>
                                            <div className='career-job-card'>
                                                <h6 className='career-job-text'>Sales Man</h6>
                                                <div>
                                                    <button className='btn-detail'>Details</button>
                                                    <button className='btn-apply'>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='career-jobs-second'>
                                            <div className='career-job-card'>
                                                <h6 className='career-job-text'>Cashier/Accountant</h6>
                                                <div>
                                                    <button className='btn-detail'>Details</button>
                                                    <button className='btn-apply'>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='career-jobs-second'>
                                            <div className='career-job-card'>
                                                <h6 className='career-job-text'>Book keeper</h6>
                                                <div>
                                                    <button className='btn-detail'>Details</button>
                                                    <button className='btn-apply'>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2'></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Careers
