import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import "./faqs.scss";

const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <Link to="/">
                <li class="breadcrumb-item">
                  <a href="#">Home /</a>
                </li>
              </Link>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="main-faqs faqs1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-11 m-auto p-0">
                  <div className="faqshead">
                    <h2>FAQs</h2>
                  </div>
                  <div className="main-parent">
                    <div className="info">
                      <Link to="/faqs">
                        <h3>ORDER INFORMATION</h3>
                      </Link>
                      <Link to="/faqs1">
                        {" "}
                        <h4>Shipping</h4>
                      </Link>
                      <Link to="/faqs2">
                        {" "}
                        <h5>Returns & Cancellation</h5>
                      </Link>
                      <p>Need more help?</p>
                      <Link to="/contactus">
                        {" "}
                        <button>Contact Us</button>
                      </Link>
                    </div>

                    <div className="head1">
                      <h2>What is my order status?</h2>
                      <p>
                        You can view your order status by looking up your order.
                      </p>
                      <div className="brdr"></div>
                      <h2>What payments do you accept?</h2>
                      <p>
                        We accept Visa, MasterCard, Discover, American Express
                        and check cards or ATM cards, as long as they are
                        connected with one of the major credit card companies
                        listed above. You can safely enter your entire credit
                        card number via our secure server, which encrypts all
                        submitted information.
                      </p>
                      <div className="brdr"></div>
                      <h2>What is PayPal Credit?</h2>
                      <p>
                        Checkout fast with PayPal Credit. The effortless way to
                        pay without using your credit card. Simply select PayPal
                        Credit at checkout, answer two simple questions and
                        accept the terms. It's that easy. There is no separate
                        application process. PayPal Credit offers flexible terms
                        that allow you to choose to pay in full or over time.
                        Subject to credit approval. See Terms.
                      </p>
                      <div className="brdr"></div>
                      <h2>How do I enter a shipping address?</h2>
                      <p>
                        A shipping information page will be presented where you
                        can enter a separate shipping address and the shipping
                        method can be chosen.
                      </p>
                      <div className="brdr"></div>
                      <h2>
                        Should I put spaces or dashes in the credit card number?
                      </h2>
                      <p>
                        Your card number should be entered in as a continuous
                        string of numbers.
                      </p>
                      <div className="brdr-mbl"></div>
                    </div>

                    <div className="head">
                      <h2>Why are you not accepting my credit card?</h2>
                      <p>
                        There are many reasons for a failed credit card
                        transaction. Your card may have expired or reached its
                        limit or a credit card computer, either on our end or
                        your bank's end, may have encountered a machine error.{" "}
                      </p>
                      <div className="brdr"></div>
                      <h2>When will my credit card charged?</h2>
                      <p>
                        We will not bill you until your product(s), including
                        backordered or preordered items, are actually shipped.
                        If your items are shipped separately you will be billed
                        each time an item is shipped.
                      </p>
                      <div className="brdr"></div>
                      <h2>How will the charge show up on my credit card?</h2>
                      <p>
                        The charge will appear on your credit card as:
                        "DRI*Western Digital Online Store".
                      </p>
                      <div className="brdr"></div>
                      <h2>
                        How will you know where and how to send my physical
                        product?
                      </h2>
                      <p>
                        If you order a physical product, a Shipping Information
                        page will appear during checkout so you can enter a
                        shipping address and choose a shipping method.
                      </p>
                      <div className="brdr"></div>
                      <h2>Can I order without a credit card?</h2>
                      <p>
                        No. The only form of payment accepted is with a credit
                        card.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
