import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./topsaver.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Environment from "../../utils/environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import Location from "../../Location/Location";
import { cartItems } from "../../../redux/action/index";
import Spinner from "react-bootstrap/Spinner";

const TopSaver = ({ topSaverList,setTopSaverList }) => {
  const dispatch = useDispatch();

  const [isShown, setIsShown] = useState(false);
  const [startposition, setstartposition] = useState(0);
  const val = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("userId");
  const history = useHistory();
  const api_url = Environment.base_url;
  // const [topSaverList, setTopSaverList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [loader, setLoader] = useState(null);
  const handleClick = () => {
    setIsShown((current) => !current);
  };

  const getTopSaversList = async () => {
    setIsLoading(true);
    setLoader(true);
    const config = {
      method: "get",
      url: `${api_url}/store/products/top-saver?limit=20&offset=1`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setIsLoading(false);
        // setTopSaverList(res?.data?.data?.products);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setIsLoading(false);
        setLoader(false);
      });
  };

  // useEffect(() => {
  //   getTopSaversList();
  // }, []);
  const owl_option = {
    nav: true,
    dots: false,
    dotsEach: false,
    loop: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-caret-left'></i>",
      "<i class='fas fa-caret-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      361: {
        items: 1.5,
        loop: true,
        margin: 15,
      },
      600: {
        items: 1,
        margin: 15,
      },
      700: {
        items: 2.5,
        margin: 20,
        loop: true,
      },
      1000: {
        items: 3.5,
        margin: 20,
        loop: true,
      },
      1200: {
        items: 6,
        margin: 20,
      },
    },
  };

  const [show12, setShow12] = useState(false);
  // const handleCartItem = (productToAdd) => {
  //   const obj = productToAdd;
  //   const discId = productToAdd?.id;
  //   Object.assign(obj, { id: obj.productId });
  //   Object.assign(obj, { discountId: discId });
  //   obj.cartAmount = 1;
  //   const temp = [obj];
  //   temp.push(1);
  //   dispatch(cartItems(temp));
  //   setShow12(true);
  // };

  const [rend, setRend] = useState(false);
  const [update, setUpdate] = useState(0);
  

  const handleCartItem = (productToAdd, index) => {
    setstartposition(index)
    setTopSaverList([]);
    let dumArr = topSaverList;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
     console.log("DDDDDDDD", dumArr, count);
     setTopSaverList(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = quantityAmount;
    let temp = [productToAdd];
    if(temp?.cartAmount) {

    } else {
      temp[0].cartAmount = count.quantityAmount;
    }
    temp.push(1);
    console.log("temp in seller: ", temp);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update+1)
  };

  const increase = (productToAdd, index) => {
     setstartposition(index)
    setTopSaverList([]);
    let dumArr = topSaverList;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
     setTopSaverList(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = productToAdd.cartAmount+1;
    let temp = [productToAdd];
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update+1)
  };

  const decrease = (productToAdd, index) => {
   setstartposition(index)
    setTopSaverList([]);
    let dumArr = topSaverList;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount - 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
     setTopSaverList(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = productToAdd.cartAmount-1;
    let temp = [productToAdd];
    temp[1]= -1
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
    setUpdate(update+1)

  };

  return (
    <>
     {topSaverList?.length > 0 && (
      <section id="scroll" className="topsaver">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-12 col-12 p-0">
                  <div className="parent">
                    <div className="main-heading">
                      <h4>
                        Top Saver{" "}
                        <Link to="/all-categories?id=top-saver">
                          All Offers{" "}
                          <img
                            src="\assets\btn-icons\arrow-right.svg"
                            alt="img"
                            className="img-fluid ml-2"
                          />
                        </Link>
                      </h4>
                    </div>
                    {/* <div className="timer">
                      <p>
                        <img
                          src="\assets\social-icons\clock.svg"
                          alt="img"
                          className="img-fluid me-2 none-mobile"
                        />
                        <span className="me-1"> Expires In: </span> 16 : 21 : 06
                      </p>
                    </div>  */}
                  </div>
                  {isLoading ? (
                    <div className="text-center py-4">
                      <Spinner animation="border" variant="info" />
                    </div>
                  ) : (
                    // <div className="loader-image-landing">
                    //   <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
                    // </div>
                    <div class="owl_option">
                      {topSaverList?.length > 0 ? (
                        <OwlCarousel className="owl-theme"  startPosition={startposition} key={update} {...owl_option}>
                          {topSaverList?.map((item,index) => {
                            return (
                              <>
                                {/* {checkQuantity(item) && */}
                                <div key={index} className="card-topsavers">
                                <div className="top-abs">
                                  <span className="off">{item.discount}% OFF</span>
                                  {/* <div className="zzzzzzzzzzz">
                                    <img
                                      src={
                                        cls ? imglikeblue : imgliketransparent
                                      }
                                      alt="img"
                                      className="img-fluid "
                                    />
                                  </div> */}
                                </div>
                                <div className="inner-img">
                                  {item?.quantityAmount  ? (
                                    <div className="btn-add add-counter">
                                      <a onClick={() => decrease(item, index)}>
                                        <img
                                          src="\assets\minus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                      <span>{item?.quantityAmount}</span>
                                      <a
                                          onClick={() => {
                                            if (
                                              item.quantityAmount !==
                                              item?.product?.quantity-item?.product?.outOfStockThreshold
                                            ) {
                                              increase(item, index);
                                            }
                                          }}
                                      >
                                        <img
                                          src="\assets\plus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  ) : (
                                    <a
                                      onClick={() =>
                                        handleCartItem(item, index)
                                      }
                                      className="btn-add pulse-animation"
                                    >
                                      <img
                                        src="\assets\btn-add.png"
                                        alt="img"
                                        className="img-fluid"
                                        style={{
                                          width: "38px",
                                          height: "38px",
                                        }}
                                      />
                                    </a>
                                  )}
                                <Link to={"/product-detail?id=" + item?.product?.id}>
                                 
                                  <img
                                    src={item?.product?.imageUrl ?item?.product?.imageUrl :"assets\Frame 186 (1).png" }
                                    alt="img"
                                    className="img-fluid"
                                  /></Link>
                                </div>
                                <Link to={"/product-detail?id=" + item?.product?.id}>
                                <div className="bottom-text">
                                  <span> {item?.product?.category[0]?.name}</span>
                                  <h5> {item?.product?.name}</h5>
                                  <div className="d-flex">
                                  <del style={{ color: "red" }}>
                                        <h6>Rs.{item?.product?.price}</h6>
                                      </del>
                                      <h6 className="dis-price">
                                        Rs.{Math.trunc(
                                          item?.product?.price -
                                            item?.product?.price *
                                              (item?.discount / 100)
                                        )}
                                      </h6>
                                      </div>
                                </div>
                                </Link>
                              </div>
                                {/* } */}
                              </>
                            );
                          })}
                        </OwlCarousel>
                      ) : (
                        <h6>No posts to show</h6>
                      )}
                    </div>
                  )}
                </div>
                {/* <div className="col-xl-3 col-12 padd-sm h-100"> */}
                {/* <div className="discount-card"> */}
                {/* <div className="top-content"> */}
                {/* <h2>Sign up to get exclusive discounts! </h2> */}
                {/* <p>For new member sign up at the first time</p> */}
              </div>
              {/* <div className="option-fields"> */}
              {/* <input type="text" placeholder="Username" /> */}
              {/* <input type="text" placeholder="Email Address" /> */}
              {/* <input type="text" placeholder="Password" /> */}
              {/* </div> */}
              {/* <div className="btn-bottom"> */}
              {/* <Link to="/login"> */}
              {/* <button className="common-btn">Register Now</button> */}
              {/* </Link> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    )}
      <Location show12={show12} setShow12={setShow12} />
    </>
  );
};

export default TopSaver;
