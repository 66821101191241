import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import "./faqs.scss";

const Faqs1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
            <Link to="/">
                <li class="breadcrumb-item">
                  <a href="#">Home /</a>
                </li>
              </Link>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="main-faqs faqs2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-11 m-auto p-0">
                  <div className="faqshead">
                    <h2>FAQs</h2>
                  </div>
                  <div className="main-parent">
                  
                      <div className="info">
                      <Link to="/faqs"><h3>ORDER INFORMATION</h3></Link>
                      <Link to="/faqs1"> <h4>Shipping</h4></Link>
                      <Link to="/faqs2">  <h5>Returns & Cancellation</h5></Link>
                        <p>Need more help?</p>
                        <Link to="/contact-us"> <button>Contact Us</button></Link>
                      </div>
                  
                      <div className="head">
                        <h2>What are Quick Grocery Store timings?</h2>
                        <p>
                        Quick Grocery Shopping is operating from 7 am to 10 pm Pakistan Standard Time.
                        </p>
                        <div className="brdr"></div>
                        <h2>
                        Does Quick Grocery Shopping provide corporate bulk groceries?
                        </h2>
                        <p>
                        Yes, Quick Grocery Shopping is dealing with corporate monthly groceries very actively. We provide bulk of groceries to corporations also providing them discounts and rewards. 
                        </p>
                      </div>
                  
                      <div className="head">
                      <div className="brdr-mbl"></div>
                        <h2>
                        Is Quick Grocery Shopping dealing with both in-store and online ordering?
                        </h2>
                        <p>
                        Yes. We have a physical store as well as an ordering process through WhatsApp and a website. 
                        </p>
                        <div className="brdr"></div>
                        {/* <h2>Can I order without a credit card?</h2>
                        <p>
                          No. The only form of payment accepted is with a credit{" "}
                          card.
                        </p> */}
                      </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs1;
