import "./navbar.scss";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import Environment from "../../utils/environment";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { locationAddressRedux } from "../../../redux/action";
import { searchItem } from "../../../redux/action";
import { searchOffSet } from "../../../redux/action";
import emptycartImg from "../../../assets/emptycartImg.svg";
import Accordion from "react-bootstrap/Accordion";
import { cartItems } from "../../../redux/action";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const Navbar = ({ locaiton, wishCount, props, setCatState, categoryData }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const hideModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);
  const locations = useLocation();
  const dispatch = useDispatch();
  const isAllCategoriesPage = locations.pathname === "/allcategories";
  const locationRedux = useSelector((state) => state.UserReducer.location);
  const cartItemRedux = useSelector((state) => state.UserReducer.cart);
  const emptyCartRedux = useSelector((state) => state.UserReducer.emptyBool);
  const [isLoading, setIsLoading] = useState(false);
  const val = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("userId");
  const [rend, setRend] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showw, setShoww] = useState(false);
  const handleClosee = () => setShoww(false);
  const handleShoww = () => setShoww(true);

  const [showcart, setShowCart] = useState(false);
  const handleCloseCart = () => setShowCart(false);
  const handleShowCart = () => setShowCart(true);

  const [showsearch, setShowSearch] = useState(false);
  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);
  const [totalCartPrice, setTotalCartPrice] = useState(0);

  const [cartCount, setCartCount] = useState(
    JSON.parse(localStorage.getItem("cartItems"))?.length
  );
  const [cartItemss, setCartItemss] = useState(
    JSON.parse(localStorage.getItem("cartItems"))
  );


  useEffect(() => {
    if (cartItemRedux?.length > 0) {
      handleAddToCart(cartItemRedux[0], cartItemRedux[1]);
    }
  }, [cartItemRedux]);

  useEffect(() => {
    if (emptyCartRedux) {
      setCartCount("0");
      setCartItemss([]);
    }
  }, [emptyCartRedux]);

  const handleAddToCart = (productToAdd, counter) => {
    let items = [];
    if (localStorage.getItem("cartItems") == null) {
      items = [];
    } else {
      items = JSON.parse(localStorage.getItem("cartItems"));
    }

    let existingItem = items.find((item) => item?.id == productToAdd?.id);
    if (existingItem) {
      for (let i = 0; i < items?.length; i++) {
        if (items[i]?.id == productToAdd?.id) {
          items[i].cartAmount += counter;
        }
      }
    } else {
      items.push(productToAdd);
    }
    // productDetail.cartAmount = productDetail.cartAmount + 1;

    setCartItemss(items);
    // items = [...items, productDetail];

    localStorage.setItem("cartItems", JSON.stringify(items));
    // localStorage.removeItem("cartItems");

    setCartCount(items.length);
  };

  const handleCartItemDelete = (val) => {
    let cartCopy = [...cartItemss];
    setCartCount(cartCopy?.length - 1);
    cartCopy = cartCopy.filter((item) => item.id != val?.id);
    setCartItemss(cartCopy);
    localStorage.setItem("cartItems", JSON.stringify(cartCopy));
    // window.location.reload();
  };

  const cartPrice = (cart) => {
    let price = 0;
    for (let i = 0; i < cart?.length; i++) {
      if (cart[i]?.discountedProducts && cart[i]?.discountedProducts[0]?.discount !== undefined) {
        price =
          Math.trunc(
            cart[i]?.price -
            cart[i]?.price * (cart[i]?.discountedProducts[0]?.discount / 100)
          ) *
          cart[i]?.cartAmount +
          price;
      } else if (cart[i]?.discount !== undefined) {
        price =
          Math.trunc(
            cart[i]?.product?.price -
            cart[i]?.product?.price * (cart[i]?.discount / 100)
          ) *
          cart[i]?.cartAmount +
          price;
      } else {
        price = cart[i]?.price * cart[i]?.cartAmount + price;
      }
    }
    setTotalCartPrice(price);
  };


  useEffect(() => {
    cartPrice(cartItemss);
  }, [cartItemss]);

  const [allcategory, setAllCategory] = useState([]);
  const [shopcategory, setShopCategory] = useState([]);
  const [childcategory, setChildCategory] = useState([]);
  const [freshchildcategory, setFreshChildCategory] = useState([]);
  const [frozenchildcategory, setFrozenChildCategory] = useState([]);
  const [searchcategory, setSearchCategory] = useState([]);
  const [searchingcategory, setSearchingCategory] = useState([]);
  // const [filtCategory, setFiltCategory] = useState([]);
  // const [filtProductCategory, setFiltProductCategory] = useState([]);
  const [searching, setSearching] = useState("");
  const [offSet, setOffSet] = useState(1);
  const [categories, setCategories] = useState("");
  const [searchproduct, setSearchProduct] = useState("");
  const [productcategories, setProductCategories] = useState([]);

  const [fresh, setFresh] = useState();
  const [frozen, setFrozen] = useState();
  const [productid, setProductId] = useState("");
  const [dropvalue, setDropValue] = useState("All Categories");
  const [filterproduct, setFilterProduct] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // dropdownclose
  const handleDropdownClose = () => {
    setShowDropdown(false);
  };

  // all category
  const getAllCategories = async () => {
    await axios
      .get(`${Environment.base_url}/store/categories/all-publish?limit=11`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setAllCategory(res?.data?.data?.categories);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  // shop by category
  const getShopCategories = async () => {
    await axios
      .get(
        `${Environment.base_url}/store/categories?parentOnly=true&afterElement=0&limit=50&allCategories=false`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setShopCategory(res?.data?.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getShopCategories();
  }, []);

  // dropdown 2
  const getChildCategories = async (id) => {
    await axios
      .get(`${Environment.base_url}/store/categories/${id}/childs`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setChildCategory(res?.data?.data?.childCategories);
      })
      .catch((err) => { });
  };

  // search dropdown
  const getSearchCategories = async (id) => {
    await axios
      .get(
        `${Environment.base_url}/store/categories/${id}/products?limit=5&&offset=10`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setSearchCategory(res?.data?.data.products);
      })
      .catch((err) => { });
  };

  // search
  const SearchingCategories = async () => {
    await axios
      .get(
        `${Environment.base_url}/store/categories?&offset=1&limit=100&search=name=${searching}`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setSearchingCategory(res?.data?.data?.categories);
      })
      .catch((err) => { });
  };
  useEffect(() => {
    if (searching) {
      // SearchingCategories();
    }
  }, []);

  const SearchingProducts = async () => {
    await axios
      .get(
        `${Environment.base_url}/store/products?&offset=1&limit=100&search=name=${searchproduct}`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        setSearchProduct(res?.data?.data?.products);
      })
      .catch((err) => { });
  };
  useEffect(() => {
    SearchingProducts();
  }, []);

  // product search by category
  const getProduct = async () => {
    setProductCategories([]);
    try {
      const res = await axios.get(
        `${Environment.base_url}/store/products/?&limit=1000&offset=1&search=name=${searching}&${filterproduct && filterproduct.length > 0
          ? "filter=productCategoryId=in:[" + filterproduct + "]"
          : "&filter=isPublish=eq:true"
        }`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      );
      setProductCategories(res?.data?.data.products);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      getProduct();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searching]);

  // product search by category
  // const getProductCategory = async () => {
  //   await axios
  //     .get(
  //       `${Environment.base_url}/store/categories/${productid}/products?&limit=1&offset=1&search=name=${searchproduct}`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + val,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setProductCategories(res?.data?.data.products);
  //     })
  //     .catch((err) => {
  //     });
  // };
  const history = useHistory();
  // product search by category end
  function handleClick() {
    const elementId = "scroll";

    if (window.location.pathname !== "/") {
      history.push("/");

      // After navigating to the home page, wait for a short delay before scrolling to the section
      setTimeout(() => {
        scrollToSection(elementId);
      }, 500); // Adjust the delay as needed
    } else {
      // User is already on the home page, scroll to the section directly
      scrollToSection(elementId);
    }
  }

  function scrollToSection(elementId) {
    const element = document.getElementById(elementId);

    if (element) {
      const yOffset = -80; // adjust this value to control the amount of space above the element
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  // fresh categories
  const getFreshChildCategories = async () => {
    await axios
      .get(`${Environment.base_url}/store/categories/${userId}/childs`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setFreshChildCategory(res?.data?.data.childCategories);
      })
      .catch((err) => { });
  };
  // useEffect(() => {
  //   getFreshChildCategories();
  // }, []);

  // frozen categories
  const getFrozenChildCategories = async () => {
    await axios
      .get(`${Environment.base_url}/store/categories/${userId}/childs`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setFrozenChildCategory(res?.data?.data.childCategories);
      })
      .catch((err) => { });
  };
  // useEffect(() => {
  //   getFrozenChildCategories();
  // }, []);
  // dropdown value show

  //input search close outside click
  const [see, setSee] = useState(false);
  const useOutsideClick = (callback) => {
    const ref = React.useRef();

    React.useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [callback]);

    return ref;
  };

  const handleClickOutside = () => {
    setSee(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const userIsLoggedIn = localStorage.getItem("accessToken") !== null;

  // delivery map

  const addressLoc = props?.location?.state?.addLoc;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Marker = ({ lat, lng }) => (
    <div className="marker" style={{ width: "0px", height: "0px" }}>
      <span role="img" aria-label="marker" style={{ fontSize: "40px" }}>
        <img src="\assets\location.png"></img>
      </span>
    </div>
  );

  const [locationAddress, setLocationAddress] = useState("");
  const [location, setLocation] = useState({
    center: {
      lat: 33.6844,
      lng: 73.0479,
    },
    zoom: 13,
  });

  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [location1, setLocation1] = useState("");

  const [clickedLocation, setClickedLocation] = useState(null);
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    if (!searchValue) {
      return;
    }
    const geocoderRequest = {
      address: searchValue,

      componentRestrictions: {
        country: "pk",
        locality: "Islamabad|Rawalpindi",
      },
    };

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(geocoderRequest, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        setLocation({
          center: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
          zoom: 15,
        });
      }
    });
  };

  const handleMapClick = (event) => {
    setLocation({
      center: {
        lat: event.lat,
        lng: event.lng,
      },
      zoom: 15,
    });
    const clickedLat = event.lat;
    const clickedLng = event.lng;
    setClickedLocation({ lat: clickedLat, lng: clickedLng });
    Geocode.setApiKey("AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0");

    Geocode.fromLatLng(event.lat, event.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        setLocationAddress(address);
        dispatch(locationAddressRedux(address));
      },
      (error) => { }
    );

    // Do something with the location data, e.g. update state
  };

  const handleRevokeLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(savePosition);
    }

    // if (navigator.permissions && navigator.permissions.query) {
    //   navigator.permissions
    //     .query({ name: "geolocation" })
    //     .then((permission) => {
    //       if (permission.state === "granted") {
    //         permission.revoke().then(() => {
    //           setLocationPermissionGranted(false);
    //         });
    //       }
    //     });
    // }
  };

  //   useEffect(() => {

  //   }, []);

  useEffect(() => {
    Geocode.setApiKey("AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0");

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        setLocation1(address);
        setLocationAddress(address);
        dispatch(locationAddressRedux(address));
      },
      (error) => { }
    );
  }, [lat, lng]);
  function savePosition(position) {
    setLng(position?.coords?.longitude);
    setLat(position?.coords?.latitude);
  }
  if (location) {
    const locationString = JSON.stringify(location);
    localStorage.setItem("location", locationString);
  }

  const prorefresh = () => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 500);
    return () => clearTimeout(timer);
  };

  const prorefresh1 = () => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 250);
    return () => clearTimeout(timer);
  };

  function CheckboxWithPara({ label, changeID }) {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (changeID, label) => {
      setIsChecked(!isChecked);
      let updatedFilterProduct;
      if (filterproduct.includes(changeID)) {
        updatedFilterProduct = filterproduct.filter((id) => id !== changeID);
      } else {
        updatedFilterProduct = [...filterproduct, changeID];
      }
      setFilterProduct(updatedFilterProduct);
      if (updatedFilterProduct.length === 0) {
        setDropValue("All Categories");
      } else {
        setDropValue(label);
      }
    };

    return (
      <div className="mycheckboxmain">
        <label className="checkbox" htmlFor={label}>
          <input
            onChange={() => handleCheckboxChange(changeID, label)}
            type="checkbox"
            name="color"
            value={label}
            id={label}
            checked={filterproduct.includes(changeID)}
          />
          <span className="checkmark"></span>
        </label>
        <p className={isChecked ? "checkboxbluepara" : "checkboxpara"}>
          {label}
        </p>
      </div>
    );
  }

  useEffect(() => {
    dispatch(searchOffSet(1));
  }, [])

  const logotop = () => {
    dispatch(searchItem(""));
    dispatch(searchOffSet(1));
    window.scroll(0, 0);
  };

  const removeall = () => {
    localStorage.removeItem("cartItems");
    window.location.reload();
  };

  const handleSearchClick = () => {
    if (!searching || searching.trim() === "") {
      if (window.location.pathname === "/all-categories") {
        window.location.reload();
      } else {
        dispatch(searchItem(""));
        dispatch(searchOffSet(1));
        history.push("/all-categories");
      }
    } else {
      dispatch(searchItem(searching));
      dispatch(searchOffSet(1));
      handleClickOutside();
      handleCloseSearch();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!searching || searching.trim() === "") {
        if (window.location.pathname === "/all-categories") {
          dispatch(searchOffSet(1));
          window.location.reload();
        } else {
          dispatch(searchItem(""));
          dispatch(searchOffSet(1));
          history.push("/all-categories");
        }
      } else {
        dispatch(searchItem(searching));
        dispatch(searchOffSet(1));
        history.push("/all-categories");
        handleClickOutside();
        handleCloseSearch();
      }
    }
  };

  const handleDecrease = (index) => {
    let tempCart = [...cartItemss];
    let tempCartAmount = tempCart[index]?.cartAmount - 1;
    tempCart[index] = { ...tempCart[index], cartAmount: tempCartAmount };
    setCartItemss(tempCart);
    cartPrice(tempCart);
    localStorage.setItem("cartItems", JSON.stringify(tempCart));
  };

  const handleIncrease = (index, item) => {
    let tempCart = [...cartItemss];
    let tempCartItem = { ...tempCart[index] };
    const quantity = item?.quantity || item?.product?.quantity;
    const limit = item?.outOfStockThreshold || item?.product?.outOfStockThreshold;
    if (tempCartItem.cartAmount < quantity - limit) {
      let tempCartAmount = tempCartItem.cartAmount + 1;
      tempCart[index] = { ...tempCartItem, cartAmount: tempCartAmount };
      setCartItemss(tempCart);
      cartPrice(tempCart);
      localStorage.setItem("cartItems", JSON.stringify(tempCart));
    } else {
      toast.error("Max limit reached");
    }
  };

  const handleCartItem = (productToAdd, index) => {
    setProductCategories([]);
    let dumArr = productcategories;
    let count = productToAdd;
    // setJustLanding([]);
    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    // console.log("DDDDDDDD", dumArr, count);
    setProductCategories(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = 1;
    let temp = [productToAdd];
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
  };

  const handleSignInClick = () => {
    const currentRoute = history.location.pathname + history.location.search;
    localStorage.setItem("currentRoute", currentRoute);
  };

  return (
    <>
      <section className="sec-1">
        <div className="bg-m">
          <div className="container-fluid navbar-main">
            <div className="col-xl-11 col-12 m-auto p-0">
              <nav class="navbar navbar-expand-xl navbar-mobile ">
                <Link to="/" className="navbar-logo ">
                  <img onClick={logotop} src="\newlogo.svg"></img>
                </Link>
                <Link to="/account" className="login-btn-mobile d-none">
                  <div className="h-m">
                    <img className="a1" src="\assetsnav\s3.svg"></img>
                  </div>
                </Link>
                <button
                  class="navbar-toggler mobile-toggle"
                  type="button"
                  // onClick={handleShow}
                  onClick={handleShoww}
                >
                  <img src="\bar.svg" alt="img" className="img-fluid" />
                </button>
                <div
                  onClick={handleShowSearch}
                  className="search-btn-mobile d-none"
                >
                  <div className="">
                    <img
                      className="a1"
                      src="\assets\social-icons\search-icon.svg"
                    ></img>
                  </div>
                </div>
                <div
                  onClick={handleShowCart}
                  className="cart-btn-mobile d-none"
                >
                  <div className="">
                    <img className="a1" src="\assetsnav\a2.svg"></img>
                    {cartCount > 0 ? (
                      <span className="sp-1">{cartCount}</span>
                    ) : null}
                  </div>
                </div>

                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav m-auto scutvscfscrcsr">
                    <li className="category-drop">
                      <div class="dropdown ">
                        <button
                          class="dropdown-toggle "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M4 4H10V10H4V4ZM14 4H20V10H14V4ZM4 14H10V20H4V14ZM14 17C14 17.7956 14.3161 18.5587 14.8787 19.1213C15.4413 19.6839 16.2044 20 17 20C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17C20 16.2044 19.6839 15.4413 19.1213 14.8787C18.5587 14.3161 17.7956 14 17 14C16.2044 14 15.4413 14.3161 14.8787 14.8787C14.3161 15.4413 14 16.2044 14 17Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>{" "}
                          Categories
                        </button>
                        <ul class="dropdown-menu animationcategory slideUpIn">
                          <div className="category-items">
                            {shopcategory?.map((data, index) => {
                              return (
                                <>
                                  <div className="single-item">
                                    <a
                                      onClick={() => {
                                        setCatState({
                                          cat: data?.id,
                                          type: "cat",
                                        });
                                      }}
                                      href={"/all-categories?category=" + encodeURIComponent(data?.name)}
                                    // to={{
                                    //   pathname: "/all-categories",
                                    //   state: {
                                    //     cat: data?.id,
                                    //     type: "cat",
                                    //   },
                                    // }}
                                    >
                                      <a onClick={logotop}>
                                        <div className="upper-img ">
                                          <img
                                            src={data?.imageUrl}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="inner-text">
                                          <h6>{data?.name}</h6>
                                        </div>
                                      </a>
                                    </a>
                                  </div>
                                </>
                              );
                            })}
                            <div className="single-items">
                              {/* <a
                                    href="https://pharmacy.quick.shop" target="_blank"
                                    >
                                      <a onClick={logotop}>
                                        <div className="upper-img ">
                                          <img
                                            src="categories-icons\pharmacy.png"
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="inner-text">
                                          <h6>Pharmacy</h6>
                                        </div>
                                      </a>
                                    </a> */}
                            </div>
                          </div>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item dropdown yscvydvcdycyd">
                      <div className="di-1 search-ff">
                        {/* <a
                          class="nav-link pa-1 header__search__drop__down"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-bs-haspopup="true"
                          aria-bs-expanded="false"
                          onClick={getAllCategories}
                          data-bs-auto-close="false"
                        >
                          {dropvalue?.length > 12
                            ? `${dropvalue?.slice(0, 12)} ...`
                            : dropvalue}
                         {filterproduct.length > 0 ? <span className="numbers">{filterproduct.length}</span> : null}
                          <img src="\assetsnav\drop1.svg"></img>
                        </a> */}
                        <div
                          class="dropdown-menu m-lx search-drop-nav animationcategory slideUpIn"
                          aria-labelledby="dropdownMenuClickableInside"
                        >
                          {/* <Link to="/allcategories">
                            {" "} */}
                          {/* <Link
                            onClick={() => {
                              setCatState({
                                cat: "",
                                type: "cat",
                              });
                              setRend(!rend);
                            }}
                            to={
                              productid
                                ? {
                                    pathname: "/allcategories",
                                    state: {
                                      cat: "",
                                      type: "cat",
                                    },
                                  }
                                : "/allcategories"
                            }
                          >
                            <a
                              class="dropdown-item li-1x li-h"
                              href="#"
                              onClick={() => {
                                setProductId(null);
                                setDropValue("All Categories");
                                setFilterProduct(null);
                              }}
                            >
                              All Categories
                            </a>
                          </Link> */}
                          {/* {" "}
                          </Link> */}
                          {/* <hr className="h-dr"></hr>
                          {allcategory?.map((data, index) => {
                            return (
                              <>
                                <Link
                                  eventKey={data?.id}
                                  onClick={(e) => {
                                    setCatState({
                                      cat: data?.id,
                                      type: "cat",
                                    });
                                    setProductId(data?.id);
                                    setDropValue(data?.name);
                                    setFilterProduct(data?.id);
                                    setRend(!rend);
                                  }}
                                  to={
                                    data?.id
                                      ? {
                                          pathname: "/allcategories",
                                          state: {
                                            cat: data?.id,
                                            type: "cat",
                                          },
                                        }
                                      : "/allcategories"
                                  }
                                  class="dropdown-item dropdown__item__list li-1x "
                                >
                                  {data?.name}
                                </Link>

                                <hr className="h-dr"></hr>
                              </>
                            );
                          })} */}
                          {allcategory?.map((data, index) => {
                            return (
                              <>
                                <CheckboxWithPara
                                  label={data?.name}
                                  changeID={data?.id}
                                />
                              </>
                            );
                          })}
                        </div>
                        <input
                          ref={ref}
                          placeholder="I’m searching for..."
                          className="in-1"
                          value={searching}
                          // onClick={() => setSee(true)}
                          onChange={(e) => {
                            setSee(true);
                            setSearching(e.target.value);
                            setIsLoading(true);
                            // getProductCategory(productid)
                          }}
                          onKeyDown={(e) => handleKeyDown(e)}
                          type="text"
                        />
                        <li class="nav-item dropdown li-2">
                          <div
                            class="dropdown-menu ab-mai animationcategory slideUpIn"
                            aria-bs-labelledby="navbarDropdown"
                          >
                            {cartItemss?.length > 0
                              ? cartItemss?.map((item) => {
                                return (
                                  <>
                                    <div className="d-flex">
                                      <div>
                                        <img src="\assetsnav\prod.svg"></img>
                                      </div>
                                      <div className=" d-sub">
                                        <p className="pa-1x">{item?.name}</p>
                                        <p className="pa-21">
                                          {item?.description}
                                        </p>
                                        <p className="pa-3">
                                          Rs. {item?.price}
                                        </p>
                                      </div>
                                    </div>
                                    <hr className="hr-4"></hr>
                                  </>
                                );
                              })
                              : null}

                            <div className="d-flex">
                              <div className="d-flex">
                                <div>
                                  <img src="\assetsnav\prod.svg"></img>
                                </div>
                                {searchcategory?.map((data, index) => {
                                  return (
                                    <div className=" d-sub">
                                      <p className="pa-1x">{data?.name}</p>
                                      <p className="pa-2">Yorkshire Coffee</p>
                                      <p className="pa-3">{data?.price}</p>
                                    </div>
                                  );
                                })}
                              </div>
                              <hr className="hr-4"></hr>
                              {/* <div className="d-flex">
                                <img src="\assets nav\prod2.svg"></img>
                                <div className=" d-sub">
                                  <p className="pa-1x">
                                    Vimto Squash Remix Apple
                                  </p>
                                  <p className="pa-2">1.5 Litres</p>
                                  <p className="pa-3">Rs. 1,468</p>
                                </div>
                              </div>
                              <hr className="hr-4"></hr>*/}
                            </div>
                          </div>
                        </li>
                        <div
                          class="nav-link search-icon "
                        // href="#"
                        // id="navbarDropdown"
                        // role="button"
                        // data-bs-toggle="dropdown"
                        // aria-bs-haspopup="true"
                        // aria-bs-expanded="false"
                        //  onClick={() => getSearchCategories()}
                        >
                          <div className="rea-1">
                            <Link to="/all-categories">
                              <img
                                className="sea-1"
                                src="\assetsnav\search-new.svg"
                                onClick={handleSearchClick}
                              ></img>
                            </Link>
                          </div>
                        </div>
                      </div>
                      {searching && see ? (
                        <div
                          ref={ref}
                          className="search__box__handle animationcategory slideUpIn"
                        >

                          {productcategories?.length > 0 ? (
                            productcategories?.map((data, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="product-map  value-show"
                                  >
                                    <div>
                                      <Link
                                        className="text-dark"
                                        to={"/product-detail?id=" + data?.id}
                                        onClick={prorefresh}
                                      // state: {
                                      //   id: data?.id,
                                      // },
                                      // }
                                      >
                                        {/* <p>{data?.name}</p> */}

                                        <div className="d-flex">
                                          <div>
                                            {data?.imageUrl !== null &&
                                              data?.imageUrl !== undefined ? (
                                              <img
                                                className="search-prod-img"
                                                src={data?.imageUrl ? data?.imageUrl : "assets\Frame 186 (1).png"}
                                              ></img>
                                            ) : (
                                              <img src="assets navprod.svg"></img>
                                            )}
                                          </div>
                                          <div className=" d-sub">
                                            <p className="pa-1x">{data?.name}</p>
                                            <p className="pa-2 my-2 pb-1 category___name">
                                              {data?.category?.name}
                                            </p>
                                            {data?.discountedProducts[0]?.discount ?
                                              <div className="d-flex">
                                                <del style={{ color: "red" }}>
                                                  <h6 className="price__tag1">Rs.{data?.price}</h6>
                                                </del>
                                                <h6 className="price__distag">
                                                  Rs.{Math.trunc(
                                                    data?.price -
                                                    data?.price *
                                                    (data?.discountedProducts[0]?.discount / 100)
                                                  )}
                                                </h6>
                                              </div>
                                              :
                                              <p className="pa-3 price__tag">
                                                Rs. {data?.price}
                                              </p>
                                            }

                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div>
                                      {data?.quantityAmount === 1 ? (
                                        <img
                                          src="assets\homeshopnew\yes.svg"
                                          className="addd"
                                        ></img>

                                      ) : (
                                        <img
                                          onClick={() => handleCartItem(data, index)}
                                          src="assets\homeshopnew\add-to-cart.svg"
                                          className="addd"
                                        ></img>
                                      )}
                                    </div>
                                  </div>
                                  <hr className="hr-4 divider__line"></hr>
                                </>
                              );
                            })
                          ) : (
                            isLoading ? (
                              <div className="loader-image-product-detail">
                                <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
                              </div>
                            ) : (
                              <div className="emptycartImg">
                                <h5 className="p-2">
                                  No products on this search
                                </h5>
                              </div>
                            )
                          )}
                        </div>
                      ) : null}
                    </li>
                  </ul>
                  {/* <div className="da-2 d-block"> */}
                  {/* {!locationRedux && !locaiton ? ( */}
                  {/* <> */}
                  {/* <div className="d-inline-flex align-items-center"> */}
                  {/* <h6 className="">Add Delivery Address</h6> */}
                  {/* <div className="d-flex"> */}
                  {/* <div class="dropdown loc"> */}
                  {/* <button */}
                  {/* class="locbtn" */}
                  {/* type="button" */}
                  {/* id="dropdownMenuButton1" */}
                  {/* data-bs-toggle="dropdown" */}
                  {/* aria-expanded="false" */}
                  {/* > */}
                  {/* <img */}
                  {/* className="dro-1 pad-img" */}
                  {/* src="\assets nav\drop.svg" */}
                  {/* ></img> */}
                  {/* </button> */}
                  {/* <ul */}
                  {/* class="dropdown-menu locdrop" */}
                  {/* aria-labelledby="dropdownMenuButton1" */}
                  {/* > */}
                  {/* <li>
                            <a class="dropdown-item" href="#">
                              I/11 Quecko Inc. Street#55 Isl...
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Paksitan Town Pwd Isl...
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              IJP Road Rawalpindi
                            </a>
                          </li> */}
                  {/* <Link
                                  to={{
                                    pathname: "/location",
                                    state: {
                                      addLoc: locaiton,
                                    },
                                  }}
                                > */}
                  {/* <li> */}
                  {/* <a
                                      // class="dropdown-item color d-flex align-items-center justify-content-start pt-3"
                                      style={{ borderTop: "1px solid #D1EDFA" }}
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      class="btngps"
                                      type="button"
                                      onClick={handleRevokeLocationPermission}
                                    >
                                      <img
                                        src="\Assests\gps.svg"
                                        alt="img"
                                        className="img-fluid me-2"
                                      />
                                      Location on Map
                                    </a> */}
                  {/* </li> */}
                  {/* </Link> */}
                  {/* </ul> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </> */}
                  {/* ) : ( */}
                  {/* <> */}
                  {/* <p className="text-specss">Delivering to:</p> */}
                  {/* <div className="d-flex">
                          {locationRedux ? (
                            <p className="pa-3">{locationRedux}</p>
                          ) : (
                            <p className="pa-3">{locaiton}</p>
                          )}

                          <div class="dropdown loc">
                            <button
                              class="locbtn"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                className="dro-1"
                                src="\assetsnav\drop.svg"
                              ></img>
                            </button>
                            <ul
                              class="dropdown-menu locdrop"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              {/* <li>
                            <a class="dropdown-item" href="#">
                              I/11 Quecko Inc. Street#55 Isl...
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Paksitan Town Pwd Isl...
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              IJP Road Rawalpindi
                            </a>
                          </li> */}
                  {/* <Link
                                to={{
                                  pathname: "/location",
                                  state: {
                                    addLoc: locaiton,
                                  },
                                }}
                              > */}
                  {/* <li>
                                  <a
                                    // class="dropdown-item color d-flex align-items-center justify-content-start pt-3"
                                    // style={{ borderTop: "1px solid #D1EDFA" }}
                                    style={{ borderTop: "1px solid #D1EDFA" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    class="btngps"
                                    type="button"
                                    onClick={handleRevokeLocationPermission}
                                  >
                                    <img
                                      src="\Assests\gps.svg"
                                      alt="img"
                                      className="img-fluid me-2"
                                    />
                                    Location on Map
                                  </a>
                                </li> */}
                  {/* </Link> */}
                  {/* </ul> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </> */}
                  {/* )} */}
                  {/* </div> */}

                  <div className="fl-1">
                    {/* <Link to="/shopwishlist">
                      <div className="h-m">
                        <img className="a1" src="\assets nav\a1.svg"></img>
                        {wishCount > 0 ? (
                          <span className="sp-1">{wishCount}</span>
                        ) : null}
                      </div>
                    </Link> */}

                    <div className="h-m ">
                      <li class="nav-item dropdown li-2">
                        <a
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          onClick={() => setShowDropdown(!showDropdown)}
                          data-bs-toggle="dropdown"
                          aria-bs-haspopup="true"
                          aria-bs-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <img className="a1" src="\assetsnav\a2.svg"></img>
                          {cartCount > 0 ? (
                            <span className="sp-1 sp-2">{cartCount}</span>
                          ) : null}
                        </a>
                        <div
                          class={`dropdown-menu ab-mai animationcategory slideUpIn ${showDropdown ? "show" : ""
                            }`}
                          aria-bs-labelledby="navbarDropdown"
                        >
                          <div className="upper-head">
                            <h6>
                              Products
                              {cartCount > 0 ? <span>{cartCount}</span> : null}
                            </h6>
                            <a href="#"  onClick={showModal}>
                              Remove All
                            </a>
                          </div>
                          {cartItemss?.length > 0 ? (
                            cartItemss?.map((item, index) => {
                              return (
                                <>
                                  <div className="d-flex flex___item">
                                    <div className="d-flex d-main1">
                                      <div className="">
                                        <img
                                          className="cart-img"
                                          src={
                                            item?.product?.imageUrl ||
                                            item?.imageUrl
                                          }
                                          alt=""
                                        ></img>
                                      </div>
                                      <div className="d-block d-sub">
                                        <p className="pa-1">
                                          {item?.product?.name || item?.name}
                                        </p>
                                        {/* <p className="pa-21">
                                          {item?.product?.description || item?.description}
                                        </p> */}
                                        <p className="pa-3">
                                          Rs.{" "}
                                          {item?.discount &&
                                            Math.trunc(
                                              item?.product?.price -
                                              item?.product?.price *
                                              (item?.discount / 100)
                                            )}
                                          {(item?.discountedProducts &&
                                            Math.trunc(
                                              item?.price -
                                              item?.price *
                                              (item?.discountedProducts[0]
                                                ?.discount /
                                                100)
                                            )) ||
                                            item.price}
                                          {/* <span className="amount-text ps-4">
                                            (x
                                            {item?.cartAmount
                                              ? item?.cartAmount
                                              : 1}
                                            )
                                          </span> */}
                                          {item?.cartAmount <= 0 &&
                                            handleCartItemDelete(item)}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <img
                                      className="tra-1"
                                      src="\assetsnav\trash.svg"
                                      alt=""
                                      onClick={() => handleCartItemDelete(item)}
                                    ></img> */}
                                    <div className="btn-add add-counter">
                                      {item?.cartAmount === 1 ? (
                                        <a
                                          onClick={() =>
                                            handleCartItemDelete(item)
                                          }
                                        >
                                          <img
                                            src="\assets\homeshopnew\trash.svg"
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </a>
                                      ) : (
                                        <a
                                          onClick={() => handleDecrease(index)}
                                        >
                                          <img
                                            src="\assets\minus-icon.svg"
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </a>
                                      )}

                                      <span> {item?.cartAmount}</span>
                                      <a onClick={() => handleIncrease(index, item)}>
                                        <img
                                          src="\assets\plus-icon.svg"
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <hr className="hr-3"></hr>

                                  {/* <div className="dro-l">
                                    <p className="pm">TOTAL:</p>
                                    <p className="po">Rs. {totalCartPrice}</p>
                                  </div> */}
                                </>
                              );
                            })
                          ) : (
                            <div className="emptycartImg">
                              <img src={emptycartImg} alt="Empty cart" />
                              <h5 className="p-2">No products in the cart.</h5>
                            </div>
                          )}
                          {cartItemss?.length > 0 ? (
                            <>
                              <div className="set-upper-item">
                                <div className="dro-l">
                                  <p className="pm">TOTAL:</p>
                                  <p className="po">Rs. {totalCartPrice}</p>
                                </div>
                                <div className="dro-2">
                                  <Link
                                    to="/home-sshop"
                                    className="w-100"
                                    onClick={handleDropdownClose}
                                  >
                                    {" "}
                                    <button onClick={logotop} className="po usvcvss">
                                      View Cart
                                    </button>
                                  </Link>
                                  {/* <Link to="/cartcheckout" className="w-100">
                                  {" "}
                                  <button className="po usvcvss">
                                    Checkout
                                  </button>
                                </Link> */}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </li>
                    </div>
                    {userIsLoggedIn ? (
                      <Link onClick={logotop} to="/account">
                        {/* <div className="h-m">
                          <img className="a1" src="\assetsnav\s3.svg"></img>
                        </div> */}
                        <button className="sign-btn ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>{" "}
                          My Account
                        </button>
                      </Link>
                    ) : (
                      <Link onClick={logotop} to="/login">
                        {/* <div className="h-m">
                          <img className="a1" src="\assetsnav\s3.svg"></img>
                        </div> */}
                        <button
                          className="sign-btn "
                          onClick={handleSignInClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>{" "}
                          Sign In
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* second line */}

        <hr className="set-custom-border-top" />

        <div className="container-fluid second-nav ">
          <div className="col-xl-11 col-12 m-auto p-0">
            <div className="d-flex dealtoday-cat">
              <nav class="navbar navbar-expand-lg">
                {/* <div>
                  <li class="nav-item dropdown ml-4 li-3">
                    <a
                      class="nav-link btn-1"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-bs-haspopup="true"
                      aria-bs-expanded="false"
                      data-bs-auto-close="outside"
                      onClick={getShopCategories}
                    >
                      Product Categories
                      <img
                        className="me-2"
                        src="assets\btn-icons\Vector.svg"
                        alt="Shop Icon"
                      ></img>
                    </a>
                    <div
                      class="dropdown-menu droe-main"
                      aria-bs-labelledby="navbarDropdown"
                    >
                      {shopcategory.map((data, index) => {
                        return (
                          <li class="nav-item dropdown li-2">
                            <Link
                              onClick={() => {
                                setCatState({
                                  cat: data?.id,
                                  type: "cat",
                                });
                              }}
                              to={{
                                pathname: "/all-categories",
                                state: {
                                  cat: data?.id,
                                  type: "cat",
                                },
                              }}
                            >
                              <a
                                className="dropdown-item li-1 li-2"
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={() => {
                                  const dropdownMenu =
                                    document.querySelector(".droe-main");
                                  dropdownMenu.classList.remove("show");
                                }}
                              >
                                {data?.name}
                              </a>
                            </Link>
                          </li>
                        );
                      })}
                    </div>
                  </li>
                </div> */}
                {/* <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button> */}

                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  {/* <ul class="navbar-nav ml-auto ul-1x none-mobile">
                    <li class="nav-item active d-flex ms-4 li-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M5.07492 11.0667H7.64992V17.0667C7.64992 18.4667 8.40826 18.7501 9.33326 17.7001L15.6416 10.5334C16.4166 9.65841 16.0916 8.93341 14.9166 8.93341H12.3416V2.93341C12.3416 1.53341 11.5833 1.25008 10.6583 2.30008L4.34992 9.46674C3.58326 10.3501 3.90826 11.0667 5.07492 11.0667Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <div onClick={handleClick}>
                        <Link to="/">
                          <a class="nav-link li-1" href="#">
                            Quick Top Saver
                          </a>
                        </Link>
                      </div>
                    </li>
                    <Link to="/about-us">
                      <li class="nav-item ms-4">
                        <a class="nav-link  li-1">About Us</a>
                      </li>
                    </Link>
                    <Link to="/loyality-program">
                      <li class="nav-item ms-4">
                        <a class="nav-link  li-1">Loyalty Program</a>
                      </li>
                    </Link>
                    <li class="nav-item ms-4">
                      <Link to="/corporate-orders" class="nav-link li-1">
                        Corporate Orders
                      </Link>
                    </li>
                    <li class="nav-item ms-4">
                      <Link to="/contact-us" class="nav-link li-1">
                        Contact Us
                      </Link>
                    </li>
                  </ul> */}
                  <div className="bottom-items-nav">
                    <ul className="bottom-ul">
                      <li className="pulse-animation">
                        <a onClick={handleClick} href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.07492 11.0667H7.64992V17.0667C7.64992 18.4667 8.40826 18.7501 9.33326 17.7001L15.6416 10.5334C16.4166 9.65841 16.0916 8.93341 14.9166 8.93341H12.3416V2.93341C12.3416 1.53341 11.5833 1.25008 10.6583 2.30008L4.34992 9.46674C3.58326 10.3501 3.90826 11.0667 5.07492 11.0667Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>{" "}
                          Quick Top Saver
                        </a>
                      </li>
                      {/* <li className="pulse-animation">
                        <Link to="/all-categories">
                          <a href="#">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M3.47483 12.7502L7.24983 16.5252C8.79983 18.0752 11.3165 18.0752 12.8748 16.5252L16.5332 12.8668C18.0832 11.3168 18.0832 8.80016 16.5332 7.24183L12.7498 3.47516C11.9582 2.6835 10.8665 2.2585 9.74983 2.31683L5.58316 2.51683C3.9165 2.59183 2.5915 3.91683 2.50816 5.57516L2.30816 9.74183C2.25816 10.8668 2.68316 11.9585 3.47483 12.7502Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.91659 10.0002C9.06718 10.0002 9.99992 9.06742 9.99992 7.91683C9.99992 6.76624 9.06718 5.8335 7.91659 5.8335C6.76599 5.8335 5.83325 6.76624 5.83325 7.91683C5.83325 9.06742 6.76599 10.0002 7.91659 10.0002Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>{" "}
                            Special Prices
                          </a>
                        </Link>
                      </li> */}
                      {/* <li>
                        <div class="dropdown">
                    
                          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <Link
                    onClick={() => {
                      setCatState({
                        cat: 3,
                        type: "cat",
                      });
                      console.log("jdjdjd");
                    }}
                    to={{
                      pathname: "/all-categories",
                      state: {
                        cat: 3,
                        type: "cat",
                      },
                    }}
                  > 
                            Fresh </Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                              <path d="M12.7237 7.13794L8.99953 10.8621L5.27539 7.13794" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </button>
                          <ul class="dropdown-menu animationcategory slideUpIn">
                            <li><a class="dropdown-item" href="#">Coming Soon...</a></li>
                            <li><a class="dropdown-item" href="#">Coming Soon...</a></li>
                            <li><a class="dropdown-item" href="#">Coming Soon...</a></li>
                          </ul>
                        </div>
                      </li> */}
                      {/* <li>
                        <div class="dropdown">
                      
                          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <Link
                       to="/all-categories"
                        >
                            Frozen</Link>
                             <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                              <path d="M12.7237 7.13794L8.99953 10.8621L5.27539 7.13794" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </button>
                          <ul class="dropdown-menu animationcategory slideUpIn">
                            <li><a class="dropdown-item" href="#">Coming Soon...</a></li>
                            <li><a class="dropdown-item" href="#">Coming Soon...</a></li>
                            <li><a class="dropdown-item" href="#">Coming Soon...</a></li>
                          </ul>
                        </div>
                      </li> */}
                      <li className="pulse-animation">
                        <a
                          href={"/all-categories?category=Fruits%20%26%20Vegetables"}
                          onClick={() => {
                            setCatState({
                              cat: 3,
                              type: "cat",
                            });
                          }}
                        // to={{
                        //   pathname: "/all-categories",
                        //   state: {
                        //     cat: 3,
                        //     type: "cat",
                        //   },
                        // }}
                        >
                          <a onClick={logotop} >Fresh</a>
                        </a>
                      </li>{" "}
                      <li className="pulse-animation">
                        <a
                          href={"/all-categories?category=Frozen"}

                          onClick={() => {
                            setCatState({
                              cat: 11,
                              type: "cat",
                            });
                          }}
                        // to={{
                        //   pathname: "/all-categories",
                        //   state: {
                        //     cat: 11,
                        //     type: "cat",
                        //   },
                        // }}
                        >
                          <a onClick={logotop} >Frozen</a>
                        </a>
                      </li>
                      <li className="pulse-animation">
                        <Link to="/about-us">
                          <a onClick={logotop} href="#">About Us</a>
                        </Link>
                      </li>
                      <li className="pulse-animation">
                        <Link to="/loyality-program">
                          <a onClick={logotop} href="#">Loyalty Program</a>
                        </Link>
                      </li>
                      <li className="pulse-animation">
                        <Link to="/corporate-orders">
                          <a onClick={logotop} href="#">Corporate Orders</a>
                        </Link>
                      </li>
                      <li className="pulse-animation">
                        <Link to="/contact-us">
                          <a onClick={logotop} href="#">Contact Us</a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="sidebar-catogary">
          <Offcanvas
            className="sidebar-catogary"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton className="profile">
              {userIsLoggedIn ? (
                <Link onClick={handleClose} to="/account">
                  <img
                    src="\assets\social-icons\login.svg"
                    alt="img"
                    className="img-fluid pl-3"
                  />
                </Link>
              ) : (
                <Link onClick={handleClose} to="/account">
                  <img
                    src="\assets\social-icons\login.svg"
                    alt="img"
                    className="img-fluid pl-3 profile2"
                  />{" "}
                  Log In / Sign up{" "}
                </Link>
              )}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="links-deal">
                <ul class="navbar-nav mr-auto ul-1x none-mobile">
                  <li onClick={handleClose} class="nav-item active d-flex">
                    {/* <img src="\assetsnav\lg6.svg" className="me-2"></img> */}
                    <Link to="/" class="nav-link li-1">
                      <a class="nav-link li-1 " href="#" onClick={handleClick}>
                        Quick Top Saver
                      </a>
                    </Link>
                  </li>
                  {/* <li class="nav-item d-flex ">
                    <img
                      src="\assets\social-icons\tag.svg"
                      className="me-2"
                    ></img>
                    <a class="nav-link li-1" href="#">
                      Special Prices
                    </a>
                  </li> */}

                  {/* <Link
                    onClick={() => {
                      setCatState({
                        cat: 3,
                        type: "cat",
                      });
                    }}
                    to={{
                      pathname: "/allcategories",
                      state: {
                        cat: 3,
                        type: "cat",
                      },
                    }}
                  > */}
                  <li class="nav-item ">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Product Categories</Accordion.Header>
                        <Accordion.Body>
                          {shopcategory.map((data, index) => {
                            return (
                              <li
                                onClick={handleClose}
                                class="nav-item dropdown li-2"
                              >
                                <a
                                  onClick={() => {
                                    setCatState({
                                      cat: data?.id,
                                      type: "cat",
                                    });
                                  }}
                                  href={"/all-categories?category=" + encodeURIComponent(data?.name)}
                                // to={{
                                //   pathname: "/all-categories",
                                //   state: {
                                //     cat: data?.id,
                                //     type: "cat",
                                //   },
                                // }}
                                >
                                  <a
                                    className="all-cat"
                                    id="navbarDropdown"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    onClick={() => {
                                      const dropdownMenu =
                                        document.querySelector(".droe-main");
                                      dropdownMenu.classList.remove("show");
                                    }}
                                  >
                                    {data?.name}
                                  </a>
                                </a>
                              </li>
                            );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    {/* <a
                        class="nav-link disabled li-1 nav-cat"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                       Product Categories
                      </a> */}
                  </li>
                  {/* </Link> */}

                  {/* <Link
                    onClick={() => {
                      setCatState({
                        cat: 11,
                        type: "cat",
                      });
                    }}
                    to={{
                      pathname: "/allcategories",
                      state: {
                        cat: 11,
                        type: "cat",
                      },
                    }}
                  >
                    <li onClick={handleClose} class="nav-item ">
                      <a
                        class="nav-link disabled li-1 nav-cat"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Frozen
                      </a>
                    </li>
                  </Link> */}

                  <li onClick={handleClose} class="nav-item">
                    <Link to="/about-us" class="nav-link li-1">
                      <a class="nav-link disabled li-1" href="#">
                        About Us
                      </a>
                    </Link>
                  </li>
                  <li onClick={handleClose} class="nav-item">
                    <Link to="/loyality-program" class="nav-link li-1">
                      <a class="nav-link disabled li-1" href="#">
                        Loyalty Program
                      </a>
                    </Link>
                  </li>
                  <li onClick={handleClose} class="nav-item">
                    <Link to="/corporate-orders" class="nav-link li-1">
                      Corporate Orders
                    </Link>
                  </li>
                  <li onClick={handleClose} class="nav-item">
                    <Link to="/contact-us" class="nav-link li-1">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="sidebar-cart">
          <Offcanvas
            className="sidebar-cart"
            show={showcart}
            onHide={handleCloseCart}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Link to="">Shopping Cart</Link>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0 gvstvtscvtvsv">
              <div
                className="bottom-items suvctssctscsc"
                style={{ overflowY: "auto", maxHeight: "67vh" }}
              >
                <div className="upper-head">
                  <h6>
                    Products
                    {cartCount > 0 ? <span>{cartCount}</span> : null}
                  </h6>
                  <a href="#" onClick={showModal}>
                    Remove All
                  </a>
                </div>
                {cartItemss?.length > 0 ? (
                  cartItemss?.map((item, index) => {
                    return (
                      <>
                        <div className="main-field">
                          <div className="dvcdtvdtvtvcdvvcvd">
                            <div className="img-item">
                              <img
                                src={item?.product?.imageUrl || item?.imageUrl}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="text">
                              <h5>{item?.product?.name || item?.name}</h5>
                              <h6>
                                {item?.product?.description ||
                                  item?.description}
                              </h6>
                              <h4>
                                <span>Rs.</span>{" "}
                                {" "}
                                {item?.discount &&
                                  Math.trunc(
                                    item?.product?.price -
                                    item?.product?.price *
                                    (item?.discount / 100)
                                  )}
                                {(item?.discountedProducts &&
                                  Math.trunc(
                                    item?.price -
                                    item?.price *
                                    (item?.discountedProducts[0]
                                      ?.discount /
                                      100)
                                  )) ||
                                  item.price}
                                {/* <span className="amount-text ps-4">
                                  (x{item?.cartAmount})
                                </span> */}
                                {item?.cartAmount <= 0 &&
                                  handleCartItemDelete(item)}
                              </h4>
                            </div>
                          </div>
                          <div className="img-delete">
                            {/* <img
                              src="\assets\btn-icons\trash.svg"
                              alt="img"
                              className="trash-img-size"
                              onClick={() => handleCartItemDelete(item)}
                            /> */}
                            <div className="btn-add add-counter">
                              {item?.cartAmount === 1 ? (
                                <a onClick={() => handleCartItemDelete(item)}>
                                  <img
                                    src="\assets\homeshopnew\trash.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                </a>
                              ) : (
                                <a onClick={() => handleDecrease(index)}>
                                  <img
                                    src="\assets\minus-icon.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                </a>
                              )}
                              <span> {item?.cartAmount}</span>
                              <a onClick={() => handleIncrease(index, item)}>
                                <img
                                  src="\assets\plus-icon.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        </div>

                        {/* <hr className="hr-3"></hr> */}

                        {/* <div className="dro-l">
                                    <p className="pm">TOTAL:</p>
                                    <p className="po">Rs. {totalCartPrice}</p>
                                  </div> */}
                      </>
                    );
                  })
                ) : (
                  <div className="emptycartImg">
                    <img src={emptycartImg} alt="Empty cart" />
                    <h5 className="p-2">No products in the cart.</h5>
                  </div>
                )}
              </div>
              {cartItemss?.length > 0 ? (
                <>
                  <div className="bottom-btns">
                    <div className="upper-text">
                      <p>TOTAl:</p>
                      <h6>Rs. {totalCartPrice}</h6>
                    </div>
                    <div className="btnsss">
                      <Link to="/home-sshop" onClick={() => handleCloseCart()}>
                        {" "}
                        View Cart
                      </Link>
                      {/* <Link to="/cartcheckout"> Checkout</Link> */}
                    </div>
                  </div>
                </>
              ) : null}
            </Offcanvas.Body>
          </Offcanvas>
        </div>

        <div className="sidebar-search">
          <Offcanvas
            className="sidebar-search"
            show={showsearch}
            onHide={handleCloseSearch}
            placement="end"
          >
            <Offcanvas.Header>
              <img
                src="\assets\btn-close.svg"
                style={{ marginLeft: "11px" }}
                alt="arrow"
                className="arrowlefti"
                onClick={handleCloseSearch}
              />

              <div className="option-field cvvdvcdvvvdcv">
                <input
                  type="text"
                  placeholder="I’m searching for..."
                  value={searching}
                  onChange={(e) => {
                    setSee(true);
                    setSearching(e.target.value);
                    setIsLoading(true);
                    // getProductCategory(productid)
                  }}
                  onKeyDown={handleKeyDown}
                />
                <Link to="/all-categories">
                  <img
                    src="\assets\bg-imgs\search-mobile-input.svg"
                    alt="img"
                    className="img-fluid search-iconvggvgvgv"
                    onClick={handleSearchClick}
                  />
                </Link>
                {/* <img
                  src="\assets\btn-icons\search-normal.svg"
                  alt="img"
                  className="img-fluid search-icon"
                /> */}
              </div>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Dropdown className="dropsss" autoClose={false}>
                {/* <Dropdown.Toggle>
                {dropvalue?.length > 20
                            ? `${dropvalue?.slice(0, 20)} ...`
                            : dropvalue}
                  {filterproduct.length > 0 ? <span className="numbers">{filterproduct.length}</span> : null}
                  <img src="\assetsnav\drop1.svg" alt="Dropdown Icon" className="arrowicon" />
                </Dropdown.Toggle> */}

                {/* <Dropdown.Menu>
                {allcategory?.map((data, index) => {
                            return (
                              <>
                                <CheckboxWithPara label={data?.name}
                                   changeID={data?.id}
                                />
                              </>
                            );
                          })}
               
                </Dropdown.Menu> */}
              </Dropdown>
              <p className="searchresultpara">Search Results</p>
              {searching && see ? (
                <div ref={ref}>
                  {productcategories?.length > 0 ? (
                    productcategories.map((data, index) => (
                      <div className="main-field" key={index}>
                        <Link
                          className="text-dark d-inline-flex"
                          onClick={prorefresh1}
                          to={"/product-detail?id=" + data?.id}
                        >
                          <div className="img-item">
                            <div>
                              {data?.imageUrl !== null &&
                                data?.imageUrl !== undefined ? (
                                <img
                                  className="search-prod-img"
                                  src={data?.imageUrl}
                                  alt="Product"
                                />
                              ) : (
                                <img
                                  src="assets navprod.svg"
                                  alt="Default Product"
                                />
                              )}
                            </div>
                          </div>
                          <div className="text">
                            <h5>{data?.name}</h5>
                            <h6>{data?.category?.name}</h6>
                            {data?.discountedProducts[0]?.discount ?
                              <div className="d-flex">
                                <del style={{ color: "red" }}>
                                  <h6 className="price__tag1">Rs.{data?.price}</h6>
                                </del>
                                <h6 className="price__distag">
                                  Rs.{Math.trunc(
                                    data?.price -
                                    data?.price *
                                    (data?.discountedProducts[0]?.discount / 100)
                                  )}
                                </h6>
                              </div>
                              :
                              <h4>
                                <span>Rs.</span> {data?.price}
                              </h4>
                            }

                          </div>
                        </Link>
                        <div>
                          {data?.quantityAmount === 1 ? (
                            <img
                              src="assets\homeshopnew\yes.svg"
                              className="addd"
                            ></img>

                          ) : (
                            <img
                              onClick={() => handleCartItem(data, index)}
                              src="assets\homeshopnew\add-to-cart.svg"
                              className="addd"
                            ></img>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    isLoading ? (
                      <div className="loader-image-product-detail">
                        <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
                      </div>
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "20px",
                        }}
                      >
                        No products{" "}
                      </p>
                    )
                  )}
                </div>
              ) : null}
            </Offcanvas.Body>
          </Offcanvas>
          <div
            class="modal fade location-modal"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-contentt">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Is this your exact location?
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  {/* <input placeholder="Enter your full address" /> */}
                  {/* {locationRedux ? (
                            <p className="pa-3">{locationRedux}</p>
                          ) : (
                            <p className="pa-3">{locaiton}</p>
                          )} */}

                  <input
                    type="text"
                    name="search"
                    value={locationRedux ? locationRedux : locaiton}
                    placeholder="Search location"
                    onChange={(e) => handleSearch(e)}
                  />
                  <div className="map-div">
                    <GoogleMapReact
                      onClick={handleMapClick}
                      bootstrapURLKeys={{
                        key: "AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0",
                      }}
                      defaultCenter={location.center}
                      defaultZoom={location.zoom}
                      yesIWantToUseGoogleMapApiInternals
                    >
                      {clickedLocation && (
                        <Marker
                          lat={clickedLocation.lat}
                          lng={clickedLocation.lng}
                          width="20px"
                          height="20px"
                        />
                      )}
                    </GoogleMapReact>
                    <div className="w-100 confirm__location___btn">
                      <button
                        type="button"
                        //  class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Confirm Address
                      </button>
                    </div>
                  </div>
                  {/* <img src='\Assests\map.png' alt='img'/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Offcanvas
        className="sidebar-catogary-new"
        show={showw}
        onHide={handleClosee}
      >
        <Offcanvas.Header>
          <div className="top-logo">
            <Link onClick={logotop} to="/">
              <img
                onClick={handleClosee}
                src="\new-logo-blue.svg"
                alt="img"
                className="img-fluid"
              />
            </Link>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="account-div">
            {userIsLoggedIn ? (
              <Link onClick={logotop} to="/account">
                <a className="btn-acc" href="#" onClick={handleClosee}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M11.1468 9.96409C11.0552 9.95492 10.9452 9.95492 10.8443 9.96409C8.66268 9.89075 6.93018 8.10325 6.93018 5.90325C6.93018 3.65742 8.74518 1.83325 11.0002 1.83325C13.246 1.83325 15.0702 3.65742 15.0702 5.90325C15.061 8.10325 13.3285 9.89075 11.1468 9.96409Z"
                      stroke="#019DE0"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.56316 13.3467C4.34483 14.8317 4.34483 17.2517 6.56316 18.7276C9.084 20.4142 13.2182 20.4142 15.739 18.7276C17.9573 17.2426 17.9573 14.8226 15.739 13.3467C13.2273 11.6692 9.09316 11.6692 6.56316 13.3467Z"
                      stroke="#019DE0"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  Account
                </a>
              </Link>
            ) : (
              <Link onClick={logotop} to="/login">
                <a
                  className="btn-acc"
                  href="#"
                  onClick={() => {
                    handleClosee();
                    handleSignInClick();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M11.1468 9.96409C11.0552 9.95492 10.9452 9.95492 10.8443 9.96409C8.66268 9.89075 6.93018 8.10325 6.93018 5.90325C6.93018 3.65742 8.74518 1.83325 11.0002 1.83325C13.246 1.83325 15.0702 3.65742 15.0702 5.90325C15.061 8.10325 13.3285 9.89075 11.1468 9.96409Z"
                      stroke="#019DE0"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.56316 13.3467C4.34483 14.8317 4.34483 17.2517 6.56316 18.7276C9.084 20.4142 13.2182 20.4142 15.739 18.7276C17.9573 17.2426 17.9573 14.8226 15.739 13.3467C13.2273 11.6692 9.09316 11.6692 6.56316 13.3467Z"
                      stroke="#019DE0"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  sign In
                </a>
              </Link>
            )}
            {/* <Link to="/login">
            <a href="#" className="btn-signinn">Sign In</a></Link> */}
          </div>
          <div className="bottom-content">
            <div className="main-heading ">
              <h6>Browse Categories</h6>
            </div>
            <ul class="itemsss">
              {shopcategory.map((data, index) => {
                return (
                  <a
                    onClick={() => {
                      setCatState({
                        cat: data?.id,
                        type: "cat",
                      });
                      handleClosee();
                    }}
                    href={"/all-categories?category=" + encodeURIComponent(data?.name)}
                  // to={{
                  //   pathname: "/all-categories",
                  //   state: {
                  //     cat: data?.id,
                  //     type: "cat",
                  //   },
                  // }}
                  >
                    <li>
                      <a onClick={logotop} class="dropdown-item">
                        {data?.name}
                      </a>{" "}
                    </li>{" "}
                  </a>
                );
              })}
              {/* <a href="https://pharmacy.quick.shop" target="_blank"
                  >
                    <li>
                      <a onClick={logotop} class="dropdown-item">
                       Pharmacy
                      </a>{" "}
                    </li>{" "}
                  </a> */}
            </ul>
            <div className="main-heading mt-3">
              <h6>Others</h6>
            </div>
            <ul class="itemsss">
              <li>
                <Link onClick={logotop} to="/contact-us">
                  <a class="dropdown-item" href="#" onClick={handleClosee}>
                    <img
                      src="\categories-icons\sms.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    Contact
                  </a>
                </Link>
              </li>
              <li>
                <Link onClick={logotop} to="/corporate-orders">
                  <a class="dropdown-item" href="#" onClick={handleClosee}>
                    <img
                      src="\categories-icons\briefcase.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    Corporate Orders
                  </a>
                </Link>
              </li>
              <li>
                <Link onClick={logotop} to="/about-us">
                  <a class="dropdown-item" href="#" onClick={handleClosee}>
                    <img
                      src="\categories-icons\info-circle.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    About
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div className="navmodalouterdiv">
        <Modal show={isModalVisible} onHide={hideModal}
          centered
          className="navmodalouterdiv"

        >
          <Modal.Header closeButton className="removemodalhead">
            <Modal.Title>Empty your cart

              {/*               
              <span> <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path d="M13.0003 24.6459C6.57616 24.6459 1.35449 19.4242 1.35449 13.0001C1.35449 6.57591 6.57616 1.35425 13.0003 1.35425C19.4245 1.35425 24.6462 6.57591 24.6462 13.0001C24.6462 19.4242 19.4245 24.6459 13.0003 24.6459ZM13.0003 2.97925C7.47533 2.97925 2.97949 7.47508 2.97949 13.0001C2.97949 18.5251 7.47533 23.0209 13.0003 23.0209C18.5253 23.0209 23.0212 18.5251 23.0212 13.0001C23.0212 7.47508 18.5253 2.97925 13.0003 2.97925Z" fill="#264653"/>
  <path d="M9.93381 16.8785C9.72798 16.8785 9.52215 16.8026 9.35965 16.6401C9.04548 16.326 9.04548 15.806 9.35965 15.4918L15.4913 9.36014C15.8055 9.04597 16.3255 9.04597 16.6396 9.36014C16.9538 9.6743 16.9538 10.1943 16.6396 10.5085L10.508 16.6401C10.3563 16.8026 10.1396 16.8785 9.93381 16.8785Z" fill="#264653"/>
  <path d="M16.0655 16.8785C15.8596 16.8785 15.6538 16.8026 15.4913 16.6401L9.35965 10.5085C9.04548 10.1943 9.04548 9.6743 9.35965 9.36014C9.67382 9.04597 10.1938 9.04597 10.508 9.36014L16.6396 15.4918C16.9538 15.806 16.9538 16.326 16.6396 16.6401C16.4771 16.8026 16.2713 16.8785 16.0655 16.8785Z" fill="#264653"/>
</svg></span>  */}

            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">

            <div className="">
              <h4 className="removemodbody">
                Are you sure you want to empty your cart?
              </h4>
            </div>

          </Modal.Body>
          <Modal.Footer className="remofotr mb-0">
            <button className="closebbtths" onClick={hideModal}>
              Close
            </button>
            <button className="closebbtthsredd" onClick={removeall}>
              Yes i’m sure
            </button>
          </Modal.Footer>
        </Modal>

      </div>

    </>
  );
};

export default Navbar;
