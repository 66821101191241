import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import "./homeshop.scss";
// import * as React from 'react';
// import dayjs, { Dayjs } from "dayjs";

import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import "react-toastify/dist/ReactToastify.css";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Checkbox from "@mui/material/Checkbox";
import Cart from "./Cart";
import axios from "axios";
import Environment from "../utils/environment";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "react-toastify/dist/ReactToastify.css";

const Homeshopnew = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showlocation, setShowlocation] = useState(false);
  // const handleShowlocation = () => {
  //   setShowlocation(true);
  // };
  // const handleCloselocation = () => {
  //   setShowlocation(false);
  // };
  // const [location, setLocation] = useState({
  //   center: {
  //     lat: 33.6844,
  //     lng: 73.0479,
  //   },
  //   zoom: 13,
  // });

  const api_url = Environment.base_url2;
  const api_url2 = Environment.base_url;

  const history = useHistory();
  const userId = localStorage.getItem("userId");
  const [loader, setLoader] = useState(false);
  const [select, setSelect] = useState(false);

  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems"))
  );
  const [isAddressEdit, setIsAddressEdit] = useState(false);
  const [addresserrors, setAddressErrors] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [checkoutAddressId, setCheckoutAddressId] = useState("");
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [addressTypeId, setAddressTypeId] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("Islamabad");
  const [state, setState] = useState("");
  const [nearBy, setNearBy] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [name, setName] = useState("");
  const [confirmAddress, setConfirmAddress] = useState("");
  const [checkactive, setCheckActive] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [activeState, setActiveState] = useState("");

  // const [showlocation, setShowlocation] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");

  const [couponCode, setCouponCode] = useState("");
  const [shippingFee, setShippingFee] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cityerrorss, setCityErrorss] = useState("");
  const [nameerrorss, setNameErrorss] = useState("");
  const [mobileerrorss, setMobileErrorss] = useState("");
  const [val, setVal] = useState(null);
  const [showdelete, setShowdelete] = useState(false);

  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = () => setShowdelete(true);

  const handleShowlocation = () => {
    setShowlocation(true);
  };
  const [location, setLocation] = useState({
    center: {
      lat: 33.6844,
      lng: 73.0479,
    },
    zoom: 13,
  });

  const [suggestions, setSuggestions] = useState([]);

  const deleteAddress = async () => {
    setLoader(true);
    const config = {
      method: "delete",
      url: `${api_url}/address/${addressId}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        getAddressList();
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setAddress(searchValue);

    if (!searchValue) {
      setSuggestions([]);
      return;
    }

    if (window.google && window.google.maps) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: searchValue,
          types: ["address"],
          componentRestrictions: {
            country: "pk",
          },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // Filter suggestions for Islamabad and Rawalpindi
            const filteredSuggestions = predictions.filter(
              (suggestion) =>
                suggestion.description.includes("Islamabad") ||
                suggestion.description.includes("Rawalpindi")
            );
            setSuggestions(filteredSuggestions);
          } else {
            setSuggestions([]);
          }
        }
      );
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion) {
      const selectedPlaceId = suggestion?.place_id;
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      placesService.getDetails(
        { placeId: selectedPlaceId },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const location = place.geometry.location;
            setLocation({
              center: {
                lat: location.lat(),
                lng: location.lng(),
              },
              zoom: 15,
            });
          } else {
          }
        }
      );
    }
    setAddress(suggestion.description);
    setSuggestions([]);
  };

  const [clickedLocation, setClickedLocation] = useState(null);
  const handleCloselocation = () => {
    setShowlocation(false);
    setAddressId("");
    setIsAddressEdit(false);
    setAddressErrors("");
    setAddress("");
    setCity("Islamabad");
    setName("");
    setMobileNumber("");
    setNearBy("");
    setPostalCode("");
    setState("");
    setActiveState("");
    setCityErrorss("");
    setNameErrorss("");
    setMobileErrorss("");
  };

  const Marker = () => <div className="marker">📍</div>;

  const Validateaddress = () => {
    if (address.length === 0) {
      setAddressErrors("Address is Required");
    }
    if (city.length === 0) {
      setCityErrorss("City is Required");
    }
    if (name.length === 0) {
      setNameErrorss("Name is Required");
    }
    if (mobileNumber.length === 0) {
      setMobileErrorss("Mobile number is Required");
    } else if (mobileNumber.length != 11) {
      setMobileErrorss("Mobile number is invalid");
    }
  };

  const addNewAddress = async () => {
    Validateaddress();
    if (mobileNumber?.length === 11 && select === true) {
      handleCloselocation();
      setLoader(true);
      const config = {
        method: "post",
        url: api_url + "/address",
        data: {
          address: address,
          city: city,
          ...(state && { state: state }),
          ...(nearBy && { nearByPlace: nearBy }),
          ...(postalCode && { postalCode: postalCode }),
          name: name,
          contactNo: mobileNumber,
          latitude: location?.center?.lat,
          longitude: location?.center.lng,
          addressTypeId: addressTypeId,
          customAddress: confirmAddress,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          getAddressList();
          handleCloselocation();
          toast.success(res?.data?.message);
          setLoader(false);
          setAddress("");
          setCity("Islamabad");
          setName("");
          setMobileNumber("");
          setConfirmAddress("");
          setNearBy("");
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            // toast.error(err?.response?.data?.message, {
            //   position: "bottom-left",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
        });
    } else {
      // setAddressErrors("Select Address from suggestion");
    }
  };

  const editAddress = async () => {
    Validateaddress();
    if (mobileNumber?.length === 11) {
      handleCloselocation();
      setLoader(true);
      window.scrollTo(0, 0);
      const config = {
        method: "patch",
        url: api_url + "/address/" + addressId,
        data: {
          address: address,
          city: city,
          ...(state && { state: state }),
          ...(nearBy && { nearByPlace: nearBy }),
          ...(postalCode && { postalCode: postalCode }),
          name: name,
          contactNo: mobileNumber,
          latitude: location?.center?.lat,
          longitude: location?.center.lng,
          addressTypeId: addressTypeId,
          customAddress: confirmAddress,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          handleCloselocation();
          toast.success(res?.data?.message);
          getAddressList();
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            // toast.error(err?.response?.data?.message, {
            //   position: "bottom-left",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
        });
    }
  };

  const handleMapClick = (event) => {
    setAddressErrors("");
    setSelect(true);
    setLocation({
      center: {
        lat: event.lat,
        lng: event.lng,
      },
      zoom: 15,
    });
    const clickedLat = event.lat;
    const clickedLng = event.lng;
    setClickedLocation({ lat: clickedLat, lng: clickedLng });
    Geocode.setApiKey("AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0");

    Geocode.fromLatLng(event.lat, event.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        setAddress(address);
      },
      (error) => {
      }
    );

    // Do something with the location data, e.g. update state
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(false);
    cartPrice(cartItems);
  }, []);
  const [value, setValue] = useState(dayjs());
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [deliveryType, setDeliveryType] = useState("standard");
  const [totalPrice, setTotalPrice] = useState(0);
  const criteria = localStorage.getItem("criteria");


  useEffect(() => {
    if (val !== "null" || val !== "undefined") {
      getAddressList();
    }
  }, [val]);

  const [defaults, setDefault] = useState("");

  const getAddressList = async () => {
    setLoader(true);
    await axios
      .get(`${api_url}/address`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setAddressList(res?.data?.data);
        calculateDeliveryFee(res?.data?.data[0]);
        // setCheckoutAddressId(res?.data?.data[0]?.id);

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getAddressTypeList = async () => {
    // Validateaddress();
    setLoader(true);
    const config = {
      method: "get",
      url: `${api_url}/address/types`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setAddressTypeList(resData);
        setAddressTypeId(resData[0]?.id);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const getAddressDetails = async (id) => {
    setLoader(true);
    if (id) {
      const config = {
        method: "get",
        url: `${api_url}/address/${id}`,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;

          setAddress(resData?.addressDetails?.address);
          setConfirmAddress(resData?.addressDetails?.customAddress);
          setCity(resData?.addressDetails?.city);
          setNearBy(resData?.addressDetails?.nearByPlace);
          setPostalCode(resData?.addressDetails?.postalCode);
          setMobileNumber(resData?.addressDetails?.contactNo);
          setName(resData?.addressDetails?.name);
          setState(resData?.addressDetails?.state);
          for (let i = 0; i < resData?.addressTypes.length; i++) {
            if (resData?.addressTypes[i]?.userAddress != null) {
              setActiveState(resData?.addressTypes[i]?.addressTypeId);
            }
          }
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else {
            toast.error(err?.response?.data?.message, {
              position: "top-right",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    }
  };

  const calculateDeliveryFee = async (item) => {
    if (item) {
      const config = {
        method: "post",
        url: `${api_url2}/orders/delivery-charges`,
        data: {
          latitude: item?.latitude,
          longitude: item?.longitude,
          total: totalPrice,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          setCheckoutAddressId(item?.id);
          setSelectedAddress(item);
          setShippingFee(resData?.deliveryCharges);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else {
            // toast.error(err?.response?.data?.message, {
            //   position: "top-right",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
        });
    }
  };

  const [eligible, setEligible] = useState("");

  const [discount, setDiscount] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [errorcoupon, setErrorCoupon] = useState("");
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  const verifyCoupon = async () => {
    if (couponCode.length != 0) {
      const config = {
        method: "post",
        url: `${api_url2}/discounts/verify-coupon`,
        data: {
          code: couponCode,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          console.log(res?.data?.data, "aabiii");
          setLoader(false);
          if (resData.criteria > totalPrice) {
            // toast.error(`Please shope atleast this amount ${resData.criteria} `, {
            //   position: "top-right",
            //   autoClose: 2000,
            // });
            toast.warning(`Please shop at least this amount ${resData.criteria}`, {
              position: "top-right",
              autoClose: 2000,
            });
            setCouponCode("");
            setIsValidCoupon(false);
          } else {
            setEligible("Coupon Discount has been applied!.");
            // let a = totalPrice - resData?.discountAmount
            // setTotalPrice(a)
            setDiscount(resData?.discountAmount);
            localStorage.setItem("couponCode", couponCode);
            localStorage.setItem("discountAmount", resData?.discountAmount);
            localStorage.setItem("criteria", resData?.criteria);
            localStorage.setItem("type", resData?.type);
            setIsValidCoupon(true);
            setDisableInput(true);
            setDisableApplyButton(true);
            setCouponCode("");
            window.location.reload();
            // setDissable(true)
            // toast.success("Code is verified and total price is discounted", resData.criteria, {
            //   position: "top-right",
            //   autoClose: 2000,
            // });
          }
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else {
            if (err?.response?.data?.message?.includes("limit reached")) {
              setErrorCoupon("User limit has been reached");
            } else {
              setErrorCoupon("Invalid Promo Code");
            }
            // toast.error(err?.response?.data?.message, {
            //   position: "top-right",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
          setIsValidCoupon(false);
          setCouponCode("");
        });
    } else {
      setErrorCoupon("Please enter your promo code");
    }
  };

  const handleRemoveCoupon = () => {
    localStorage.removeItem("couponCode");
    localStorage.removeItem("discountAmount");
    localStorage.removeItem("criteria");
    localStorage.removeItem("type");
    setDiscountAmount(0);
    setIsValidCoupon(false);
    setDisableInput(false);
    setDisableApplyButton(false);
    setCouponCode("");
    window.location.reload();
  };



  const handleDecrease = (index, e) => {
    e.preventDefault();
    let tempCart = [...cartItems];
    let tempCartAmount = tempCart[index]?.cartAmount - 1;
    tempCart[index] = { ...tempCart[index], cartAmount: tempCartAmount };
    setCartItems(tempCart);
    cartPrice(tempCart);
    localStorage.setItem("cartItems", JSON.stringify(tempCart));
  };


  const handleIncrease = (index, e, item) => {
    e.preventDefault();
    let tempCart = [...cartItems];
    let tempCartItem = { ...tempCart[index] };
    const quantity = item?.quantity || item?.product?.quantity;
    const limit = item?.outOfStockThreshold || item?.product?.outOfStockThreshold;
    if (tempCartItem.cartAmount < quantity - limit) {
      let tempCartAmount = tempCartItem.cartAmount + 1;
      tempCart[index] = { ...tempCartItem, cartAmount: tempCartAmount };
      setCartItems(tempCart);
      cartPrice(tempCart);
      localStorage.setItem("cartItems", JSON.stringify(tempCart));
    } else {
      toast.error("Max limit reached");
    }
  };

  const handleCartItemDelete = (val) => {
    let cartCopy = [...cartItems];
    setCartCount(cartCopy?.length - 1);
    cartCopy = cartCopy.filter((item) => item?.id != val?.id);
    setCartItems(cartCopy);
    cartPrice(cartCopy);
    localStorage.setItem("cartItems", JSON.stringify(cartCopy));
    window.location.reload();
  };
  const cartPrice = (cart) => {
    let price = 0;
    for (let i = 0; i < cart?.length; i++) {
      if (cart[i]?.discountedProducts && cart[i]?.discountedProducts[0]?.discount !== undefined) {
        price =
          Math.trunc(
            cart[i]?.price -
            cart[i]?.price * (cart[i]?.discountedProducts[0]?.discount / 100)
          ) *
          cart[i]?.cartAmount +
          price;
      } else if (cart[i]?.discount !== undefined) {
        price =
          Math.trunc(
            cart[i]?.product?.price -
            cart[i]?.product?.price * (cart[i]?.discount / 100)
          ) *
          cart[i]?.cartAmount +
          price;
      } else {
        price = cart[i]?.price * cart[i]?.cartAmount + price;
      }
    }
    setTotalPrice(price);
    if (criteria > price) {
      handleRemoveCoupon();
    }
  };

  //   const savedLocationString = localStorage.getItem('location');
  //   const savedLocation = JSON.parse(savedLocationString);
  //  console.log(savedLocation);
  useEffect(() => {
    // const savedLocationString = localStorage.getItem("location");
    // const savedLocation = JSON.parse(savedLocationString);
    // if (savedLocation) {
    //   setSelectedAddress(savedLocation);
    // } else if (addressList.length > 0) {
    //   setSelectedAddress(addressList[0].address);
    // } else {
    //   setSelectedAddress("Please select an address");
    // }
  }, []);

  let a = localStorage.getItem("couponCode");

  useEffect(() => {
    const res = localStorage.getItem("accessToken");

    if (res) {
      setUserIsLoggedIn(true);
      setVal(res);
    }
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const handleSignInClick = () => {
    const currentRoute = history.location.pathname + history.location.search;
    localStorage.setItem('currentRoute', currentRoute);
  };

  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <Link to="/">
                <li class="breadcrumb-item">
                  <a href="#">Home /</a>
                </li>
              </Link>
              <Link to="/faqs">
                <li class="breadcrumb-item">
                  <a href="#">FAQS</a>
                </li>
              </Link>
            </ol>
          </nav>
        </div>
      </section>
      <section className="homesshop mt-5">
        <div className="container-fluid ">
          <div className="desktoponle_mobileviewdisplaynone">
            <div className="row">
              <div className="col-11 m-auto p-0">
                <div className="row">
                  <div className="col-xl-7 col-md-12 m">
                    <div className="left_cover ">
                      <div className="left_top">
                        <h1 className="nbads">Delivery Tgdfgdfgdfgype</h1>
                        <div className="cards_covertop">
                          <div className="inercardss">
                            <a href="#">
                              <img
                                src="\assets\homeshopnew\truck-fast.svg"
                                className="img-fluid"
                              />
                            </a>
                            <h5 className="decvbliver">Standard Delivery</h5>
                            <p className="delivercx">We deliver in 1-2 hours</p>
                          </div>
                          {/* <div className="inercardss">
                            <a href="#">
                              <img src="\assets\homeshopnew\shop.svg" className="img-fluid" />
                            </a>
                            <h5 className="decvbliver">
                              Pickup
                            </h5>
                            <p className="delivercx">
                              Pickup from the store
                            </p>
                          </div> */}
                          {/* <div className="inercardss">
                            <a href="#">
                              <img src="\assets\homeshopnew\truck-fast.svg" className="img-fluid" />
                            </a>
                            <h5 className="decvbliver">
                              Deliver Later
                            </h5>
                            <p className="delivercx">
                              Select delivery date & time
                            </p>
                          </div> */}
                        </div>
                      </div>

                      <div className="sec_left">
                        <div className="dathdc">
                          <h5 className="delibgf">Deliver to</h5>
                          {userIsLoggedIn ? (
                            <h5
                              className="asssddress"
                              onClick={() => {
                                handleShowlocation();
                                getAddressTypeList();
                              }}
                            >
                              + Address
                            </h5>
                          ) : (
                            <Link to="/login">
                              <h5 onClick={handleSignInClick} className="asssddress">Login to add address</h5></Link>
                          )}
                        </div>
                        <div className="cards_coversec">
                          {addressList?.length > 0 ? (
                            addressList?.map((item) => {
                              return (
                                <>
                                  <div
                                    onClick={() => {
                                      setCheckActive(item);
                                      calculateDeliveryFee(item);
                                    }}
                                    className={
                                      checkactive === item
                                        ? "inercardss_sec1"
                                        : "inercardss_sec"
                                    }
                                  >
                                    <div className="inercardflex">
                                      <h5 className="decvbliverss">
                                        {item?.name}
                                      </h5>
                                      <a href="#">
                                        <img
                                          onClick={() => {
                                            setIsAddressEdit(true);
                                            setAddressId(item?.id);
                                            handleShowlocation();
                                            getAddressTypeList();
                                            getAddressDetails(item?.id);
                                          }}
                                          src="\assets\homeshopnew\edit-2.svg"
                                          className="img-fluid"
                                        />
                                        <img
                                          onClick={(e) => {
                                            setAddressId(item?.id);
                                            handleShowdelete();
                                          }}
                                          src="/assets/homeshopnew/trash.svg"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                    <p className="delivercxs">
                                      {item?.address}
                                    </p>
                                    <p className="delivercxv">
                                      {item?.addressType?.name}{" "}
                                      <span className="">
                                        {item?.contactNo}
                                      </span>
                                    </p>
                                  </div>
                                </>
                              );
                            })
                          ) : loader ? (
                            <>
                              <div className="text-center py-4">
                                {<Spinner animation="border" variant="info" />}
                              </div>
                            </>
                          ) : (
                            <h5>No Addresses</h5>
                          )}
                        </div>
                      </div>
                      {/* <div className="left_top">
                        <h1 className="nbads">Delivery Type</h1>
                        <div className="cards_coversec"></div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-xl-5 col-md-12  ">
                    <div className="right_coverhead ">
                      <div className="ddarcc">
                        <h5 className="adarr">Your Order</h5>
                      </div>
                      <div className="  largesecscrolydiv">
                        {cartItems?.length > 0 ? (
                          cartItems?.map((item, index) => {
                            return (
                              <>
                                <div className="inersoclyrow">
                                  <div className="leftsside">
                                    <div className="leftssideimgg">
                                      <img
                                        src={
                                          item?.product?.imageUrl ||
                                          item?.imageUrl
                                        }
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="leftssidetext">
                                      <p className="milkiiparaa">
                                        {item?.product?.name || item?.name}
                                      </p>
                                      <p className="milkiiparaa">
                                        Rs.{" "}
                                        {Math.trunc(
                                          (item?.discountedProducts && item?.discountedProducts[0]?.discount !== undefined) ?
                                            Math.trunc(
                                              item?.price - (item?.price * (item?.discountedProducts[0]?.discount / 100))
                                            ) :
                                            (item?.discount !== undefined) ?
                                              Math.trunc(
                                                item?.product?.price - (item?.product?.price * (item?.discount / 100))
                                              ) :
                                              item?.price
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="rightcounter">
                                    {item?.cartAmount === 1 ? (
                                      <span className="">
                                        <a
                                          onClick={(e) =>
                                            handleCartItemDelete(item)
                                          }
                                          href="#"
                                        >
                                          <img
                                            src="\assets\homeshopnew\trash.svg"
                                            className="img-fluid"
                                          />
                                        </a>
                                      </span>
                                    ) : (
                                      <span className="">
                                        <a
                                          onClick={(e) =>
                                            handleDecrease(index, e)
                                          }
                                          href="#"
                                        >
                                          <img
                                            src="\assets\homeshopnew\minus.svg"
                                            className="img-fluid"
                                          />
                                        </a>
                                      </span>
                                    )}
                                    <span className="diggitts">
                                      {item?.cartAmount}{" "}
                                    </span>
                                    <span className="">
                                      <a
                                        onClick={(e) =>
                                          handleIncrease(index, e, item)
                                        }
                                        href="#"
                                      >
                                        <img
                                          src="\assets\homeshopnew\add.svg"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <Redirect to="/cart" />
                        )}
                      </div>
                    </div>
                    <div className="secleftbtmm">
                      <div className="felidinput">
                        <input
                          type="text"
                          className={`form-control ${errorcoupon ? "error-input-homeshop" : ""
                            }`}
                          placeholder="Coupon code"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          value={couponCode}
                          onChange={(e) => {
                            setCouponCode(e.target.value);
                            setErrorCoupon();
                          }}
                        />
                        <button
                          class="btn  apply"
                          type="button"
                          id="button-addon2"
                          onClick={verifyCoupon}
                          disabled={isValidCoupon || a}
                        >
                          Apply
                        </button>
                      </div>
                      {isValidCoupon ||
                        (a && (
                          <div className="promoheadingssd">
                            <p className="promoheadcxsd">Promo Code Applied</p>
                            <p className="promoheadcxsd_normal">
                              Note: One discount can be redeem per order
                            </p>
                          </div>
                        ))}
                      {errorcoupon && (
                        <p className="homeshop-validations">{errorcoupon}</p>
                      )}
                    </div>
                    <div className="thrdrightbutm">
                      <div className="inerthdbtm">
                        <p className="lakjhalkfja">Subtotal</p>
                        <p className="lakjhalkfja">Rs.{totalPrice}</p>
                      </div>
                      {isValidCoupon ||
                        (a && (
                          <div className="inerthdbtm">
                            <p className="lakjhalkfja">
                              Discount{" "}
                              <span className="discount">
                                {a}
                                <img
                                  onClick={handleRemoveCoupon}
                                  src="\assets\homeshopnew\close-circle.svg"
                                  className="img-fluid ps-2 pb-1"
                                />
                              </span>
                            </p>
                            {localStorage.getItem("discountAmount") && (
                              <p className="lakjhalkfja">
                                {localStorage.getItem("type") === "percentage" ? "%" : "Rs:"}{localStorage.getItem("discountAmount")}
                              </p>
                            )}
                          </div>
                        ))}
                      <div className="inerthdbtm1">
                        <p className="lakjhalkfja">Shipping</p>
                        <p className="lakjhalkfjas">
                          {selectedAddress ? (
                            <>{selectedAddress?.address}</>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                      <div className="inerthdbtm">
                        <p className="lakjhalkfja">Total</p>
                        <p className="lakjhalkfja">
                          Rs.{" "}
                          {localStorage.getItem("discountAmount")
                            ? localStorage.getItem("type") === "percentage"
                              ? totalPrice - (totalPrice * parseInt(localStorage.getItem("discountAmount")) / 100)
                              : totalPrice - parseInt(localStorage.getItem("discountAmount"))
                            : totalPrice}
                        </p>
                      </div>
                    </div>
                    <div className="thrdchecoutbtn">
                      {selectedAddress && val ? (
                        <Link
                          to={{
                            pathname: "/cart-checkout",
                            state: {
                              deliveryTime: value,
                              cartItems: cartItems,
                              deliveryType: deliveryType,
                              addressId: checkoutAddressId,
                              shippingFee: shippingFee,
                              latitude: selectedAddress?.latitude,
                              longitude: selectedAddress?.longitude,
                              discountCoupon: localStorage.getItem(
                                "discountAmount"
                              )
                                ? localStorage.getItem("couponCode")
                                : "",
                              totalPrice: totalPrice,
                              discount: localStorage.getItem("discountAmount"),
                            },
                          }}
                        >
                          <button className="thrdchecoutbtn_endd">
                            Proceed to checkout
                          </button>
                        </Link>
                      ) : (
                        <div>
                          <p className="login-check-homeshop text-danger mb-2">
                            {userIsLoggedIn ? (
                              <div>
                                <p className="add-adress">
                                  Please Add Address{" "}
                                  <span
                                    onClick={() => {
                                      handleShowlocation();
                                      getAddressTypeList();
                                    }}
                                    className="here"
                                  >
                                    Here!
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <Link to="/login">
                                <button onClick={handleSignInClick} className="thrdchecoutbtn_enddd">
                                  Login To Proceed
                                </button>
                              </Link>
                            )}
                          </p>

                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileview_mobileviewdisplaynone ">
            <div className="row">
              <div className="col-11 m-auto p-0">
                <button onClick={goBack} className="backktopage ">
                  <img
                    src="\assets\homeshopnew\arrow-left.svg"
                    className="img-fluid"
                  />{" "}
                  back
                </button>

                <div className="row">
                  {/* <h5 className="nbadsccxx">Delivery Type</h5> */}
                  <div className="col-xl-7 col-md-12 p-0">
                    <div className="left_cover ">
                      <div className="left_top">
                        <h1 className="nbads">Delivery Type</h1>
                        <div className="cards_covertop">
                          <div className="inercardss">
                            <a href="#">
                              <img
                                src="\assets\homeshopnew\truck-fast.svg"
                                className="img-fluid"
                              />
                            </a>
                            <h5 className="decvbliver">Standard Delivery</h5>
                            <p className="delivercx">We deliver in 1-2 hours</p>
                          </div>
                          {/* <div className="inercardss">
                              <a href="#">
                                <img
                                  src="\assets\homeshopnew\shop.svg"
                                  className="img-fluid"
                                />
                              </a>
                              <h5 className="decvbliver">Pickup</h5>
                              <p className="delivercx">Pickup from the store</p>
                            </div>
                            <div className="inercardss">
                              <a href="#">
                                <img
                                  src="\assets\homeshopnew\truck-fast.svg"
                                  className="img-fluid"
                                />
                              </a>
                              <h5 className="decvbliver">Deliver Later</h5>
                              <p className="delivercx">
                                Select delivery date & time
                              </p>
                            </div> */}
                        </div>
                      </div>

                      <div className="sec_left">
                        <div className="dathdc">
                          <h5 className="delibgf">Deliver to</h5>
                          {userIsLoggedIn ? (
                            <h5
                              className="asssddress"
                              onClick={() => {
                                handleShowlocation();
                                getAddressTypeList();
                              }}
                            >
                              + Address
                            </h5>
                          ) : (
                            <Link to="/login">
                              <h5 onClick={handleSignInClick} className="asssddress">Login to add adress</h5></Link>
                          )}
                        </div>
                        <div className="cards_coversec">
                          {addressList?.length > 0 ? (
                            addressList?.map((item) => {
                              return (
                                <>
                                  <div
                                    onClick={() => {
                                      setCheckActive(item);
                                      calculateDeliveryFee(item);
                                    }}
                                    //  className="inercardss_sec"
                                    className={
                                      checkactive === item
                                        ? "inercardss_sec1"
                                        : "inercardss_sec"
                                    }
                                  >
                                    <div className="inercardflex">
                                      <h5 className="decvbliverss">
                                        {item?.name}
                                      </h5>
                                      <div
                                      >
                                        <img
                                          onClick={() => {
                                            setIsAddressEdit(true);
                                            setAddressId(item?.id);
                                            handleShowlocation();
                                            getAddressTypeList();
                                            getAddressDetails(item?.id);
                                          }}
                                          src="\assets\homeshopnew\edit-2.svg"
                                          className="img-fluid"
                                        />
                                        <img
                                          onClick={(e) => {
                                            setAddressId(item?.id);
                                            handleShowdelete();
                                          }}
                                          src="/assets/homeshopnew/trash.svg"
                                          className="img-fluid"
                                        />
                                      </div>

                                    </div>
                                    <p className="delivercxs">
                                      {item?.address}
                                    </p>
                                    <p className="delivercxv">
                                      {item?.addressType?.name}{" "}
                                      <span className="">
                                        {item?.contactNo}
                                      </span>
                                    </p>
                                  </div>
                                </>
                              );
                            })
                          ) : loader ? (
                            <>
                              <div className="text-center py-4">
                                {<Spinner animation="border" variant="info" />}
                              </div>
                            </>
                          ) : (
                            <h5>No Addresses</h5>
                          )}
                        </div>
                      </div>
                      {/* <div className="left_top">
                          <h1 className="nbads">Delivery Type</h1>
                          <div className="cards_coversec"></div>
                        </div> */}
                    </div>
                  </div>

                  <div className="col-xl-5 col-md-12 p-0">
                    <div className="right_coverhead ">
                      <div className="ddarcc">
                        <h5 className="adarr">Your Order</h5>
                      </div>
                      <div className="">
                        {cartItems?.length > 0 ? (
                          cartItems?.map((item, index) => {
                            return (
                              <>
                                <div className="inersoclyrow">
                                  <div className="leftsside">
                                    <div className="leftssideimgg">
                                      <img
                                        src={
                                          item?.product?.imageUrl ||
                                          item?.imageUrl
                                        }
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="leftssidetext">
                                      <p className="milkiiparaa">
                                        {item?.product?.name || item?.name}
                                      </p>
                                      <p className="milkiiparaa">
                                        Rs.{" "}
                                        {Math.trunc(
                                          item?.product?.price -
                                          item?.product?.price *
                                          (item?.discount / 100)
                                        ) || item?.price}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="rightcounter">
                                    {item?.cartAmount === 1 ? (
                                      <span className="">
                                        <a
                                          onClick={(e) =>
                                            handleCartItemDelete(item)
                                          }
                                          href="#"
                                        >
                                          <img
                                            src="\assets\homeshopnew\trash.svg"
                                            className="img-fluid"
                                          />
                                        </a>
                                      </span>
                                    ) : (
                                      <span className="">
                                        <a
                                          onClick={(e) =>
                                            handleDecrease(index, e)
                                          }
                                          href="#"
                                        >
                                          <img
                                            src="\assets\homeshopnew\minus.svg"
                                            className="img-fluid"
                                          />
                                        </a>
                                      </span>
                                    )}

                                    <span className="diggitts">
                                      {item?.cartAmount}{" "}
                                    </span>
                                    <span className="">
                                      <a
                                        onClick={(e) =>
                                          handleIncrease(index, e, item)
                                        }
                                        href="#"
                                      >
                                        <img
                                          src="\assets\homeshopnew\add.svg"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <Redirect to="/cart" />
                        )}
                      </div>
                    </div>
                    <div className="secleftbtmm">
                      <div className="felidinput">
                        <input
                          type="text"
                          className={`form-control ${errorcoupon ? "error-input-homeshop" : ""
                            }`}
                          placeholder="Coupon code"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          value={couponCode}
                          onChange={(e) => {
                            setCouponCode(e.target.value);
                            setErrorCoupon();
                          }}
                        />
                        <button
                          class="btn  apply"
                          type="button"
                          id="button-addon2"
                          onClick={verifyCoupon}
                          disabled={isValidCoupon || a}
                        >
                          Apply
                        </button>
                      </div>
                      {isValidCoupon ||
                        (a && (
                          <div className="promoheadingssd">
                            <p className="promoheadcxsd">Promo Code Applied</p>
                            <p className="promoheadcxsd_normal">
                              Note: One discount can be redeem per order
                            </p>
                          </div>
                        ))}
                      {errorcoupon && (
                        <p className="homeshop-validations">{errorcoupon}</p>
                      )}
                    </div>
                    <div className="thrdrightbutm">
                      <div className="inerthdbtm">
                        <p className="lakjhalkfja">Subtotal</p>
                        <p className="lakjhalkfja">Rs.{totalPrice}</p>
                      </div>
                      {isValidCoupon ||
                        (a && (
                          <div className="inerthdbtm">
                            <p className="lakjhalkfja">
                              Discount{" "}
                              <span className="discount">
                                {a}
                                <img
                                  onClick={handleRemoveCoupon}
                                  src="\assets\homeshopnew\close-circle.svg"
                                  className="img-fluid ps-2 pb-1"
                                />
                              </span>
                            </p>
                            {localStorage.getItem("discountAmount") && (
                              <p className="lakjhalkfja">
                                {localStorage.getItem("type") === "percentage" ? "%" : "Rs:"}{localStorage.getItem("discountAmount")}
                              </p>
                            )}
                          </div>
                        ))}
                      <div className="inerthdbtm1">
                        <p className="lakjhalkfja">Shipping</p>
                        <p className="lakjhalkfjas">
                          {" "}
                          {selectedAddress ? (
                            <>{selectedAddress?.address}</>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                      <div className="inerthdbtm">
                        <p className="lakjhalkfja">Total</p>
                        <p className="lakjhalkfja">
                          Rs.{" "}
                          {localStorage.getItem("discountAmount")
                            ? localStorage.getItem("type") === "percentage"
                              ? totalPrice - (totalPrice * parseInt(localStorage.getItem("discountAmount")) / 100)
                              : totalPrice - parseInt(localStorage.getItem("discountAmount"))
                            : totalPrice}
                        </p>
                      </div>
                    </div>
                    <div className="thrdchecoutbtn">
                      {/* <button className="thrdchecoutbtn_endd">Checkout</button> */}
                      {selectedAddress && val ? (
                        <Link
                          to={{
                            pathname: "/cart-checkout",
                            state: {
                              deliveryTime: value,
                              cartItems: cartItems,
                              deliveryType: deliveryType,
                              addressId: checkoutAddressId,
                              shippingFee: shippingFee,
                              latitude: selectedAddress?.latitude,
                              longitude: selectedAddress?.longitude,
                              discountCoupon: localStorage.getItem(
                                "discountAmount"
                              )
                                ? localStorage.getItem("couponCode")
                                : "",
                              totalPrice: totalPrice,
                              discount: localStorage.getItem("discountAmount"),
                            },
                          }}
                        >
                          <button className="thrdchecoutbtn_endd">
                            Proceed to checkout
                          </button>
                        </Link>
                      ) : (
                        <div>
                          <p className="login-check-homeshop text-danger mb-2">
                            {!selectedAddress && userIsLoggedIn ? (
                              <div>
                                <p className="add-adress">
                                  Please Add Address{" "}
                                  <span
                                    onClick={() => {
                                      handleShowlocation();
                                      getAddressTypeList();
                                    }}
                                    className="here"
                                  >
                                    Here!
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <Link to="/login">
                                <button onClick={handleSignInClick} className="thrdchecoutbtn_enddd">
                                  Login To Proceed
                                </button>
                              </Link>
                            )}
                          </p>

                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Offcanvas className="offcaveshopnew" placement={'end'} show={showlocation} onHide={handleCloselocation} >
        <Offcanvas.Header closeButton>
        {isAddressEdit ? (
          <Offcanvas.Title>Edit Address</Offcanvas.Title>
          ) : (
          <Offcanvas.Title>Add new Address</Offcanvas.Title>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="innerinputs">
            <input
              type="text"
              placeholder="Enter Location"
              className="maininput"


            />
            <img src="\assets\homeshopnew\search-normal.svg" className="img-fluid scrhbarpostion" />
          </div>

<div className="map-div">
              <GoogleMapReact
                onClick={handleMapClick}
                bootstrapURLKeys={{
                  key: "AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0",
                }}
                defaultCenter={location.center}
                defaultZoom={location.zoom}
                yesIWantToUseGoogleMapApiInternals
              >
                {clickedLocation && (
                  <Marker lat={clickedLocation.lat} lng={clickedLocation.lng} />
                )}
              </GoogleMapReact>
            </div>

          <div className="formlocation">
            <div className="inputss">
              <div className="innerinputs">
                <input
                  type="text"
                  placeholder="Full Address"
                  className="maininput"
                />
              </div>
              <div className="innerinputs">

                <input
                  type="text"
                  placeholder="City"
                  className="maininput"


                />
                <input
                  type="text"
                  placeholder="State"
                  className="maininput"


                />
              </div>
              <div className="innerinputs">
                <input
                  type="text"
                  placeholder="Postal Code"
                  className="maininput"
                />
              </div>
              <div className="innerinputs">
                <input
                  type="text"
                  placeholder="Nearby Place (Optional)"
                  className="maininput"
                />
              </div>
              <div className="innerinputs">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="maininput"
                />
              </div>
              <div className="innerinputs">
                <input
                  type="text"
                  placeholder="Phone No."
                  className="maininput"
                />
                <button
                  className="savebtnveyyt"
                >
                  Verify
                </button>
              </div>
            </div>

            <div className="seclastbtnns-cover">

              <span className="seclastbtnns">
                <img
                  src="\Assests\home-2.svg"
                  alt="home"
                  className="spanicon"
                />
                Home
              </span>
              <span className="seclastbtnns">
                <img
                  src="\Assests\home-2.svg"
                  alt="home"
                  className="spanicon"
                />
                Office
              </span>
              <span className="seclastbtnns">
                <img
                  src="\Assests\home-2.svg"
                  alt="home"
                  className="spanicon"
                />
                Other
              </span>


            </div>
          </div>
          <button
            className="savebtn"

          >
            Save Address
          </button>

        </Offcanvas.Body>
      </Offcanvas> */}

      <Modal
        className="locationmodal"
        show={showlocation}
        onHide={handleCloselocation}
        centered
      >
        <Modal.Header closeButton>
          {isAddressEdit ? (
            <Modal.Title>Edit Address</Modal.Title>
          ) : (
            <Modal.Title>Add New Address</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="locationss">
            <div className="map-div">
              <GoogleMapReact
                onClick={handleMapClick}
                bootstrapURLKeys={{
                  key: "AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0",
                }}
                defaultCenter={location.center}
                defaultZoom={location.zoom}
                yesIWantToUseGoogleMapApiInternals
              >
                {clickedLocation && (
                  <Marker lat={clickedLocation.lat} lng={clickedLocation.lng} />
                )}
              </GoogleMapReact>
            </div>
            {/* <img src="\Assests\modalmap.png" alt="map" className="map" /> */}
            <div className="formlocation">
              {/* <h6 className="headmodal">
                {address + " " + city + " " + state}
              </h6> */}
              <p className="mt-3">Full Address<span class="spa1 text-danger">*</span></p>
              <input
                type="text"
                placeholder="Enter your full address or Pin your location"
                className={`maininput ${addresserrors ? "error-input-account" : ""
                  }`}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setAddressErrors("");
                  handleSearch(e);
                }}
              />

              {suggestions.length > 0 && (
                <ul className="suggestions">
                  {suggestions.map((suggestion, index) => (
                    <a>
                      {" "}
                      <li
                        key={index}
                        onClick={() => {
                          handleSuggestionClick(suggestion);
                          setSelect(true);
                        }}
                      >
                        {suggestion.description}
                      </li>
                    </a>
                  ))}
                </ul>
              )}
              {addresserrors ? (
                <p className="address-error-text">{addresserrors}</p>
              ) : null}

              <p className="mt-3">Full Address<span class="spa1 text-danger">*</span></p>
              <input
                type="text"
                placeholder="Enter your full address or Pin your location"
                className="maininput"
                value={confirmAddress}
                onChange={(e) => {
                  setConfirmAddress(e.target.value);
                  
                }}
              />

              <div className="inputss">
                <div className="innerinputs">
                  <p>City</p>
                  <select
                    className="maininput"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      // setCityErrorss("");
                    }}
                  >
                    <option value="Islamabad">Islamabad</option>
                    <option value="Rawalpindi">Rawalpindi</option>
                  </select>
                  {/* {cityerrorss ? (
                    <p className="homeshopp-validations">{cityerrorss}</p>
                  ) : null} */}
                </div>
                <div className="innerinputs">
                  <p>Nearby Place (Optional)</p>
                  <input
                    type="text"
                    placeholder="Nearby Place"
                    className="maininput"
                    value={nearBy}
                    onChange={(e) => setNearBy(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="inputss">
                <div className="innerinputs">
                  <p>Postal Code</p>
                  <input
                    type="text"
                    placeholder="Postal Code"
                    className="maininput"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div>
                <div className="innerinputs">
                  <p>Nearby Place (Optional)</p>
                  <input
                    type="text"
                    placeholder="Nearby Place "
                    className="maininput"
                    value={nearBy}
                    onChange={(e) => setNearBy(e.target.value)}
                  />
                </div>
              </div> */}
              <div className="inputss">
                <div className="innerinputs">
                  <p>Name<span class="spa1 text-danger">*</span></p>
                  <input
                    type="text"
                    placeholder="Name"
                    className={`maininput ${nameerrorss ? "error-input-homeshop " : ""
                      }`}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameErrorss("");
                    }}
                  />
                  {nameerrorss ? (
                    <p className="homeshopp-validations">{nameerrorss}</p>
                  ) : null}
                </div>
                <div className="innerinputs">
                  <p>Mobile Number<span class="spa1 text-danger">*</span></p>
                  <input
                    type="number"
                    placeholder="Mobile Number"
                    className={`maininput ${mobileerrorss ? "error-input-homeshop " : ""
                      }`}
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                      setMobileErrorss("");
                    }}
                  />
                  {mobileerrorss ? (
                    <p className="homeshopp-validations">{mobileerrorss}</p>
                  ) : null}
                </div>
              </div>
              <div className="spanbtns">
                {addressTypeList.length > 0
                  ? addressTypeList?.map((item) => {
                    return (
                      <>
                        <span
                          className={
                            activeState == item?.id ? "spanactive" : ""
                          }
                          onClick={() => {
                            setActiveState(item?.id);
                            setAddressTypeId(item?.id);
                          }}
                        >
                          <img
                            src="\Assests\home-2.svg"
                            alt="home"
                            className="spanicon"
                          />
                          {item?.name}
                        </span>
                      </>
                    );
                  })
                  : null}
              </div>
            </div>
            <button
              className="savebtn"
              disabled={loader}
              onClick={isAddressEdit ? editAddress : addNewAddress}
            >
              Save Address
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="deletemodal"
        show={showdelete}
        onHide={handleClosedelete}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete address ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            Are you sure you want to delete <br /> this address?
          </h4>
          <div className="deletebtns">
            <button
              className="dark"
              onClick={() => {
                deleteAddress();
                handleClosedelete();
              }}
            >
              Confirm
            </button>
            <button className="light" onClick={handleClosedelete}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Homeshopnew;
