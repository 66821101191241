import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import "./allcategoriesnew.scss";
import axios from "axios";
import Environment from "../utils/environment";
import MainLoader from "../utils/MainLoader";

const Allcategoriesnew = () => {

  const val = localStorage.getItem("accessToken");

  const history = useHistory();
  const api_url = Environment.base_url;
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const getCategories = async (val) => {
    setIsLoading(true);
    const response = await axios.get(
      `${api_url}/store/categories/minimal?offset=1&limit=50`,
      {
        headers: {
          Authorization: "Bearer " + val,
        },
      }
    );
    setCategoryData(response?.data?.data?.categories);
    setIsLoading(false);
  };

   console.log(categoryData,"lk");
  useEffect(() => {
   getCategories();
   window.scroll(0, 0);
  }, []);


  return (
    <>
      {isLoading && <MainLoader/>}
      <section className="main-category">
        <div className="container-fluid">
          <div className="row">
            <div className="col-11 m-auto p-0 ">
              <div className="mainoterdiv">
                <h3 className="allcad">
                  all categories
                </h3>
                <div className="allinonecard">
                  {categoryData?.map((data,index) => {
                    console.log(data);
                    return(
                      <>
                      <Link to={`/all-categories?category=${encodeURIComponent(data?.name)}`}>
                      <div key={index} className="card-browsesnd">
                      <div className="inner-imgss pulse-animation">
                        <img
                          src={data?.imageUrl}
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <h5>{data?.name}</h5>
                    </div>
                    </Link>
                    </>
                    )
                  })}
               
                
                </div>

              </div>

            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default Allcategoriesnew;
