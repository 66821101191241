import React from "react";
import "./bannernew.scss";
import { Link, useLocation, useHistory } from "react-router-dom";


const BannerNew = () => {
  const userIsLoggedIn = localStorage.getItem("accessToken") !== null;
  const locations = useLocation();
  const history = useHistory();
  const handleSignInClick = () => {
    const currentRoute = history.location.pathname + history.location.search;
    localStorage.setItem("currentRoute", currentRoute);
  };
  return (
    <>
      <section className="banner-new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="parent-items">
                <div className="first-item">
                <a
                          href={"/all-categories?category=Cooking%20Essentials"}

                        // to={{
                        //   pathname: "/all-categories",
                        //   state: {
                        //     cat: 8,
                        //     type: "cat",
                        //   },
                        // }}
                      >
                  <div className="first-box">
                    {/* <img
                      src="\bannernew\dummy-img4.png"
                      alt="img"
                      className="img-fluid item-image"
                    /> */}
                    <div className="inner-content">
                      <h6>everyday cooking essentials</h6>
                
                        <a >
                          {" "}

                          shop now
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10 17L15 12L10 7"
                              stroke="#ffffff"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                     
                    </div>
                  </div>
                  </a>
                  <a
                   href={"/all-categories?category=Pharmacy"} 

                        // to={{
                        //   pathname: "/all-categories",
                        //   state: {
                        //     cat: 4,
                        //     type: "cat",
                        //   },
                        // }}
                      >
                  <div className="second-box">
                    {/* <img
                      src="\bannernew\dummy-img2.png"
                      alt="img"
                      className="img-fluid item-image"
                    /> */}
                    <div className="inner-content">
                      <h6>Quick Pharmacy</h6>
                   
                        <a >

                          shop now
                        
                          <svg style={{ marginTop: "-1px" }} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M10 17.5L15 12.5L10 7.5" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      
                    </div>
                  </div>
                  </a>
                </div>
                <div className="second-item">
                {userIsLoggedIn ?   <Link  to="/account">
                  <div className="first-box">
                    {/* <img
                      src="\bannernew\dummy-img3.png"
                      alt="img"
                      className="img-fluid item-image"
                    /> */}
                    <div className="inner-content">
                      <h6>Get an exclusive discount with our promo code</h6>
                     

                        <a >
                           My Account{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10 17L15 12L10 7"
                              stroke="#ffffff"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                    
                    </div>
                  </div>
                  </Link> :   <Link onClick={handleSignInClick} to="/login">
                  <div className="first-box">
                    {/* <img
                      src="\bannernew\dummy-img3.png"
                      alt="img"
                      className="img-fluid item-image"
                    /> */}
                    <div className="inner-content">
                      <h6>Sign-up now to get exclusive discounts</h6>
                     

                        <a >
                          sign up{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10 17L15 12L10 7"
                              stroke="#ffffff"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                    
                    </div>
                  </div>
                  </Link>}
             
                  <a
                          href={"/all-categories?category=Health%20%26%20Beauty"}

                        // to={{
                        //   pathname: "/all-categories",
                        //   state: {
                        //     cat: 6,
                        //     type: "cat",
                        //   },
                        // }}
                      >
                  <div className="second-box">
                    {/* <img
                      src="\bannernew\dummy-img1.png"
                      alt="img"
                      className="img-fluid item-image"
                      style={{maxWidth: "400px", width: "100%"}}
                    /> */}
                    <div className="inner-content">
                      <h6>beauty care products you deserve</h6>
                   
                        <a >

                          shop now
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10 17L15 12L10 7"
                              stroke="#ffffff"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                     
                    </div>
                  </div>
                  </a>
                </div>
                <div className="third-item">
                <a
                          href={"/all-categories?category=Beverages"}

                        // to={{
                        //   pathname: "/all-categories",
                        //   state: {
                        //     cat: 2,
                        //     type: "cat",
                        //   },
                        // }}
                      >
                  <div className="first-box">
                    {/* <img
                      src="\bannernew\dummy-img5.png"
                      alt="img"
                      className="img-fluid item-image"
                    /> */}
                    <div className="inner-content">
                      <h6>BEVERAGES AND DRINKS</h6>
                     
                        <a >

                          shop now
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10 17L15 12L10 7"
                              stroke="#ffffff"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      
                    </div>
                  </div>
                  </a>
                  <a
                          href={"/all-categories?category=Fruits%20%26%20Vegetables"}
                  
                        // to={{
                        //   pathname: "/all-categories",
                        //   state: {
                        //     cat: 3,
                        //     type: "cat",
                        //   },
                        // }}
                      >
                  <div className="second-box">
                    {/* <img
                      src="\bannernew\dummy-img6.png"
                      alt="img"
                      className="img-fluid item-image"
                      style={{maxWidth: "260px", width: "100%"}}
                    /> */}
                    <div className="inner-content">
                      <h6>Freshness to your door!</h6>
                     
                        <a>

                          shop now
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            style={{ marginTop: "-1px" }}
                          >
                            <path
                              d="M10 17L15 12L10 7"
                              stroke="#ffffff"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                    
                    </div>
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerNew;
