import React, { useEffect } from 'react'
import Navbar from '../landing/header/Navbar'
import "./otherissues.scss"
const OtherIssues = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className="bread-crumb">
                <div className="bread">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="#">FAQS</a>
                            </li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section className='main-term'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-11 m-auto'>
                            <div className='backbtn'>
                                <a>Back</a>
                            </div>
                            <div className='otherissues-main'>
                                <h2>Other Issues</h2>
                                <p className='upper-issues'>Any issues other than that explained and guided on our site, can be reported to <span className='font-bold'>info@quick.shop.</span> Or you can also reach us on our social media as well as WhatsApp at 0300 566 8000. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OtherIssues