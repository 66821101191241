import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyDrPnO0Qpbkba8e3VLPTbkp3uEqLFKS_5o",
    authDomain: "groceryapp-33333.firebaseapp.com",
    projectId: "groceryapp-33333",
    storageBucket: "groceryapp-33333.appspot.com",
    messagingSenderId: "617601541308",
    appId: "1:617601541308:web:6d466c839d1d1eeb045cb9",
    measurementId: "G-BZJV9SR4R3"
  };
export const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);