import React, { useEffect, useState } from "react";
import "./cartsuccess.scss";
import Environment from "../utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const PaymentCartSuccess = () => {
  const api_url = Environment.base_url;
  const api_url3 = Environment.base_url3;
  const val = localStorage.getItem("accessToken");
  const history = useHistory();
  const [bankInfo,setBankInfo] = useState([]);
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const onImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const [loader, setLoader] = useState(null);

  const placeOrder = async () => {
    const formData = new FormData();
    formData.append("transactionReceiptImage", image);
    if (image) {
      let config = {
        method: "patch",
        url: api_url + `/orders/${id}/transaction-receipt`,
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      setLoader(true);
      await axios(config)
        .then((res) => {
          toast.success(res?.data?.message);
          setLoader(false);
          history.push("/account");
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            toast.error(err?.response?.data?.message, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    } else {
      toast.warning("please upload image first");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
  }, [id]);
  
  
  const bankInformation = async () => {
    await axios
    .get(`${api_url3}/bank?limit=10&offset=1`, {
      headers: {
        Authorization: "Bearer " + val,
      },
    })
    .then((res) => {
     
      setBankInfo(res?.data?.data?.banks[0]);
    })
    .catch((err) => { });

  };
  
  useEffect(() => {
    bankInformation();
    window.scroll(0, 0);
  }, []);

 

  return (
    <>
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="main-success">
        <div className="container-fluid p-0">
          <div className="info-text">
            <p>
              Make your payment directly into our bank account. Your order will
              not be shipped until the funds have cleared in our account.
            </p>
          </div>
          {/* for mobile */}
          <div className="info-text1">
            <p>
              Make your payment directly into our bank account. Your order will
              not be shipped until the funds have cleared in our account.
            </p>
          </div>
          <div className="row">
            <div className="col-xl-6 bank-side">
              <div className="row">
                <div className="col-xl-12 bank-detail-main">
                  <div className="bank-detail-card">
                    <div className="main-heading">
                      <h4>Bank Transfer</h4>
                    </div>
                    <div className="bottom-detail">
                      <div className="inner-text">
                        <p>Bank</p>
                        <h6>{bankInfo?.name} Bank</h6>
                      </div>
                      <div className="inner-text">
                        <p>Account Name</p>
                        <h6>{bankInfo?.accountName}</h6>
                      </div>
                      <div className="inner-text">
                        <p>Account Number</p>
                        <h6>{bankInfo?.accountNumber}</h6>
                      </div>
                      <div className="inner-text">
                        <p>Branch</p>
                        <h6>{bankInfo?.branchName}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="bank-detail-card">
                    <div className="main-heading">
                      <h4>Jazz Cash</h4>
                    </div>
                    <div className="bottom-detail">
                      <div className="inner-text">
                        <p>Account Name</p>
                        <h6>Quick Grocery</h6>
                      </div>
                      <div className="inner-text">
                        <p>Account Number</p>
                        <h6>03118174957</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 m-auto p-0">
              <div className="row">
                <div className="col-xl-12">
                  <div className="parent1">
                    <div className="main-img">
                      {/* <img src='\assets\dummy-imgs\cashier-register1.png' className='img-fluid' /> */}
                      <img
                        src="assets\btn-icons\quick.gif"
                        className="img-fluid"
                      />
                    </div>
                    <div className="heading">
                      <h2>Checkout Successful</h2>
                    </div>
                    <label htmlFor="upload" className="upload-img">
                      <div>
                        {image ? (
                          <img
                            className="img-fluid upload-img-set"
                            src={
                              image
                                ? URL?.createObjectURL(image)
                                : "AssestsUploadcart.svg"
                            }
                            alt="Uploaded Image"
                          />
                        ) : (
                          <img
                            src="\Assests\Uploadcart.svg"
                            alt="Upload Image"
                            className="img-fluid uploaimmg"
                          />
                        )}
                      </div>
                      <h6>Upload transaction screenshot</h6>
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={onImageChange}
                        className="d-none"
                        id="upload"
                      />
                    </label>
                    <div className="orderbtn">
                      <button onClick={placeOrder}>Upload</button>
                    </div>
                    {loader && <div className="loader">Uploading...</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentCartSuccess;
