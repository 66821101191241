import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import "./cartcheckout.scss";
import axios from "axios";
import Environment from "../utils/environment";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { Modal, Button, ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { emptyCart } from "../../../redux/action/index";
import { emptyCart } from "../../redux/action";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../landing/footer/Footer";
import { Label } from "reactstrap";

const Cartcheckout = (props) => {
  const dispatch = useDispatch();

  const propsData = props?.location?.state;
  console.log("data: ", propsData);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const userId = localStorage.getItem("userId");
  const [loader, setLoader] = useState(null);
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState("");
  const [warningerror, setWarningError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const [productimage, setProductImage] = useState(null);
  const onImageChange = (event) => {
    setProductImage(event.target.files[0]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    paymentTypeList();
  }, []);
  useEffect(() => {
    setCartItems(propsData?.cartItems);
    if (propsData) {
      cartPrice(propsData?.cartItems);
      let tempArr = [];
      for (let i = 0; i < propsData?.cartItems?.length; i++) {
        let tempObj = {
          id: propsData?.cartItems[i]?.productId || propsData?.cartItems[i]?.id,
          quantity: propsData?.cartItems[i]?.cartAmount,
        };
        tempArr.push(tempObj);
      }
      setProducts(tempArr);
    }
  }, [propsData]);

  const cartPrice = (cart) => {
    let price = 0;
    for (let i = 0; i < cart?.length; i++) {
      if (cart[i]?.discountedProducts && cart[i]?.discountedProducts[0]?.discount !== undefined) {
        price =
          Math.trunc(
            cart[i]?.price -
            cart[i]?.price * (cart[i]?.discountedProducts[0]?.discount / 100)
          ) *
          cart[i]?.cartAmount +
          price;
      } else if (cart[i]?.discount !== undefined) {
        price =
          Math.trunc(
            cart[i]?.product?.price -
            cart[i]?.product?.price * (cart[i]?.discount / 100)
          ) *
          cart[i]?.cartAmount +
          price;
      } else {
        price = cart[i]?.price * cart[i]?.cartAmount + price;
      }
    }
    setTotalPrice(price);
   
  };
  console.log(products, "productimage");
  const placeOrder = async () => {
    // validateUserName();
    // if (!errors) {
    const formData = new FormData();
    if (productimage) {
      formData.append("prescription", productimage);
    }

    formData.append("addressId", propsData?.addressId);
    formData.append("type", propsData?.deliveryType);
    formData.append("device", "website");


    if (propsData?.deliveryType === "scheduled") {
      formData.append("pickUpTime", propsData?.deliveryTime);
    }

    formData.append("useWalletBalance", false);
    formData.append("paymentTypeId", selectedPaymentTypeId);

    // Assuming 'products' is an array of product objects
    products.forEach((product, index) => {
      formData.append(`products[${index}][id]`, product.id);
      formData.append(`products[${index}][quantity]`, product.quantity);
      // Add other product properties as needed
    });

    formData.append("latitude", propsData?.latitude);
    formData.append("longitude", propsData?.longitude);
    if (propsData?.discountCoupon) {
      formData.append("discountCoupon", propsData?.discountCoupon);
    }


    console.log('formdddaata', formData, products);
    if (selectedPaymentTypeId) {
      setIsLoading(true);
      setLoader(true);
      setIsLoading(true);
      let config = "";
      if (propsData?.discountCoupon) {
        config = {
          method: "post",
          url: api_url + "/orders",
          data: formData,
          headers: {
            Authorization: "Bearer " + val,
          },
        };
      } else {
        config = {
          method: "post",
          url: api_url + "/orders",
          data: formData,
          headers: {
            Authorization: "Bearer " + val,
          },
        };
      }

      await axios(config)
        .then((res) => {
          // toast.success(res?.data?.message);
          setLoader(false);
          setIsLoading(false);
          if (selectedPaymentTypeId == 1) {
            localStorage.removeItem("cartItems");
            dispatch(emptyCart(true));
            history.push("/cart-success");
            localStorage.removeItem("discountAmount");
            localStorage.removeItem("couponCode");
            // window.location.reload();
          } else {
            localStorage.removeItem("cartItems");
            dispatch(emptyCart(true));
            history.push("/payment-cartsuccess?id=" + res?.data?.data?.id);
            localStorage.removeItem("discountAmount");
            localStorage.removeItem("couponCode");
          }
          // history.push("/cartsuccess");
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            setError(err?.response?.data?.message);
            setShow(true);
          }
          setLoader(false);
          setIsLoading(false);
        });
    } else {
      setWarningError("Please select any payment method!");
    }

    // }
  };

  const paymentTypeList = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const config = {
      method: "get",
      url: api_url + "/payments/types",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setLoader(false);
        setPaymentTypes(res?.data?.data);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };
  // useEffect(() => {
  // history.replace("./")
  // }, [history])

  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <Link to="/">
                <li class="breadcrumb-item">
                  <a href="#">Home /</a>
                </li>
              </Link>
              <Link to="/faqs">
                <li class="breadcrumb-item">
                  <a href="#">FAQS</a>
                </li>
              </Link>
            </ol>
          </nav>
        </div>
      </section>
      {isLoading ? (
        <div className="loader-image-checkout">
          <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
        </div>
      ) : (
        <section className="main-checkout">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 m-auto p-0">
                <div className="row">
                  <div className="col-xl-11 m-auto p-0">
                    <div className="parent">
                      <div className="parenthead">
                        <h2>Checkout</h2>
                      </div>
                      {/* <div className="parentbox">
                      <div className="code">
                        <img
                          src="\assets\social-icons\tag.png"
                          className="img-fluid"
                        />
                        <h2>
                          Have a coupon?{" "}
                          <span> Click here to enter your code </span>{" "}
                        </h2>
                      </div>
                    </div> */}
                    </div>

                    <div className="row">
                      <div className="col-xl-6 col-12 padd-lg">
                        <div className="payment-method">
                          <h6 className="payment-head">Payment Method</h6>
                          {paymentTypes?.length > 0
                            ? paymentTypes?.map((item, index) => {
                              return (
                                <>
                                  <div className="option-field">
                                    <div class="form-group">
                                      <input
                                        type="checkbox"
                                        id={index}
                                        checked={
                                          selectedPaymentType == item?.value
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setSelectedPaymentTypeId(
                                              item?.id
                                            );
                                            setSelectedPaymentType(
                                              item?.value
                                            );
                                          } else {
                                            setSelectedPaymentTypeId("");
                                            setSelectedPaymentType("");
                                          }
                                        }}
                                      />
                                      <label for={index}>{item?.value}</label>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                            : null}
                        </div>
                        <div className="payment-method">
                          <h6 className="payment-head">Upload Prescription</h6>
                          <label htmlFor="upload" className="form-control onluipdodebtn_maindiv "  >
                            {productimage ? (<img
                              src={URL.createObjectURL(productimage)}
                              alt="img"
                              className="img-fluid upload-img"
                            />) : (
                              <>  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                                <path d="M15.105 11.8706C15.5428 11.4457 15.5428 10.8084 15.105 10.3836L11.8217 7.19708C11.3839 6.77222 10.7273 6.77222 10.2895 7.19708L7.00617 10.3836C6.5684 10.8084 6.5684 11.4457 7.00617 11.8706C7.44395 12.2954 8.10061 12.2954 8.53839 11.8706L9.96116 10.4898V16.4378C9.96116 17.0751 10.3989 17.5 11.0556 17.5C11.7123 17.5 12.15 17.0751 12.15 16.4378V10.4898L13.5728 11.8706C14.0106 12.2954 14.6673 12.2954 15.105 11.8706ZM18.06 4.96656C16.4184 1.24901 11.9312 -0.556655 7.99117 1.14279C5.47396 2.20495 3.72285 4.43548 3.39452 7.09087C1.0962 7.62195 -0.436021 9.95869 0.111199 12.1892C0.658419 13.9949 2.51897 15.3757 4.48896 15.3757H8.3195V13.6762C7.44395 13.8887 6.45895 13.57 5.80229 12.9327C4.70785 11.8706 4.70785 10.1711 5.80229 9.21518L9.08561 6.02871C9.63283 5.49763 10.2895 5.2852 11.0556 5.2852C11.8217 5.2852 12.4784 5.60385 13.0256 6.02871L16.3089 9.21518C17.4033 10.2773 17.4033 11.9768 16.3089 12.9327C15.6522 13.57 14.7767 13.8887 13.7917 13.6762V15.3757H16.5278C19.5922 15.3757 22 13.0389 22 10.0649C22 7.72816 20.4678 5.71007 18.06 4.96656Z" fill="#009DE0" />
                              </svg>
                                <h6 className="onluipdodebtn">Upload Here</h6></>)}

                            <input onChange={onImageChange} className="onluipdodebtn d-none" type="file" name="Upload Here" id="upload" />

                          </label>
                        </div>
                        {/* <div className="main-feild">
                        <p>
                          First name <span>*</span>
                        </p>
                        <input type="text" placeholder="First name" />
                        <p>
                          Last name <span>*</span>
                        </p>
                        <input type="text" placeholder="Last name" />
                        <p>
                          Street address <span>*</span>
                        </p>
                        <input
                          type="text"
                          placeholder="House number and street name"
                        />
                        <input
                          type="text"
                          placeholder="Apartment, suite, unit, etc"
                          className="innerinput"
                        />
                        <p>
                          Town/City <span>*</span>
                        </p>
                        <input type="text" placeholder="Town/City" />
                        <p>
                          Phone <span>*</span>
                        </p>
                        <input type="text" placeholder="Phone" />
                        <p>
                          Email address <span>*</span>
                        </p>
                        <input type="text" placeholder="Email address" />
                      </div>
                      <div className="difaddress">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                          <label class="form-check-label" for="defaultCheck1">
                            Ship to a different address?
                          </label>
                        </div>
                      </div>
                      <div className="areatext">
                        <p>
                          Order note <span>*</span>
                        </p>
                        <textarea
                          className="areass"
                          placeholder="Order notes"
                        />
                      </div> */}
                      </div>
                      <div className="col-xl-6 col-12 padd-lg">
                        <div className="main-headingzz">
                          <h2>Your order</h2>
                        </div>
                        <div className="product-maincard">
                          <div className="product-head">
                            <h3>Product</h3>
                            <h3>Total</h3>
                          </div>
                          <div className="middle-card">
                            {cartItems?.length > 0 ? (
                              cartItems?.map((item) => {
                                return (
                                  <>
                                    <div className="parent-middle">
                                      <div className="left-content">
                                        <h3>
                                          {item?.product?.name || item?.name}{" "}
                                        </h3>
                                        <p>(×{item?.cartAmount})</p>
                                      </div>
                                      <div className="right-content">
                                        {propsData?.discountCoupon ? (
                                          <h3>
                                            <h4>
                                              Rs.{" "}
                                              {Math.trunc(
                                                item?.product?.price -
                                                item?.product?.price *
                                                (item?.discount / 100)
                                              ) * item?.cartAmount ||
                                                item?.price * item?.cartAmount}
                                            </h4>
                                          </h3>
                                        ) : (
                                          <h3>
                                            <h4>
                                              Rs.{" "}
                                              {Math.trunc(
                                                item?.product?.price -
                                                item?.product?.price *
                                                (item?.discount / 100)
                                              ) * item?.cartAmount ||
                                                item?.price * item?.cartAmount}
                                            </h4>
                                          </h3>
                                        )}
                                      </div>
                                    </div>
                                    <div className="brdr3"></div>
                                  </>
                                );
                              })
                            ) : (
                              <p>No Products</p>
                            )}
                          </div>
                          <div className="totalhead">
                            <h3>Order Amount</h3>
                            <h3 className="subtotal">
                              Rs.{" "}
                              {localStorage.getItem("discountAmount")
                                ? localStorage.getItem("type") === "percentage"
                                  ? totalPrice - (totalPrice * parseInt(localStorage.getItem("discountAmount")) / 100)
                                  : totalPrice - parseInt(localStorage.getItem("discountAmount"))
                                : totalPrice}
                            </h3>
                          </div>
                          <div className="brdr3"></div>

                          <div className="shiping">
                            <div className="totalrate">
                              {/* <div className="rate">
                            <ul>
                              <li>Flat rate</li>
                              <li>rate</li>
                            </ul>
                          </div> */}
                              <div className="totalrate1 pt-3">
                                <h3>Shipping Charges</h3>
                                {/* <h6>Flat Rate</h6> */}
                                <p>
                                  Rs.
                                  <span className="spa11">
                                    {Math.round(propsData?.shippingFee)}
                                  </span>
                                </p>
                              </div>
                              <div className="brdr3"></div>
                              <div className="totalrate1">
                                <h3>Grand Total</h3>
                                {/* {propsData?.discountCoupon ?
                              <p>Rs. {Number(totalPrice)-Number(propsData?.discount) + Number(propsData?.shippingFee) || 0}</p>
                              : */}
                                <p>
                                  Rs.
                                  {Number   (localStorage.getItem("discountAmount")
                                ? localStorage.getItem("type") === "percentage"
                                  ? totalPrice - (totalPrice * parseInt(localStorage.getItem("discountAmount")) / 100)
                                  : totalPrice - parseInt(localStorage.getItem("discountAmount"))
                                : totalPrice) +
                                    Number(
                                      Math.round(propsData?.shippingFee)
                                    ) || 0}
                                </p>
                                {/* } */}
                              </div>
                              <div className="brdr3"></div>
                              <div className="warning-error">
                                {warningerror ? <p>{warningerror}</p> : null}
                              </div>
                              {/* <div className="difaddress">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                for="defaultCheck1"
                              >
                                Direct bank transfer
                              </label>
                            </div>
                          </div>
                          <div className="endcard">
                            <p>
                              Make your payment directly into our bank account.
                              <br></br> Please use your Order ID as the payment
                              reference.<br></br> Your order will not be shipped
                              until the funds have<br></br> cleared in our
                              account.
                            </p>
                          </div>
                          <div className="difaddress">
                            <div class="form-check endaddress">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                for="defaultCheck1"
                              >
                                Check payments
                              </label>
                            </div>
                          </div>
                          <div className="difaddress">
                            <div class="form-check endaddresss">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="defaultCheck1"
                              >
                                Cash on delivery
                              </label>
                            </div>
                          </div> */}
                            </div>
                          </div>

                          <div className="endbtn mt-3">
                            <button onClick={placeOrder}>Place order</button>
                            {/* <Link to="/cartsuccess">
                        </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* <Button variant="primary" onClick={handleShow}>
        Open Modal
      </Button> */}
      {/* <div className="modaalll">
      <Modal   show={show} onHide={handleClose} centered>
        <Modal.Header   closeButton> </Modal.Header>
         
         
          <Modal.Title> <img  src="assets\btn-icons\Group.png"></img></Modal.Title>
          <ModalBody>{error}</ModalBody>
          
       
      </Modal>
      </div> */}

      <section className="error-modal">
        <div className="container-fluid">
          <div className="modal-div123">
            <Modal
              keyboard={false}
              className="cart-location1"
              show={show}
              onHide={handleClose}
              centered
            >
              <Modal.Header>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
              </Modal.Header>
              <Modal.Body className="cart-body">
                <img
                  className="cart-adde"
                  src="assets\btn-icons\Group.png"
                ></img>
                <p className="cart-data">{error}</p>
              </Modal.Body>
              <Modal.Footer className="cart-footer"></Modal.Footer>
            </Modal>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cartcheckout;