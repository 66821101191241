// import logo from './logo.svg';
import "../../App.scss";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { getToken, onMessage } from "firebase/messaging";
import Navbar from "./header/Navbar";
import BrowseCategory from "./BrowseCategory/BrowseCategory";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Featured from "./FeaturedBrand/Featured";
import TopSaver from "./TopSaver/TopSaver";
import Banner from "./main-banner/Banner";
import Seller from "./BestSeller/Seller";
import JustLanding from "./JustLanding/JustLanding";
import Essential from "./Essential/Essential";
import Third from "../Third/Third";
import Fourth from "../Fourth/Fouth";
import Fifth from "../Fifth/Fifth";
import Six from "../Six/Six";
import Seven from "../Seven/Seven";
import Eight from "../Eight/Eight";
import Collection from "./CollectionItems/Collection";
import Social from "./Social/Social";
import axios from "axios";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import { locationAddressRedux } from "../../redux/action/index";
import { useSelector } from "react-redux";
import Related from "../RelatedProduct/Related";
import Environment from "../utils/environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainLoader from "../utils/MainLoader";
import BannerNew from "./BannerNew/BannerNew";
import { messaging } from "../firebase";

function Landing() {
  // const locationRedux = useSelector((state) => state.UserReducer.location);

  // const dispatch = useDispatch();
  const history = useHistory();
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [location, setLocation] = useState("");
  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [wishCount, setWishCount] = useState(0);
  const [post, setPost] = useState([]);
  const val = localStorage.getItem("accessToken");
  const [topSaverList, setTopSaverList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [justLanding, setJustLanding] = useState([]);
  const [justLand, setJustLand] = useState([]);
  const [thirdLine, setThirdLine] = useState([]);
  const [fourth, setFourth] = useState([]);
  const [fifth,setFifth] = useState ([]);
  const [six, setSix] = useState ([]);
  const [seven, setSeven] = useState ([]);
  const [eight,setEight] = useState ([]);
  const [loading, setLoading] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const api_url = Environment.base_url;

  const getJustLandingList = async (val) => {
    const config = {
      method: "get",
      url: `${api_url}/store/products?limit=40&offset=1&sort[]=createdAt@desc&filter=isPublish=eq:true`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    const response = await axios(config);
    setJustLanding(response?.data?.data?.products);
  };

  // const getJustLanding = async (val) => {
  //   const config = {
  //     method: "get",
  //     url: `${api_url}/store/products?limit=6&offset=1&sort[]=createdAt@desc&filter=price=between:[450,550];isPublish=eq:true`,
  //     headers: {
  //       Authorization: "Bearer " + val,
  //     },
  //   };
  //   const response = await axios(config);
  //   setJustLand(response?.data?.data?.products);
  // };


  // const getThirdLine = async (val) => {
  //   const config = {
  //     method: "get",
  //     url: `${api_url}/store/products?limit=6&offset=1&sort[]=createdAt@desc&filter=price=between:[600,700];isPublish=eq:true`,
  //     headers: {
  //       Authorization: "Bearer " + val,
  //     },
  //   };
  //   const response = await axios(config);
  //   setThirdLine(response?.data?.data?.products);
  // };

  // const getFourth = async (val) => {
  //   const config = {
  //     method: "get",
  //     url: `${api_url}/store/products?limit=6&offset=1&sort[]=createdAt@desc&filter=price=between:[750,850];isPublish=eq:true`,
  //     headers: {
  //       Authorization: "Bearer " + val,
  //     },
  //   };
  //   const response = await axios(config);
  //   setFourth(response?.data?.data?.products);
  // };

  // const getFifth = async (val) => {
  //   const config = {
  //     method: "get",
  //     url: `${api_url}/store/products?limit=6&offset=1&sort[]=createdAt@desc&filter=price=between:[950,1050];isPublish=eq:true`,
  //     headers: {
  //       Authorization: "Bearer " + val,
  //     },
  //   };
  //   const response = await axios(config);
  //   setFifth(response?.data?.data?.products);
  // };


  // const getSix = async (val) => {
  //   const config = {
  //     method: "get",
  //     url: `${api_url}/store/products?limit=6&offset=1&sort[]=createdAt@desc&filter=price=between:[1150,1250];isPublish=eq:true`,
  //     headers: {
  //       Authorization: "Bearer " + val,
  //     },
  //   };
  //   const response = await axios(config);
  //   setSix(response?.data?.data?.products);
  // };

  // const getSeven = async (val) => {
  //   const config = {
  //     method: "get",
  //     url: `${api_url}/store/products?limit=6&offset=1&sort[]=createdAt@desc&filter=price=between:[1350,1450];isPublish=eq:true`,
  //     headers: {
  //       Authorization: "Bearer " + val,
  //     },
  //   };
  //   const response = await axios(config);
  //   setSeven(response?.data?.data?.products);
  // };

  // const getEight = async (val) => {
  //   const config = {
  //     method: "get",
  //     url: `${api_url}/store/products?limit=6&offset=1&sort[]=createdAt@desc&filter=price=between:[1550,1650];isPublish=eq:true`,
  //     headers: {
  //       Authorization: "Bearer " + val,
  //     },
  //   };
  //   const response = await axios(config);
  //   setEight(response?.data?.data?.products);
  // };

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    console.log('permission', permission);
    
    if (permission === 'granted') {
     
      
      if (val) {
        // Generate token
        const token = await getToken(messaging, { vapidKey: 'BDYRISKj3vSnI1n62ZQ2AxJMGu7OIJof--McUPKnA_66FEKl9UD6DYE1Lcs6NMBkTm6WZbUbndbOXui_09L9iOk' });
        console.log('token generated', token);
        
        // Call patch API with the generated token
        await sendTokenToServer(token);
      } else {
        console.log('Access token not found in localStorage');
      }
    }
  }
  
  const sendTokenToServer = async (token) => {
    try {
      const response = await fetch('https://prod-api.quick.shop/auth/users/device-details', {
        method: 'PATCH',
        headers: {
          Authorization: "Bearer " + val,
          'Content-Type': 'application/json', // Ensure content type is specified
        },
        body: JSON.stringify({ fcmToken: token,osName: "website" }),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to send token to server');
      }
  
      console.log('Server response:', responseData);
    } catch (error) {
      console.error('Error sending token to server:', error);
    }
  }
  
  if (messaging) {
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      toast.info(payload?.notification?.body);
    });
  }
  
  

  useEffect(() => {
    //request user for notification permission 
    requestPermission()
  }, [])


  const getFeaturedBrands = async (val) => {
    const response = await axios.get(
      `${api_url}/brands/all-publish?offset=1&limit=100`,
      {
        headers: {
          Authorization: "Bearer " + val,
        },
      }
    );
    setBrandData(response?.data?.data?.brands);
  };
  const getCategories = async (val) => {
    const response = await axios.get(
      `${api_url}/store/categories/minimal?offset=1&limit=50`,
      {
        headers: {
          Authorization: "Bearer " + val,
        },
      }
    );
    setCategoryData(response?.data?.data?.categories);
  };


  const getTopSaversList = async (val) => {
    const config = {
      method: "get",
      url: `${api_url}/store/products/top-saver?limit=6&offset=1`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    const response = await axios(config);
    setTopSaverList(response?.data?.data?.products);
  };

  const getBanner = async (val) => {
    try {
      const response = await axios.get(
        `${Environment.base_url2}/banners?offset=1&limit=30`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      );
      setPost(response?.data?.data?.banners);
    } catch (err) {

    }
  };
  function savePosition(position) {
    setLng(position?.coords?.longitude);
    setLat(position?.coords?.latitude);
  }
  function positionError(error) {
    if (error.PERMISSION_DENIED) {
      // history.push("/location");
      // axiosCallForLocation();
      // showError('Geolocation is not enabled. Please enable to use this feature.');
    } else {
      // Handle other kinds of errors.
    }
  }

  const ipURL = "https://ip.nf/me.json";
  const axiosCallForLocation = () => {
    axios
      .get(ipURL)
      .then((response) => {
        setLat(response?.data?.ip?.latitude);
        setLng(response?.data?.ip?.longitude);
      })
      .catch((err) => { });
  };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(savePosition);
  //   }
  // }, []);

  useEffect(() => {
    Geocode.setApiKey("AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0");

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        setLocation(address);
      },
      (error) => {
      }
    );
  }, [lat, lng]);

  useEffect(() => {
    localStorage.removeItem("currentId");
    localStorage.removeItem("prodData");
    window.scroll(0, 0);
  }, []);

  const banner1 = post.filter(
    (data) => data?.type === "main banner 1" && data?.isPublish === true
  );
  const banner2 = post.filter(
    (data) => data?.type === "main banner 2" && data?.isPublish === true
  );

  useEffect(() => {
    fetchData();
    window.scroll(0,0);
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Make your API calls here

      await Promise.all([
        // getBanner(),
        getTopSaversList(),
        getCategories(),
        getFeaturedBrands(),
        getJustLandingList(),
        // getJustLanding(),
        // getThirdLine(),
        // getFourth(),
        // getFifth(),
        // getSix(),
        // getSeven(),
        // getEight(),
      ]);

      setLoading(false);
    } catch (error) {
      toast.error("Error Fetching Data", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
    }
  };

  const handleCloselocationRegister = () => setShowRegister(false);

  useEffect(() => {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour >= 22 || currentHour < 10) {
      prorefresh();
    }
  }, [])

  const prorefresh = () => {
    const timer = setTimeout(() => {
      setShowRegister(true);
    }, 6000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      {loading && <MainLoader />}
      {/* <Navbar
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
        locaiton={location}
        wishCount={wishCount}
        setWishCount={setWishCount}
        categoryData={categoryData}
      /> */}
      <BannerNew />
      {/* <Banner banner1={banner1} banner2={banner2} /> */}
      <BrowseCategory categoryData={categoryData} />
      <Featured brandData={brandData} />
      <TopSaver
        topSaverList={topSaverList}
        setTopSaverList={setTopSaverList}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
      />
      <Seller
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setWishlist={setWishlist}
        wishlist={wishlist}
        setWishCount={setWishCount}
        wishCount={wishCount}
      />

      <JustLanding
        justLanding={justLanding}
        setJustLanding={setJustLanding}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
      />
      {/* <Essential
        justLand={justLand}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setJustLand={setJustLand}
      />
      <Third
        thirdLine={thirdLine}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setThirdLine={setThirdLine}
      />
          <Fourth
        fourth={fourth}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setFourth={setFourth}
      />
               <Fifth
        fifth={fifth}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setFifth={setFifth}
      />
          <Six
        six={six}
        setSix={setSix}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
      />
          <Seven
        seven={seven}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setSeven={setSeven}
      />
          <Eight
        eight={eight}
        cartCount={cartCount}
        setCartCount={setCartCount}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setEight={setEight}
      /> */}
      <Collection />
      {/* <Essential /> */}
      {/* <Social /> */}
      <section className="location1">
        <div className="container-fluid">
          <div className="modal-div1">
            <Modal backdrop="static" keyboard={false} className="cart-location1" show={showRegister} onHide={handleCloselocationRegister} centered>
              <Modal.Header >
                {/* <Modal.Title>Modal heading</Modal.Title> */}
              </Modal.Header>
              <Modal.Body className="cart-body">
                <img
                  // className="cart-added"
                  src="assets\btn-icons\sleep 1.svg"
                  style={{ width: '135px' }}
                ></img>
                <p className="cart-data">
                  We're offline right now. Your order will be delivered after <span className="cart-data1"> <br /> 10 AM </span>
                </p>
              </Modal.Body>
              <Modal.Footer className="cart-footer">
                <Link to="/">
                  <Button className="shopp" onClick={handleCloselocationRegister}>
                    OK
                  </Button>
                </Link>

              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </section>

    </>
  );
}

export default Landing;
