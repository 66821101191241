import React, { useEffect } from "react";
import "./checkemail.scss";
import { Link } from "react-router-dom";
import Navbar from "../landing/header/Navbar";

const Checkemail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="email">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="main">
                <img
                  src="\Assests\Mailsentbro.png"
                  alt="img"
                  className="imgmain"
                />
                {/* <iframe  width="100%" src="https://lottie.host/embed/ee1d26e6-bd5c-4050-9652-3ea43d5f4799/4F5w0yk6GU.json"></iframe> */}
                {/* <iframe src="https://tomilabtext.netlify.app/" width="100%" className='forstyle'></iframe> */}
                <h5>Check your Email</h5>
                <p>
                  We have sent a password recovery instructions to your email.
                </p>
                <Link to="/login" className="w-100">
                  <button className="snd-btn m-auto">OK</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkemail;
