import React from "react";
import useWindowDimensions from "./getDimensions";

// import mainloader from '../Assets/img/mainloader.svg';
function MainLoader({ text }) {
  const { width } = useWindowDimensions();
  return (
    <>
      <div className="">
        <div
          className="position-fixed w-100"
          style={{
            zIndex: 1100,
            width: "100vw",
            height: "100vh",
            top: "0",
            left: "0",
            background: "white",
            backdropFilter: "white",
            WebkitBackdropFilter: "blur(20px)",
          }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <img width={150} src="assets\btn-icons\2nd_v2.gif" alt="loader" />
              {/* <h2 className='text-white w-100 text-center mt-5'>{text}</h2> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MainLoader;
