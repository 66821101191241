let initState = {};

export const UserReducer = (state = initState, action) => {
  const { type, payload } = action; //object destructring
  switch (type) {
    case "LOCATION":
      return {
        ...state,
        location: payload,
      };
    case "CART":
      return {
        ...state,
        cart: payload,
      };
      case "EMPTY":
        return {
          ...state,
          emptyBool: payload,
        };
        case "SEARCH":
          return {
            ...state,
            search: payload,
          };  case "OFFSET":
          return {
            ...state,
            offSet: payload,
          };
    default:
      return state;
  }
};
