let Environment = {
  // test net
  // djtAddress: "0xBeDA2DcEDb2D542df346BD9ce5f85737B59d12cf",
  // dispersertestnet: "0xE7ac4Cc1da49A78875a30215BB43d3638ce316C8",

  // disperser: "0xFCBEc5f112a3b730beB1659b22fD812165B6FdbE",
  // base_url: "http://192.168.20.25:5001/products",
  // base_url2: "https://prod-api.quick.shop/auth",
  // base_url3: "https://prod-api.quick.shop/admins",



 disperser: "0xFCBEc5f112a3b730beB1659b22fD812165B6FdbE",
  base_url: "https://prod-api.quick.shop/products",
  base_url2: "https://prod-api.quick.shop/auth",
  base_url3: "https://prod-api.quick.shop/admins",
 
  // stakingContractAddress: "0xfc83C2dBd745cC31D8C4E9183Ad914d8A0c0cF0F",
  // stakingContractAddress: "0x6b082Aa008e1859a8506973c5c7F847269092d2a",
};

export default Environment;
