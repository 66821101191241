import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import BannerSlider from "../BannerSlider/BannerSlider";
import Navbar from "../landing/header/Navbar";
import "./allcategories.scss";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import Environment from "../utils/environment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { searchOffSet } from "../../redux/action/index";
import { toast } from "react-toastify";
import Location from "../Location/Location";
import { cartItems } from "../../redux/action/index";
import _ from "lodash";
import Accordion from "react-bootstrap/Accordion";
import MainLoader from "../utils/MainLoader";
import { useLocation } from "react-router-dom";
import { convertLength } from "@mui/material/styles/cssUtils";

const Allcategories = (props) => {

  // const location = useLocation();
  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const categoryParam = queryParams.get('category');
  //   // Decode the categoryParam to handle spaces correctly
  //   const decodedCategory = categoryParam ? decodeURIComponent(categoryParam) : '';
  //   console.log('Category Param:', decodedCategory);
  //   // Do something with the decodedCategory, e.g., update state or make an API call
  // }, [location.search]);
  const searchItemRedux = useSelector((state) => state.UserReducer.search);
  const offset = useSelector((state) => state.UserReducer.offSet);
  // console.log(searchOffSetRedux,"searchOffSetRedux");
  const cat = props?.location?.state;
  const history = useHistory();
  const val = localStorage.getItem("accessToken");
  const prodDataTemp = JSON.parse(localStorage.getItem("prodData"));
  let selectedMessageId = localStorage.getItem("currentId");
  const api_url = Environment.base_url;
  const dispatch = useDispatch();
  // const [offset, setOffset] = useState(searchOffSetRedux ? searchOffSetRedux : 1);
  console.log("offset",offset);
  const [rend, setRend] = useState(false);
  const [post, setPost] = useState([]);
  const chatRef = useRef(null);
  const [id, setId] = useState("");

  const [scrollPosition, setScrollPosition] = useState(0);
  const [messIdCheck, setMessIdCheck] = useState(false);
  const [inStockLength, setInstockLength] = useState(0);
  const [productData, setProductData] = useState(() => {
    if (prodDataTemp?.length > 0) {
      return prodDataTemp;
    } else {
      return [];
    }
  });
  const [page, setPage] = useState([]);

  // useEffect(() => {
  //   if (cat?.cat && cat?.type == "cat") {
  //     setSelectedCategory([cat?.cat]);
  //     setGetSaverId("");
  //     setId("");
  //   }
  // }, [cat?.cat]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollPosition(window.pageYOffset);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    selectedMessageId = parseInt(selectedMessageId);
    setMessIdCheck(selectedMessageId);
    if (selectedMessageId && chatRef.current && productData?.length > 0) {
      const selectedMessageElement = chatRef.current.querySelector(
        `#xyz-${selectedMessageId}`
      );

      if (selectedMessageElement) {
        selectedMessageElement.scrollIntoView({ behavior: "smooth" });
        localStorage.removeItem("currentId");
        localStorage.removeItem("prodData");
        // selectedMessageId = "";
        // setSelectedMessageId(null);
      }
    }
  }, [productData]);

  const handleRouteChange = (id) => {
    // Store the scroll position in the browser's history state
    localStorage.setItem("prodData", JSON.stringify(productData));
  };

  // const handleRouteChange = useRef(
  //   _.debounce((id) => {
  //     // Store the scroll position in the browser's history state
  //     localStorage.setItem("prodData", JSON.stringify(productData));
  //     console.log("local prod data: ", productData);
  //     // window.history.pushState({ scrollPosition, productData }, "");
  //   }, 500) // Adjust the debounce delay as needed (e.g., 500ms)
  // ).current;

  useEffect(() => {
    window.scrollTo(0, 0);
    // props.setHandleCatChange(handleCategorySelect);
  }, []);

  // useEffect(() => {
  //   console.log("scroll position: ", scrollPosition, "   ", productData);
  //   window.scrollTo(0, scrollPosition);
  // }, [scrollPosition, productData]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [sort, setSort] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleCloseSort = () => setSort(false);
  const handleShowSort = () => setSort(true);
  const [getSaverId, setGetSaverId] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [limit, setLimit] = useState(28);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(() =>
    cat?.type == "cat" ? [cat?.cat] : []
  );

  const [selectedCatId, setSelectedCatId] = useState([]);

  const [urlId, setUrlId] = useState()
  const [selectedBrandCategory, setSelectedBrandCategory] = useState(() =>
    cat?.type == "brand" ? [cat?.cat] : []
  );

  const [priceRange, setPriceRange] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredBrand, setFilteredBrand] = useState([]);

  const getFeaturedBrands = async () => {
  
    const filterQuery = selectedCategory?.length > 0
      ? `&categoryName=${selectedCategory}&filter=isPublish=eq:true`
      : "&filter=isPublish=eq:true";

    const url = `${api_url}/brands/all-publish?offset=1&limit=100${filterQuery}`;

    await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setBrandData(res?.data?.data?.brands);
      })
      .catch((err) => { });
  };

  // const getFeaturedBrands = async () => {
  //   await axios
  //     .get(
  //       `${api_url}/brands/all-publish?offset=1&&limit=100${
  //         selectedCatId?.length > 0 
  //           ? "&filter="
  //           : "&filter=isPublish=eq:true"
  //       }${
  //         selectedCatId?.length > 0
  //           ? ";productCategoryId=in:[" +
  //           selectedCatId +
  //             "]isPublish=eq:true"
  //           : ""
  //       }`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + val,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       // console.log("brand amount", res?.data?.data?.brands);
  //       setBrandData(res?.data?.data?.brands);
  //     })
  //     .catch((err) => {});
  // };

  useEffect(() => {
    const filteredBrands = brandData.filter((item) =>
      item?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filteredBrands);
  }, [searchQuery, brandData]);

  // useEffect(() => {
  //   console.log("mainn");
  //   var saverId = window.location.href;
  //   saverId = new URL(saverId);
  //   if (selectedCategory?.length > 0 || selectedBrandCategory?.length > 0) {
  //     setGetSaverId("");
  //     setId("");
  //   } else {
  //     setGetSaverId(saverId.searchParams.get("id"));
  //     setId(saverId.searchParams.get("id"));
  //   }
  //   if (getSaverId) {
  //     getProductsList(false);
  //   console.log("mainn1");

  //   }
  // }, [getSaverId]);

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [checkTS, setCheckTS] = useState(false);
  const [checkTS1, setCheckTS1] = useState(false);

  useEffect(() => {
    var saverId = window.location.href;
    saverId = new URL(saverId);

    const categoryParam = saverId.searchParams.get("category");
    const idParam = saverId.searchParams.get("id");

    if (categoryParam) {
      setSelectedCategory(encodeURIComponent(categoryParam));
      setUrlId(encodeURIComponent(categoryParam))
    } else if (idParam) {
      setGetSaverId(idParam);
      setId(idParam);
    }

    if (getSaverId || categoryParam) {
      getProductsList(false);
    }
  }, [getSaverId]);

  useEffect(() => {
    if (urlId) {
      // setSelectedCategory(urlId)
        let ss = urlId.replace(/&/g, '%26')
        setSelectedCategory(ss)
        //  handleCategorySelect(urlId)

    }

  }, [urlId])

  const getProductsList = async (showMore) => {
    let offVal = null;
    if(offset) {
      offVal = offset
    } else {
      offVal = 1;
    }
    setLoader(true);
    if (getSaverId === "top-saver") {
        setCheckTS(true);
        console.log("top-saver");
      const config = {
        method: "get",
        url: `${api_url}/store/products/top-saver?limit=${limit}&offset=${offVal}`,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      setTimeout(async () => {
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
            setPage(res?.data?.data?.count);
          let prodData = [];
          if (showMore) {
            prodData = productData;
          } else if (!showMore) {
            prodData = [];
          }
          let tempInstock = 0;
          for (let i = 0; i < resData?.products?.length; i++) {
            prodData?.push(resData?.products[i]);
            if (
              resData?.products[i]?.quantity >
              resData?.products[i]?.outOfStockThreshold
            ) {
              tempInstock++;
            }
          }
          setInstockLength(tempInstock);

          setLoader(false);
          setProductData(prodData);
          setRend(!rend);
        })
        .catch((err) => {

        });
      }, 400);
    } else {
      let name = null;
      if (searchItemRedux && searchItemRedux !== "") {
        name = searchItemRedux;
      }
      let url = null;
      if (name && selectedCategory?.length > 0) {

        url = `${api_url}/store/products?&search=name=${name}&offset=${offVal}&limit=${limit}&categoryName=${selectedCategory}&filter=isPublish=eq:true`;
  

      }
     else if (name) {

        url = `${api_url}/store/products?&search=name=${name}&offset=${offVal}&limit=${limit}&filter=isPublish=eq:true`;
  

      }  else {
        let categoryUrl = null;
        if (selectedCategory?.length > 0) {
          categoryUrl = `${api_url}/store/products?offset=${offVal}&limit=${limit}&categoryName=${selectedCategory}`
        } else {
          categoryUrl = `${api_url}/store/products?offset=${offVal}&limit=${limit}`
        }
    
        url = `${categoryUrl}${selectedBrandCategory?.length > 0 ||
            priceRange?.length > 0
            ? "&filter="
            : "&filter=isPublish=eq:true"
          }${selectedBrandCategory?.length > 0
            ? "brandId=in:[" + selectedBrandCategory + "];isPublish=eq:true"
            : selectedBrandCategory?.length > 0
              ? "brandId=in:[" + selectedBrandCategory + "];isPublish=eq:true"
              : ""
          }${priceRange?.length > 0
            ? ";price=between:[" + priceRange + "];isPublish=eq:true"
            : priceRange?.length > 0 && selectedBrandCategory.length > 0
              ? "price=between:[" + priceRange + "];isPublish=eq:true"
              : priceRange?.length > 0
                ? "price=between:[" + priceRange + "];isPublish=eq:true" 
                : ""
          }`;
      }
      console.log("simple");
      await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + val,
          },
        })
        .then((res) => {
          const resData = res?.data?.data;
          setPage(res?.data?.data?.count);
          let prodData = [];
          if (showMore) {
            prodData = productData;
          } else if (!showMore) {
            prodData = [];
          }
          let tempInstock = 0;
          // prodData.push(resData?.products);
          for (let i = 0; i < resData?.products?.length; i++) {
            prodData?.push(resData?.products[i]);
            if (
              resData?.products[i]?.quantity >
              resData?.products[i]?.outOfStockThreshold
            ) {
              tempInstock++;
            }
          }

          setInstockLength(tempInstock);
          setProductData([]);
          setLoader(false);
          setProductData(prodData);
          setRend(!rend);
        })
        .catch((err) => { });
    }
  };




  // const checkQuantity = (item) => {
  //   let res = false;
  //   if (item?.sourceProductId) {
  //     if (item?.quantity > item?.outOfStockThreshold) {
  //       return true;
  //     }
  //   } else {
  //     if (item?.productCap?.maxCap > item?.productCap?.todaySold) {
  //       return true;
  //     }
  //   }
  // };

  // const checkQuantitytop = (item) => {
  //   let res = false;
  //   if (item?.sourceProductId) {
  //     if (item?.product?.quantity > item?.product?.outOfStockThreshold) {
  //       return true;
  //     }
  //   } else {setProductData
  //     if (
  //       item?.product?.productCap?.maxCap > item?.product?.productCap?.todaySold
  //     ) {
  //       return true;
  //     }
  //   }
  // };

  // localStorage.setItem('offset', offset.toString());
  // useEffect(() => {
  //   let offset = localStorage.getItem('offset');
  //   if (offset) {
  //     offset = parseInt(offset);
  //   } else {
  //     offset = 1;
  //   }
  //   getProductsList(offset, false);
  // }, []);

  const getCategoriesList = async () => {
    await axios
      .get(`${api_url}/store/categories/all-publish?limit=50`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setCategoryData(res?.data?.data?.categories);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (!prodDataTemp || prodDataTemp?.length == 0) {
      setTimeout(() => {
        getProductsList(false);

      }, 400);
     

    }
  }, [
    selectedCategory,
    urlId,
    priceRange,
    selectedBrandCategory.length,
  ]);
  useEffect(() => {
    if (offset > 1) {
      getProductsList(true);
      

    }
  }, [id, offset]);

  useEffect(() => {
      
    if (getSaverId === "top-saver") {
      getProductsList();
      // window.location.reload();

    } else {
      if (searchItemRedux) {
        window.scroll(0,0);
        getProductsList();
        

      }
    }
  }, [getSaverId, searchItemRedux,selectedCategory]);

  useEffect(() => {
    getCategoriesList();
  }, []);

  useEffect(() => {
    getFeaturedBrands();
  }, [selectedCategory]);

  // const handleCategorySelect = (id, name) => {
  //   setSelectedCatId(...selectedCatId, id)
  //   setSelectedBrandCategory([]);
  //   setGetSaverId("");
  //   setRend(!rend);
  //   window.scrollTo(0, 0);

  //   setSelectedCategory((prevSelected) => {
  //     const index = prevSelected.indexOf(name);
  //     if (index === -1) {
  //       return [name];
  //     } else if (prevSelected.length === 1) {
  //       return [];
  //     } else {
  //       return prevSelected.filter((item) => item !== name);
  //     }
  //   });





  //   const newUrl = `/all-categories?category=${name}`;
  //   window.history.pushState({}, "", newUrl);

  // };


  const handleCategorySelect = (id, name) => { 
    dispatch(searchOffSet(1));
    setSelectedBrandCategory([]);
    setId("");
      setGetSaverId("");
      setRend(!rend);
      window.scrollTo(0, 0);

    setSelectedCatId((prevSelectedCatId) => {
      const index = prevSelectedCatId.indexOf(id);
      if (index === -1) {
        return [id];
      } else if (prevSelectedCatId.length === 1) {
        return [];
      } else {
        return prevSelectedCatId.filter((item) => item !== id);
      }
    });
  
    setSelectedCategory((prevSelected) => {
      const index = prevSelected.indexOf(name);
      if (index === -1) {
        return [name];
      } else if (prevSelected.length === 1) {
        return [];
      } else {
        return prevSelected.filter((item) => item !== name);
      }
    } 
    
    ) ;
    const newUrl = selectedCategory.includes(name)
    ? window.location.href = '/all-categories'
    : `/all-categories?category=${name}`; 

  window.history.pushState({}, '', newUrl);
  };






  const handleBrandCategorySelect = (id) => {
    dispatch(searchOffSet(1));
    setGetSaverId("");
    setId("");
    let checkSelectedBool = selectedBrandCategory?.includes(id);
    if (checkSelectedBool) {
      let tempArr = selectedBrandCategory?.filter((item) => item !== id);
      setSelectedBrandCategory(tempArr);
    } else if (!checkSelectedBool) {
      let tempArr = selectedBrandCategory;
      tempArr.push(id);
      setSelectedBrandCategory(tempArr);
    }
    setRend(!rend);
    window.scrollTo(0, 0);
  };

  const [show12, setShow12] = useState(false);
  // const handleCartItem = (productToAdd) => {
  //   productToAdd.cartAmount = 1;
  //   const temp = [productToAdd];
  //   temp.push(1);
  //   dispatch(cartItems(temp));
  //   setShow12(true);
  // };

  const handleGoButtonClick = () => {
    if (minPrice === "" && maxPrice === "") {
      // window.location.reload();
      setPriceRange([]);
      window.scrollTo(0, 0);
      return;
    }

    if (minPrice === "" || maxPrice === "") {
      // At least one of them is empty, set to 0 if empty
      setPriceRange([
        minPrice === "" ? 0 : parseFloat(minPrice),
        maxPrice === "" ? 80000 : parseFloat(maxPrice),
      ]);
    } else {
      // Both values are provided, set the price range
      setPriceRange([parseFloat(minPrice), parseFloat(maxPrice)]);
    }
  };

  // const [topSaverList, setTopSaverList] = useState([]);

  // const getTopSaversList = async () => {
  //   const config = {
  //     method: "get",
  //     url: `${api_url}/store/products/top-saver?limit=10&offset=1`,
  //     headers: {
  //       Authorization: "Bearer " + val,
  //     },
  //   };
  //   await axios(config)
  //     .then((res) => {
  //       setProductData();
  //       setProductData(res?.data?.data?.products);
  //     })
  //     .catch((err) => {
  //       console.log('err',err);
  //     });
  // };
  // const getBanner = async () => {
  //   // setPost([]);
  //   await axios
  //     .get(`${Environment.base_url2}/banners?offset=1&&limit=10`, {
  //       headers: {
  //         Authorization: "Bearer " + val,
  //       },
  //     })

  //     .then((res) => {
  //       setPost(res?.data?.data?.banners);
  //     })
  //     .catch((err) => {});
  // };

  // const filteredData = post.filter(
  //   (data) => data?.type === "main banner 2" && data?.isPublish === true
  // );

  // useEffect(() => {
  //   getBanner();
  // }, []);

  // const handleProductClick = (productId) => {
  //   window.open(`/productdetail?id=${productId}`, "_blank");
  // };

  const handleCartItem = (productToAdd, index) => {
    setProductData([]);
    let dumArr = productData;
    let count = productToAdd;
    // setJustLanding([]);
    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    // console.log("DDDDDDDD", dumArr, count);
    setProductData(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = 1;
    let temp = [productToAdd];
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
  };

  const increase = (productToAdd, index) => {
    setProductData([]);
    let dumArr = productData;
    let count = productToAdd;
    // setJustLanding([]);
    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount + 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);
    // console.log("DDDDDDDD", dumArr, count);
    setProductData(dumArr);
    // setOpen(true);
    // setIndx(index);
    productToAdd.cartAmount = productToAdd.cartAmount + 1;
    let temp = [productToAdd];
    // console.log("temppppppppp: ", temp);
    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
  };

  const decrease = (productToAdd, index) => {
    setProductData([]);
    let dumArr = productData;
    let count = productToAdd;

    dumArr = dumArr?.filter((i) => {
      return i?.id !== count?.id;
    });
    let quantityAmount = 1;
    if (productToAdd?.quantityAmount) {
      quantityAmount = productToAdd?.quantityAmount - 1;
    }
    count.quantityAmount = quantityAmount;
    dumArr?.splice(index, count, count);

    setProductData(dumArr);

    productToAdd.cartAmount = productToAdd.cartAmount - 1;
    let temp = [productToAdd];
    temp[1] = -1;
    // console.log("temp: ", temp);

    temp.push(1);
    dispatch(cartItems(temp));
    setRend(!rend);
  };

  const showMoreButtonRef = useRef(null);

  const handleScroll = () => {
    const threshold = 550;

    const buttonRect = showMoreButtonRef.current.getBoundingClientRect();
    const isButtonInViewport =
      buttonRect.top >= 0 &&
      buttonRect.left >= 0 &&
      buttonRect.bottom <= window.innerHeight &&
      buttonRect.right <= window.innerWidth;

    if (isButtonInViewport && window.innerHeight + window.scrollY >= document.body.offsetHeight - threshold && !isLoading) {
    
      setIsLoading(true);

     
      setTimeout(() => {
        // setOffset((prev) => prev + 1);
        dispatch(searchOffSet(offset + 1));
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [offset, isLoading]);

  return (
    <>
      {loader && <MainLoader />}
      {/* <Navbar /> */}
      {/* <section className="wrapper-slider"> */}
      {/* <div className="container-fluid"> */}
      {/* <div className="row"> */}
      {/* <div className="col-xl-11 col-12 m-auto p-0"> */}
      {/* <div className="row"> */}
      {/* <div className="col-xl-8 col-12 padd-lg ps-0"> */}
      {/* <div>
                    <BannerSlider />
                  </div> */}
      {/* </div> */}
      {/* <div className="col-xl-4 col-12 padd-lg pe-1"> */}
      {/* <div className="blue"> */}
      {/* <img */}
      {/* src={filteredData[0]?.imageUrl} */}
      {/* alt="img" */}
      {/* className="" */}
      {/* /> */}
      {/* <div className="textbtn"> */}
      {/* <div className="text"> */}
      {/* <h6>Buy more, Pay less.</h6> */}
      {/* <h3>30%</h3> */}
      {/* </div> */}
      {/* <div className="btn-shop"> */}
      {/* <Link to="/allcategories"> */}
      {/* <button>Shop Now</button> */}
      {/* </Link> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
      <section className="main-category">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 m-auto p-0">
              <div className="mobile-btns d-none d-flex">
                {searchItemRedux === "" ||
                  searchItemRedux === undefined ? null : (
                  <div className="left-side">
                    <h6>
                      Search Results for <span>“{searchItemRedux}”</span>
                    </h6>
                    <p>{page} Results</p>
                  </div>
                )}
                <div className="d-flex justify-content-between ">
                  <button className="button2" onClick={handleShow}>
                    <img
                      src="assets\btn-icons\filter.svg"
                      className="img-fluid me-2"
                    />{" "}
                    Filters{" "}
                  </button>
                  <h3>Shop</h3>
                  {/* <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                        <path d="M19.3008 5.8125H3.10078C2.73178 5.8125 2.42578 5.5575 2.42578 5.25C2.42578 4.9425 2.73178 4.6875 3.10078 4.6875H19.3008C19.6698 4.6875 19.9758 4.9425 19.9758 5.25C19.9758 5.5575 19.6698 5.8125 19.3008 5.8125Z" fill="#009DE0" />
                        <path d="M17.8462 9.75H4.55385C4.25108 9.75 4 9.495 4 9.1875C4 8.88 4.25108 8.625 4.55385 8.625H17.8462C18.1489 8.625 18.4 8.88 18.4 9.1875C18.4 9.495 18.1489 9.75 17.8462 9.75Z" fill="#009DE0" />
                        <path d="M16.1854 13.5H6.21617C5.98909 13.5 5.80078 13.245 5.80078 12.9375C5.80078 12.63 5.98909 12.375 6.21617 12.375H16.1854C16.4125 12.375 16.6008 12.63 16.6008 12.9375C16.6008 13.245 16.4125 13.5 16.1854 13.5Z" fill="#009DE0" />
                      </svg> <div className="twice-text">
                        <h6>Sort by:</h6> <p>Relevance</p>
                      </div> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M9.77223 1.58435L7.47862 3.87796L6.07817 5.28556C5.48512 5.87861 4.52052 5.87861 3.92746 5.28556L0.226255 1.58435C-0.259619 1.09848 0.0904961 0.269634 0.76929 0.269634L4.77774 0.269634L9.2292 0.269634C9.91514 0.269634 10.2581 1.09848 9.77223 1.58435Z" fill="#009DE0" />
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Relevance</a></li>
                      <li><a class="dropdown-item" href="#">Price: Low to High</a></li>
                      <li><a class="dropdown-item" href="#">Price: High to Low</a></li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="cart-sidebar">
                <Offcanvas
                  className="cart-sidebar set-offcanvas-new-style"
                  style={{ maxWidth: "unset" }}
                  placement="bottom"
                  show={show}
                  onHide={handleClose}
                >
                  <Offcanvas.Body>
                    <div className="new-category">
                      <div className="main-heading">
                        <h6>Filters</h6>
                        <svg
                          onClick={handleClose}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M22.9998 12C23.008 13.4416 22.7295 14.8706 22.1804 16.2048C21.6313 17.539 20.8223 18.7521 19.8001 19.7744C18.7778 20.7967 17.5625 21.6079 16.2238 22.1615C14.8852 22.715 13.4498 23 12 23C10.5502 23 9.11478 22.715 7.77616 22.1615C6.43753 21.6079 5.22216 20.7967 4.19992 19.7744C3.17768 18.7521 2.36874 17.539 1.81961 16.2048C1.27048 14.8706 0.991997 13.4416 1.00018 12C0.991997 10.5584 1.27048 9.1294 1.81961 7.79518C2.36874 6.46097 3.17768 5.24786 4.19992 4.2256C5.22216 3.20334 6.43753 2.39211 7.77616 1.83854C9.11478 1.28498 10.5502 1 12 1C13.4498 1 14.8852 1.28498 16.2238 1.83854C17.5625 2.39211 18.7778 3.20334 19.8001 4.2256C20.8223 5.24786 21.6313 6.46097 22.1804 7.79518C22.7295 9.1294 23.008 10.5584 22.9998 12Z"
                            fill="#FAFAFA"
                            stroke="#DCF2FB"
                            stroke-width="1.5"
                          />
                          <path
                            d="M7.94824 7.96313L15.907 16.1676"
                            stroke="#264653"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M15.907 7.96313L7.94824 16.1676"
                            stroke="#264653"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Category</Accordion.Header>
                          <Accordion.Body>
                            <div className="inner-content">
                              {categoryData?.length > 0 ? (
                                categoryData?.map((item) => {
                                  let selectedBool = decodeURIComponent(selectedCategory)?.includes(
                                    item?.name
                                  );
                                  return (
                                    <>
                                      <a
                                        className={
                                          selectedBool
                                            ? "a-active"
                                            : "twice-btns"
                                        }
                                        onClick={() => handleCategorySelect(item.id, encodeURIComponent(item.name))}
                                      >
                                        <a>{item?.name}</a>
                                      </a>
                                    </>
                                  );
                                })
                              ) : (
                                <p>No Categories</p>
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Brand</Accordion.Header>
                          <Accordion.Body>
                            <div className="brand-sec">
                              <div className="option-field">
                                <input
                                  type="search"
                                  placeholder="Filter Brand"
                                  value={searchQuery}
                                  onChange={(e) =>
                                    setSearchQuery(e.target.value)
                                  }
                                />
                                <img
                                  src="\assets\search-icon.svg"
                                  alt="img"
                                  className="img-fluid search-iconn"
                                />
                              </div>
                              <div className="bottom-content">
                                {filteredData?.length > 0 ? (
                                  filteredData?.map((item) => {
                                    let selectedBool =
                                      selectedBrandCategory?.includes(item?.id);
                                    return (
                                      <>
                                        <a
                                          className={
                                            selectedBool ? "a-active" : ""
                                          }
                                          onClick={() =>
                                            handleBrandCategorySelect(item?.id)
                                          }
                                        >
                                          <div className="twice-text">
                                            <a >{item?.name}</a>
                                            <p>
                                              ({item?.products[0]?.productCount}
                                              )
                                            </p>
                                          </div>
                                        </a>
                                      </>
                                    );
                                  })
                                ) : (
                                  <p>No Brands</p>
                                )}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Shop by Price</Accordion.Header>
                          <Accordion.Body>
                            <div className="shopbyprice">
                              <div className="twice-field">
                                <div className="option-field">
                                  <label>Min (PKR)</label>
                                  <input
                                    type=""
                                    placeholder="e.g 100"
                                    value={minPrice}
                                    onChange={(e) =>
                                      setMinPrice(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="option-field">
                                  <label>Max (PKR)</label>
                                  <input
                                    type="number"
                                    placeholder="e.g 100"
                                    value={maxPrice}
                                    onChange={(e) =>
                                      setMaxPrice(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <button onClick={handleGoButtonClick}>GO</button>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="bottom-div">
                      <button onClick={handleClose} className="btn-closee">
                        Close
                      </button>
                      <button onClick={handleClose} className="btn-showw">
                        Show
                      </button>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-3 p-0">
                  {/* <div className="wrapper-shop">
                    <div className="shop-category">
                      <div className="categoryhead">
                        <h2>All Categories</h2>
                      </div>
                      <div className="sub-items">
                        {categoryData?.length > 0 ? (
                          categoryData?.map((item) => {
                            let selectedBool = selectedCategory?.includes(
                              item?.id
                            );

                            return (
                              <>
                                <a
                                  className={
                                    selectedBool
                                      ? "twice-btns-active"
                                      : "twice-btns"
                                  }
                                  onClick={() => handleCategorySelect(item?.id)}
                                >
                                  {item?.name}
                                </a>
                              </>
                            );
                          })
                        ) : (
                          <p>No Categories</p>
                        )}
                      </div>
                    </div>
                    <div className="pricerange">
                      <div className="rangehead">
                        <h2>Price Range</h2>
                      </div>
                      <div className="buttons">
                        <div className="allbtn">
                          <button
                            className={
                              priceRange[0] == 0 ? "button active" : "button"
                            }
                            onClick={() => {
                              {
                                if (priceRange[0] == 0) {
                                  setPriceRange([]);
                                  window.scrollTo(0, 0);
                                } else {
                                  setPriceRange(["0", "500"]);
                                  setRend(!rend);
                                  window.scrollTo(0, 0);
                                }
                              }
                            }}
                          >
                            Rs. 0 - Rs. 500
                          </button>
                          <button
                            className={
                              priceRange[0] == 500 ? "button active" : "button"
                            }
                            onClick={() => {
                              {
                                if (priceRange[0] == 500) {
                                  setPriceRange([]);
                                  window.scrollTo(0, 0);
                                } else {
                                  setPriceRange(["500", "1000"]);
                                  setRend(!rend);
                                  window.scrollTo(0, 0);
                                }
                              }
                            }}
                          >
                            Rs. 500 - Rs. 1000
                          </button>
                          <button
                            className={
                              priceRange[0] == 1000 ? "button active" : "button"
                            }
                            onClick={() => {
                              if (priceRange[0] == 1000) {
                                setPriceRange([]);
                                window.scrollTo(0, 0);
                              } else {
                                setPriceRange(["1000", "2000"]);
                                setRend(!rend);
                                window.scrollTo(0, 0);
                              }
                            }}
                          >
                            Rs. 1000 - Rs. 2000
                          </button>
                          <button
                            className={
                              priceRange[0] == 2000 ? "button active" : "button"
                            }
                            onClick={() => {
                              if (priceRange[0] == 2000) {
                                setPriceRange([]);
                                window.scrollTo(0, 0);
                              } else {
                                setPriceRange(["2000", "4000"]);
                                setRend(!rend);
                                window.scrollTo(0, 0);
                              }
                            }}
                          >
                            Rs. 2000 - Rs. 4000
                          </button>
                          <button
                            className={
                              priceRange[0] == 4000 ? "button active" : "button"
                            }
                            onClick={() => {
                              if (priceRange[0] == 4000) {
                                setPriceRange([]);
                                window.scrollTo(0, 0);
                              } else {
                                setPriceRange(["4000", "8000"]);
                                setRend(!rend);
                                window.scrollTo(0, 0);
                              }
                            }}
                          >
                            Rs. 4000 - Rs. 8000
                          </button>
                          <button
                            className={
                              priceRange[0] == 8000 ? "button active" : "button"
                            }
                            onClick={() => {
                              if (priceRange[0] == 8000) {
                                setPriceRange([]);
                                window.scrollTo(0, 0);
                              } else {
                                setPriceRange(["8000", "80000"]);
                                setRend(!rend);
                                window.scrollTo(0, 0);
                              }
                            }}
                          >
                            Rs. 8000 +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="pricerange">
                      <div className="categoryhead">
                        <h2>Brands</h2>
                      </div>
                      <div className="sub-items">
                        <div className="categormblmain">
                          {brandData?.length > 0 ? (
                            brandData?.map((item) => {
                              let selectedBool =
                                selectedBrandCategory?.includes(item?.id);
                              return (
                                <>
                                  <a
                                    className={
                                      selectedBool
                                        ? "twice-btns-active"
                                        : "twice-btns"
                                    }
                                    onClick={() =>
                                      handleBrandCategorySelect(item?.id)
                                    }
                                  >
                                    {item?.name}
                                  </a>
                                </>
                              );
                            })
                          ) : (
                            <p>No Brands</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="new-category">
                    <div className="main-heading">
                      <h6>Filters</h6>
                    </div>
                    <Accordion defaultActiveKey={["0"]} alwaysOpen>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Category</Accordion.Header>
                        <Accordion.Body>
                          <div className="inner-content">
                            {categoryData?.length > 0 ? (
                              categoryData?.map((item) => {
                                let selectedBool = decodeURIComponent(selectedCategory)?.includes(
                                  item?.name
                                );
                                return (
                                  <>
                                    <a
                                      className={selectedBool ? "a-active" : ""}
                                      onClick={() => handleCategorySelect(item.id, encodeURIComponent(item.name))}
                                    >


                                      <a>{item?.name}</a>
                                    </a>
                                  </>
                                );
                              })
                            ) : (
                              <p>No Categories</p>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Brand</Accordion.Header>
                        <Accordion.Body>
                          <div className="brand-sec">
                            <div className="option-field">
                              <input
                                type="search"
                                placeholder="Filter Brand"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                              <img
                                src="\assets\search-icon.svg"
                                alt="img"
                                className="img-fluid search-iconn"
                              />
                            </div>
                            <div className="bottom-content">
                              {filteredData?.length > 0 ? (
                                filteredData?.map((item) => {
                                  let selectedBool =
                                    selectedBrandCategory?.includes(item?.id);
                                  return (
                                    <>
                                      <a
                                        className={
                                          selectedBool ? "a-active" : ""
                                        }
                                        onClick={() =>
                                          handleBrandCategorySelect(item?.id)
                                        }
                                      >
                                        <div className="twice-text">
                                          <a >{item?.name}</a>
                                          <p>
                                            ({item?.products[0]?.productCount})
                                          </p>
                                        </div>
                                      </a>
                                    </>
                                  );
                                })
                              ) : (
                                <p>No Brands</p>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Shop by Price</Accordion.Header>
                        <Accordion.Body>
                          <div className="shopbyprice">
                            <div className="twice-field">
                              <div className="option-field">
                                <label>Min (PKR)</label>
                                <input
                                  type="number"
                                  placeholder="e.g 100"
                                  value={minPrice}
                                  onChange={(e) => setMinPrice(e.target.value)}
                                />
                              </div>
                              <div className="option-field">
                                <label>Max (PKR)</label>
                                <input
                                  type="number"
                                  placeholder="e.g 100"
                                  value={maxPrice}
                                  onChange={(e) => setMaxPrice(e.target.value)}
                                />
                              </div>
                            </div>
                            <button onClick={handleGoButtonClick}>GO</button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-12 padd-sm ">
                  <div className="upper-content">
                    {searchItemRedux === "" ||
                      searchItemRedux === undefined ? null : (
                      <div className="left-side">
                        <h6>
                          Search Results for <span>“{searchItemRedux}”</span>
                        </h6>
                        <p>{page} Results</p>
                      </div>
                    )}
                    <div className="right-side">
                      {/* <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                            <path d="M19.3008 5.8125H3.10078C2.73178 5.8125 2.42578 5.5575 2.42578 5.25C2.42578 4.9425 2.73178 4.6875 3.10078 4.6875H19.3008C19.6698 4.6875 19.9758 4.9425 19.9758 5.25C19.9758 5.5575 19.6698 5.8125 19.3008 5.8125Z" fill="#009DE0" />
                            <path d="M17.8462 9.75H4.55385C4.25108 9.75 4 9.495 4 9.1875C4 8.88 4.25108 8.625 4.55385 8.625H17.8462C18.1489 8.625 18.4 8.88 18.4 9.1875C18.4 9.495 18.1489 9.75 17.8462 9.75Z" fill="#009DE0" />
                            <path d="M16.1854 13.5H6.21617C5.98909 13.5 5.80078 13.245 5.80078 12.9375C5.80078 12.63 5.98909 12.375 6.21617 12.375H16.1854C16.4125 12.375 16.6008 12.63 16.6008 12.9375C16.6008 13.245 16.4125 13.5 16.1854 13.5Z" fill="#009DE0" />
                          </svg>   Sort by: <span>Relevance</span> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                            <path d="M9.77223 1.58435L7.47862 3.87796L6.07817 5.28556C5.48512 5.87861 4.52052 5.87861 3.92746 5.28556L0.226255 1.58435C-0.259619 1.09848 0.0904961 0.269634 0.76929 0.269634L4.77774 0.269634L9.2292 0.269634C9.91514 0.269634 10.2581 1.09848 9.77223 1.58435Z" fill="#009DE0" />
                          </svg>
                        </button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#">Relevance</a></li>
                          <li><a class="dropdown-item" href="#" >Price: Low to High</a></li>
                          <li><a class="dropdown-item" href="#">Price: High to Low</a></li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="main-para">
                    <p>
                      <strong>Fresh</strong> is food which has not been
                      preserved and has not spoiled yet. For vegetables and
                      fruits, this means that they have been recently harvested
                      and treated properly postharvest; for meat, it has
                      recently been slaughtered and butchered; for fish, it has
                      been recently caught or harvested and kept cold.
                    </p>
                  </div> */}
                  {/* card placement..... */}

                  <div className="row" ref={chatRef}>
                    {productData?.length > 0 ? (
                      id != "top-saver"  ? (
                        productData?.map((item, index) => {
                          return (
                            <>
                              {/* {checkQuantity(item) && ( */}
                              <div className="col-xl-3 col-lg-6 col-md-6 col-12 padd-sm">
                                <div className="card-topsaver ">
                                  <div className="top-abs">
                                    {/* <span className="off">New</span> */}
                                    {/* <div className="zzzzzzzzzzz">
                                    <img
                                      src="\assets\btn-icons\like-transparent.svg"
                                      alt="img"
                                      className="img-fluid 22"
                                    />
                                  </div> */}
                                  </div>

                                  <div
                                    // onClick={() =>
                                    //   handleProductClick(item?.id)
                                    // }
                                    id={"xyz-" + item?.id}
                                    className="inner-img"
                                  >
                                    {item?.quantityAmount ? (
                                      <div className="btn-add add-counter">
                                        <a
                                          onClick={() => decrease(item, index)}
                                        >
                                          <img
                                            src="\assets\minus-icon.svg"
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </a>
                                        <span>{item?.quantityAmount}</span>
                                        <a
                                          onClick={() => {
                                            if (
                                              item.quantityAmount !==
                                              item?.quantity-item?.outOfStockThreshold
                                            ) {
                                              increase(item, index);
                                            }
                                          }}
                                        >
                                          <img
                                            src="\assets\plus-icon.svg"
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </a>
                                      </div>
                                    ) : (
                                      <a
                                        onClick={() => {
                                          handleCartItem(item, index);
                                        }}
                                        className="btn-add pulse-animation"
                                      >
                                        <img
                                          src="\assets\btn-add.png"
                                          alt="img"
                                          className="img-fluid"
                                          style={{
                                            width: "38px",
                                            height: "38px",
                                          }}
                                        />
                                      </a>
                                    )}
                                    <Link
                                      to={"/product-detail?id=" + item?.id}
                                      onClick={() => {
                                        handleRouteChange(item?.id);
                                      }}
                                    >{item?.imageUrl ?  <img src={item?.imageUrl} alt="img" />: <img src="assets\Frame 186 (1).png" alt="img" />}
                                     
                                    </Link>
                                  </div>
                                  <Link
                                    to={"/product-detail?id=" + item?.id}
                                    onClick={() => {
                                      handleRouteChange(item?.id);
                                    }}
                                  >
                                    <div className="bottom-text">
                                      <span>{item?.category[0]?.name}</span>
                                      <h5>{item?.name}</h5>
                                      {item?.sourceProductId == null ?  <h5 className="pt-1">1 {item?.unit?.abbreviation}</h5> :null}
                                      <h6>Rs. {item?.price}</h6>
                                    </div>
                                  </Link>
                                  {/* <button
                                      className={
                                        item?.sourceProductId != null
                                          ? item?.quantity <=
                                            item?.outOfStockThreshold
                                            ? "outofstock"
                                            : "common-btn"
                                          : item?.productCap?.maxCap <=
                                            item?.productCap?.todaySold
                                            ? "outofstock"
                                            : "common-btn"
                                      }
                                      onClick={() => handleCartItem(item)}
                                      disabled={
                                        item?.sourceProductId != null
                                          ? item?.quantity <=
                                          item?.outOfStockThreshold
                                          : item?.productCap?.maxCap <=
                                          item?.productCap?.todaySold
                                      }
                                    >
                                      {item?.sourceProductId != null ? (
                                        item?.quantity <=
                                          item?.outOfStockThreshold ? (
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="change-my-color me-2"
                                          >
                                            <path
                                              d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
                                              fill="#AFE0F4"
                                            />
                                            <path
                                              d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
                                              fill="#AFE0F4"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="change-my-color me-2"
                                          >
                                            <path
                                              d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
                                              fill="#009DE0"
                                            />
                                            <path
                                              d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
                                              fill="#009DE0"
                                            />
                                          </svg>
                                        )
                                      ) : item?.productCap?.maxCap <=
                                        item?.productCap?.todaySold ? (
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="change-my-color me-2"
                                        >
                                          <path
                                            d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
                                            fill="#AFE0F4"
                                          />
                                          <path
                                            d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
                                            fill="#AFE0F4"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="change-my-color me-2"
                                        >
                                          <path
                                            d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
                                            fill="#009DE0"
                                          />
                                          <path
                                            d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
                                            fill="#009DE0"
                                          />
                                        </svg>
                                      )}
                                      {item?.sourceProductId != null
                                        ? item?.quantity <=
                                          item?.outOfStockThreshold
                                          ? "Out of Stock"
                                          : "Add to Cart"
                                        : item?.productCap?.maxCap <=
                                          item?.productCap?.todaySold
                                          ? "Out of Stock"
                                          : "Add to Cart"}
                                    </button> */}
                                </div>
                              </div>
                              {/* )} */}
                            </>
                          );
                        })
                      ) : ( 
                        productData?.map((item, index) => {
                          return (
                            <>
                              {/* {checkQuantitytop(item) && ( */}
                              <div className="col-xl-3 col-lg-6 col-md-6 col-12 padd-sm">
                                <div className="card-topsaverrr">
                                  <div className="top-abs">
                                    <span className="off">
                                      {item?.discount}% Off
                                    </span>
                                    {/* <span className="off">13% Off</span> */}
                                    {/* <div className="zzzzzzzzzzz">
                                    <img
                                      src="\assets\btn-icons\like-transparent.svg"
                                      alt="img"
                                      className="img-fluid 22"
                                    />
                                  </div> */}
                                  </div>

                                  <div
                                    id={"xyz-" + item?.product?.id}
                                    className="inner-img"
                                  >
                                    {item?.quantityAmount > 0 ? (
                                      <div className="btn-add add-counter">
                                        <a
                                          onClick={() => decrease(item, index)}
                                        >
                                          <img
                                            src="\assets\minus-icon.svg"
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </a>
                                        <span>{item?.quantityAmount}</span>
                                        <a
                                          onClick={() => {
                                            if (
                                              item.quantityAmount !==
                                              item?.product?.quantity-item?.product?.outOfStockThreshold
                                            ) {
                                              increase(item, index);
                                            }
                                          }}
                                        >
                                          <img
                                            src="\assets\plus-icon.svg"
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </a>
                                      </div>
                                    ) : (
                                      <a
                                        onClick={() =>
                                          handleCartItem(item, index)
                                        }
                                        className="btn-add pulse-animation"
                                      >
                                        <img
                                          src="\assets\btn-add.png"
                                          alt="img"
                                          className="img-fluid"
                                          style={{
                                            width: "38px",
                                            height: "38px",
                                          }}
                                        />
                                      </a>
                                    )}
                                    <Link
                                      to={
                                        "/product-detail?id=" +
                                        item?.product?.id
                                      }
                                      onClick={() => {
                                        handleRouteChange(item?.product?.id);
                                      }}
                                    >
                                    
                                      {item?.product?.imageUrl ?  <img src={item?.product?.imageUrl} alt="img" />: <img src="assets\Frame 186 (1).png" alt="img" />}
                                    </Link>
                                  </div>
                                  <Link
                                    to={
                                      "/product-detail?id=" + item?.product?.id
                                    }
                                    onClick={() => {
                                      handleRouteChange(item?.product?.id);
                                    }}
                                  >
                                    <div className="bottom-text">
                                      <span>{item?.category?.name}</span>
                                      <h5>{item?.product?.name}</h5>
                                      <div className="d-flex">
                                        <del style={{ color: "red" }}>
                                          <h6>Rs.{item?.product?.price}</h6>
                                        </del>
                                        <h6 className="dis-price">
                                          Rs.
                                          {Math.trunc(
                                            item?.product?.price -
                                            item?.product?.price *
                                            (item?.discount / 100)
                                          )}
                                        </h6>
                                      </div>
                                    </div>
                                  </Link>
                                  {/* <button
                                      className="common-btn"
                                      onClick={() => handleCartItem(item)}
                                      disabled={
                                        item?.quantity <=
                                        item?.outOfStockThreshold
                                      }
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="change-my-color me-2"
                                      >
                                        <path
                                          d="M16.0334 4.64997H15.7001L12.8834 1.8333C12.6584 1.6083 12.2917 1.6083 12.0584 1.8333C11.8334 2.0583 11.8334 2.42497 12.0584 2.6583L14.0501 4.64997H5.95008L7.94175 2.6583C8.16675 2.4333 8.16675 2.06663 7.94175 1.8333C7.71675 1.6083 7.35008 1.6083 7.11675 1.8333L4.30841 4.64997H3.97508C3.22508 4.64997 1.66675 4.64997 1.66675 6.7833C1.66675 7.59163 1.83341 8.12497 2.18341 8.47497C2.38341 8.6833 2.62508 8.79163 2.88341 8.84997C3.12508 8.9083 3.38341 8.91663 3.63341 8.91663H16.3667C16.6251 8.91663 16.8667 8.89997 17.1001 8.84997C17.8001 8.6833 18.3334 8.1833 18.3334 6.7833C18.3334 4.64997 16.7751 4.64997 16.0334 4.64997Z"
                                          fill="#009DE0"
                                        />
                                        <path
                                          d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
                                          fill="#009DE0"
                                        />
                                      </svg>
                                      {item?.quantity <=
                                        item?.outOfStockThreshold
                                        ? "Out of Stock"
                                        : "Add to Cart"}
                                    </button> */}
                                </div>
                              </div>
                              {/* )} */}
                            </>
                          );
                        })
                      )
                    ) : (
                      <h5 className="no-products">No Products</h5>
                    )}
                    {isLoading ? (
                      <div className="loader-image">
                        <img
                          src="assets\btn-icons\2nd_v2.gif"
                          alt="Loading..."
                          style={{ width: '100px', height: '100px', margin: 'auto', display: 'block' }}
                        />
                      </div>
                    ) : (
                      productData.length > 0 &&
                      productData.length !== page && (
                        <div className="d-flex justify-content-center align-items-center">
                          <button
                           ref={showMoreButtonRef}
                            className="showmore"
                            onClick={() => {
                              dispatch(searchOffSet(offset + 1));
                            }}
                            disabled={productData.length < limit}
                          >
                            Show More
                          </button>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Location show12={show12} setShow12={setShow12} />
    </>
  );
};

export default Allcategories;