import React, { Component } from "react";
import { useState, useEffect } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import BannerSlider from "../../BannerSlider/BannerSlider";
import axios from "axios";
import { toast } from "react-toastify";
import Environment from "../../utils/environment";
import Allcategories from "../../AllCategories/Allcategories";
import Spinner from "react-bootstrap/Spinner";

const Banner = ({ banner1, banner2 }) => {
  const val = localStorage.getItem("accessToken");
  const [post, setPost] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBanner = async () => {
    setIsLoading(true);
    await axios
      .get(`${Environment.base_url2}/banners?offset=1&&limit=10`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })

      .then((res) => {
        setIsLoading(false);
        setPost(res?.data?.data?.banners);
      })
      .catch((err) => {});
    setIsLoading(false);
  };

  const filteredData = post.filter(
    (data) => data?.type === "main banner 2" && data?.isPublish === true
  );

  useEffect(() => {
    getBanner();
  }, []);

  

  return (
    <>
      <section className="main-banner">
        <div className="container-fluid sub-banner">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-12 col-12 padd-lg ps-0">
                  <div>
                    <BannerSlider banner1={banner1} />
                  </div>
                </div>
              
                
                  <div className="col-xl-0 col-12 padd-lg pe-1">
                    {/* <div className="blue">
                      <img src={banner2[0]?.imageUrl} alt="img" className="" />
                      <div className="textbtn">
                        <div className="text">
                     
                        </div>
                        <div className="btn-shop">
                          <Link to="/allcategories">
                           
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Banner;
