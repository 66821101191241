import React, { useEffect } from 'react'
import Navbar from '../landing/header/Navbar'
import "./cart.scss"
const Cart = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        {/* <Navbar /> */}
        <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
            <section className='main-cart'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-xl-12 m-auto p-0'>
                            <div className='row'>
                                <div className='col-xl-12 m-auto'>
                                    <div className='parent'>
                                    <div className='main-img'>
                                       <img src='assets\dummy-imgs\Cartillustartion.png' className='img-fluid' />        
                                    </div>
                                    <div className='heading'>
                                        <h2>Cart is empty</h2>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cart