import React, { useEffect } from 'react'
import Navbar from '../landing/header/Navbar'
import "./newpass.scss"

const Newpass = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
    {/* <Navbar /> */}
    <section className="bread-crumb">
        <div className="bread">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">FAQS</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
    <section className='new'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='main'>
                        <img src='\Assests\Loginbro.png' alt='img' className='imgmain'/>
                        <h5>Create New Password</h5>
                        <p>Your new password must be different  from previously used passwords.</p>
                        <div className='inputmsg'>
                        <img src='\Assests\lock.svg' alt='img' className='imgmsg'/>
                        <input type="email" placeholder='New Password' className='emailinput' />
                        <img src='\Assests\eye.svg' alt='img' className='eye'/>
                        </div>
                        <div className='inputmsg'>
                        <img src='\Assests\lock.svg' alt='img' className='imgmsg'/>
                        <input type="email" placeholder='Confirm New Password' className='emailinput' />
                        <img src='\Assests\eye.svg' alt='img' className='eye'/>
                        </div>
                        <button className='snd-btn'>OK</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Newpass