import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./location.scss";


const Location = ({ setShow12, show12, setShowRegister, showRegister }) => {


  const handleCloselocation = () => setShow12(false);

  const prorefresh = () => {
    const timer = setTimeout(() => {
      setShow12(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    prorefresh();
  })

  const handleCloselocationRegister = () => setShowRegister(false);

  return (
    <>
      {/* <Navbar locaiton={addressLoc} /> */}
      <section className="location">
        <div className="container-fluid">
          <div className="modal-div">
            <Modal className="cart-location" backdrop="static"
              keyboard={false} show={show12} onHide={handleCloselocation} centered>
              <Modal.Header >
                {/* <Modal.Title>Modal heading</Modal.Title> */}
              </Modal.Header>
              <Modal.Body className="cart-body">
                <img
                  className="cart-added"
                  src='assets\btn-icons\quick.gif'
                ></img>
                <p className="cart-data">
                  New item have been added to your cart
                </p>
              </Modal.Body>
              {/* <Modal.Footer className="cart-footer">
                <Button className="shopp" onClick={handleCloselocation}>
                  Continue Shopping
                </Button>
                <Link to="/home-shop">
                  <Button className="shopp1" onClick={handleCloselocation}>
                    Go to Cart
                  </Button>
                </Link>
              </Modal.Footer> */}
            </Modal>
          </div>
        </div>
      </section>

      <section className="location1">
        <div className="container-fluid">
          <div className="modal-div1">
            <Modal backdrop="static" keyboard={false} className="cart-location1" show={showRegister} onHide={handleCloselocationRegister} centered>
              <Modal.Header >
                {/* <Modal.Title>Modal heading</Modal.Title> */}
              </Modal.Header>
              <Modal.Body className="cart-body">
                <img
                  className="cart-added"
                  src="\modal.svg"
                ></img>
                <p className="cart-data">
                  Please   <span className="cart-data1"> verify your email </span> to complete the account creation process.
                </p>
              </Modal.Body>
              <Modal.Footer className="cart-footer">
                <Link to="/">
                  <Button className="shopp" onClick={handleCloselocationRegister}>
                    OK
                  </Button>
                </Link>
            
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
