export const locationAddressRedux = (location) => async (dispatch) => {
  dispatch({
    type: "LOCATION",
    payload: location,
  });
};

export const cartItems = (cart) => async (dispatch) => {
  dispatch({
    type: "CART",
    payload: cart,
  });
};

export const removeFromCart = (id) => {
  // console.log('id',id);
  return {
    type: "REMOVE_FROM_CART",
    payload: id,
  };
};
export const emptyCart = (emptyBool) => {
  // console.log('bool',emptyBool);
  return {
    type: "EMPTY",
    payload: emptyBool,
  };
};

export const searchItem = (search) => {
  return {
    type: "SEARCH",
    payload: search,
  };
};
export const searchOffSet = (offSet) => {
  return {
    type: "OFFSET",
    payload: offSet ? offSet : 1,
  };
};
// export const removeFromCart=(id)=>{
//   return{
//       type:"REMOVE_FROM_CART",
//       payload:id
//   }
// }
